const CompanySucess = () => {
  return (
    <div className="flex justify-center items-center">
      <svg
        width="250"
        height="150"
        viewBox="0 0 353 297"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1645_18957)">
          <path
            d="M146.839 258.019L150.694 245.752C146.055 240.764 140.24 236.958 133.754 234.664C140.091 244.921 133.95 258.301 136.509 270.017C137.336 273.666 138.993 277.085 141.356 280.023C143.72 282.961 146.73 285.342 150.163 286.989L156.014 294.071C159.081 288.836 160.95 283.013 161.492 277.004C162.034 270.995 161.236 264.943 159.153 259.264C157.952 256.09 156.347 253.076 154.376 250.293C150.899 254.287 146.839 258.019 146.839 258.019Z"
            fill="#F2F2F2"
          />
          <path
            d="M125.639 259.585L123.49 246.926C117.061 244.483 110.118 243.61 103.266 244.383C113.62 250.802 114.232 265.453 121.871 274.825C124.276 277.73 127.32 280.069 130.777 281.669C134.233 283.269 138.014 284.088 141.839 284.066L150.308 287.856C150.664 281.833 149.679 275.808 147.421 270.193C145.163 264.579 141.686 259.51 137.229 255.334C134.705 253.016 131.893 251.018 128.858 249.387C127.569 254.475 125.639 259.585 125.639 259.585Z"
            fill="#F2F2F2"
          />
          <path
            d="M251.823 290.926L245.884 290.925L243.059 268.547L251.824 268.547L251.823 290.926Z"
            fill="#FFB6B6"
          />
          <path
            d="M253.336 296.549L234.188 296.548V296.311C234.188 294.38 234.973 292.528 236.371 291.163C237.768 289.797 239.664 289.03 241.641 289.03H241.641L245.139 286.438L251.665 289.03L253.337 289.03L253.336 296.549Z"
            fill="#2F2E41"
          />
          <path
            d="M347.756 269.766L343.707 274.01L325.023 260.771L331 254.508L347.756 269.766Z"
            fill="#FFB6B6"
          />
          <path
            d="M352.999 272.517L339.943 286.201L339.766 286.04C338.32 284.723 337.469 282.9 337.399 280.97C337.33 279.04 338.048 277.162 339.395 275.749L339.396 275.749L339.839 271.481L346.23 268.585L347.37 267.391L352.999 272.517Z"
            fill="#2F2E41"
          />
          <path
            d="M338.968 257.185L332.691 270.011L294.146 245.078L288.538 237.614L270.152 213.14L262.678 224.726L257.478 236.837C255.783 237.917 254.355 239.352 253.296 241.036C252.238 242.721 251.576 244.615 251.358 246.58L251.12 248.724L254.406 277.585L241.916 281.332L234.742 242.561L237.087 234.16L239.501 222.367L251.657 185.102H284.183L302.839 223.897L304.203 231.802L309.493 237.732L338.968 257.185Z"
            fill="#2F2E41"
          />
          <path
            d="M271.372 124.133L276.847 128.206L283.451 130.189C283.451 130.189 286.18 167.703 288.64 170.107C291.1 172.51 288.882 172.574 289.376 175.696C289.87 178.818 291.072 185.217 291.072 185.217C274.468 193.804 260.154 194.034 248.656 183.986C248.509 183.365 248.583 182.713 248.867 182.138C249.152 181.564 249.628 181.102 250.218 180.828C252.358 179.719 248.245 174.541 249.994 173.676C251.743 172.81 249.283 165.3 249.283 165.3L250.897 149.632L249.622 147.127L252.733 131.802L258.132 129.876L260.288 124.8L271.372 124.133Z"
            fill="#3F3D56"
          />
          <path
            d="M274.804 190.807C274.779 190.129 274.907 189.455 275.178 188.831C275.449 188.207 275.857 187.648 276.373 187.195C276.889 186.742 277.501 186.404 278.164 186.207C278.828 186.009 279.528 185.956 280.215 186.052L287.932 171.398L292.231 178.861L284.244 191.632C284.004 192.749 283.352 193.741 282.413 194.42C281.474 195.099 280.312 195.417 279.149 195.316C277.985 195.214 276.9 194.699 276.099 193.868C275.298 193.037 274.838 191.948 274.804 190.807H274.804Z"
            fill="#FFB6B6"
          />
          <path
            d="M287.796 187.221L304.347 162.16L304.293 162.045C304.23 161.912 300.482 154.004 296.85 146.761C296.291 145.645 295.735 144.546 295.195 143.493C294.962 143.038 294.732 142.593 294.506 142.158C292.981 139.22 291.648 136.792 290.853 135.638C289.093 133.031 286.474 131.089 283.429 130.136L283.352 130.117L283.279 130.147C281.79 130.761 280.559 131.852 279.79 133.241C279.021 134.63 278.759 136.234 279.048 137.788C280.484 145.282 283.683 152.348 288.393 158.428L291.076 161.883L281.751 181.688L287.796 187.221Z"
            fill="#3F3D56"
          />
          <path
            d="M230.883 91.1621C231.339 91.6733 231.675 92.2759 231.866 92.9275C232.058 93.5791 232.101 94.2638 231.992 94.9333C231.884 95.6029 231.626 96.2411 231.238 96.8029C230.849 97.3647 230.34 97.8365 229.745 98.185L233.195 114.315L225.045 111.163L223.018 96.3287C222.485 95.3131 222.352 94.1417 222.644 93.0365C222.935 91.9313 223.632 90.9692 224.602 90.3323C225.571 89.6955 226.746 89.4282 227.904 89.5811C229.062 89.7341 230.122 90.2966 230.883 91.1621H230.883Z"
            fill="#FFB6B6"
          />
          <path
            d="M223.098 101.938L226.393 131.588L226.51 131.644C226.606 131.69 230.782 133.691 235.907 136.074C236.772 136.476 236.648 138.217 237.551 138.633C238.174 138.92 239.818 137.88 240.447 138.167C246.049 140.726 251.713 143.215 253.971 143.889C257.019 144.833 260.305 144.729 263.285 143.596L263.356 143.564L263.394 143.496C264.155 142.103 264.408 140.498 264.11 138.946C263.812 137.394 262.982 135.987 261.756 134.958C255.81 130.019 248.771 126.498 241.193 124.672L236.882 123.639L231.36 102.517L223.098 101.938Z"
            fill="#3F3D56"
          />
          <path
            d="M265.534 122.451C271.515 122.451 276.365 117.714 276.365 111.87C276.365 106.026 271.515 101.289 265.534 101.289C259.552 101.289 254.703 106.026 254.703 111.87C254.703 117.714 259.552 122.451 265.534 122.451Z"
            fill="#FFB6B6"
          />
          <path
            d="M254.782 104.779C255.673 102.247 260.107 100.492 267.295 100.247C274.483 100.003 276.306 108.279 276.306 108.279C281.105 112.082 271.182 122.263 270.702 121.896L270.918 119.983C271.275 116.815 272.794 114.343 270.863 111.041L269.008 111.062C266.613 111.512 264.218 111.962 261.822 112.411L264.658 109.972L264.578 109.851C263.372 110.465 262.059 110.855 260.707 111C259.183 111.087 257.517 110.495 256.787 109.185C256.592 108.824 256.473 108.43 256.437 108.024C254.381 108.826 253.441 111.302 253.441 111.302C253.441 111.302 253.892 107.31 254.782 104.779Z"
            fill="#2F2E41"
          />
          <path
            d="M43.6379 105.416C43.491 105.234 43.3327 105.061 43.1641 104.898C43.2053 104.842 43.2465 104.782 43.2877 104.727L43.6379 105.416Z"
            fill="#2F2E41"
          />
          <path
            d="M45.7447 147.975C43.973 151.049 40.4348 152.765 36.9275 153.605C32.988 154.614 28.8396 154.535 24.9429 153.379C24.4485 153.223 23.9541 153.042 23.4751 152.841C23.7054 152.243 23.8879 151.629 24.0211 151.004C24.2003 150.158 24.2901 149.297 24.2889 148.433C23.8986 149.177 23.3931 149.858 22.7901 150.451C22.325 150.928 21.7968 151.343 21.2193 151.683C19.4283 150.621 17.8775 149.214 16.6614 147.548C16.7386 147.361 16.8159 147.175 16.888 146.989C17.262 146.017 17.5582 145.017 17.7738 144C18.0665 142.625 18.2132 141.224 18.2116 139.819C17.5766 141.03 16.753 142.136 15.7704 143.1C15.4758 143.408 15.1628 143.698 14.8331 143.97C14.3225 142.455 14.1251 140.856 14.2524 139.266C14.3797 137.676 14.8292 136.126 15.5747 134.707C17.6142 131.024 21.5695 128.835 25.0923 126.456C28.6202 124.076 32.1481 120.855 32.4468 116.674C32.6477 113.852 31.3086 111.024 31.8442 108.247C32.0695 107.075 32.6195 105.987 33.4342 105.1C34.2489 104.213 35.2971 103.562 36.4647 103.217C37.6322 102.871 38.8744 102.846 40.0557 103.143C41.2371 103.44 42.3123 104.048 43.1644 104.901C41.1661 107.668 39.1782 110.531 38.3696 113.821C37.5456 117.172 38.2202 121.102 40.9241 123.321C41.8511 124.086 42.9893 124.629 43.7052 125.585C44.658 126.853 44.6632 128.544 44.8177 130.103C45.1267 133.137 46.1413 136.056 46.6872 139.059C47.2383 142.058 47.2692 145.314 45.7447 147.975Z"
            fill="#2F2E41"
          />
          <path
            d="M9.42578 272.744L14.1263 276.292L30.3713 260.268L23.4337 255.031L9.42578 272.744Z"
            fill="#A0616A"
          />
          <path
            d="M9.62469 269.281L17.3976 274.457L14.9378 277.567L18.2321 287.666C18.3949 288.165 18.3902 288.701 18.2187 289.197C18.0473 289.693 17.718 290.123 17.2791 290.423C16.8401 290.723 16.3144 290.878 15.7787 290.866C15.2429 290.854 14.7253 290.674 14.3013 290.354L5.45534 283.676L6.36375 279.449L3.33527 282.076L0 279.558L9.62469 269.281Z"
            fill="#2F2E41"
          />
          <path
            d="M33.2734 114.358C33.2728 116.152 33.7229 117.919 34.5844 119.503C35.4458 121.088 36.6923 122.442 38.2143 123.446C39.7363 124.45 41.4873 125.073 43.3136 125.262C45.14 125.451 46.9858 125.198 48.689 124.527C50.3922 123.856 51.9008 122.787 53.0823 121.414C54.2638 120.04 55.0822 118.404 55.4654 116.649C55.8487 114.895 55.7852 113.075 55.2806 111.35C54.7759 109.625 53.8455 108.047 52.571 106.755L52.5172 106.703C52.4274 106.611 52.3377 106.519 52.2434 106.431C52.2428 106.431 52.2422 106.431 52.2417 106.431C52.2411 106.43 52.2406 106.43 52.2402 106.43C52.2398 106.429 52.2395 106.429 52.2392 106.428C52.239 106.428 52.2389 106.427 52.2389 106.426C52.1222 106.321 52.0056 106.216 51.8844 106.115C49.9098 104.423 47.1344 102.586 44.3026 102.586C38.1049 102.586 33.2734 108.303 33.2734 114.358Z"
            fill="#2F2E41"
          />
          <path
            d="M97.2209 108.725C97.2746 109.31 97.1964 109.899 96.9919 110.451C96.7874 111.003 96.4615 111.504 96.0372 111.92C95.6129 112.335 95.1005 112.655 94.5361 112.856C93.9717 113.057 93.369 113.135 92.7704 113.084L64.988 137.59L60.207 130.213L89.0196 108.444C89.174 107.467 89.6897 106.58 90.469 105.95C91.2484 105.32 92.2372 104.991 93.2481 105.026C94.259 105.061 95.2218 105.456 95.954 106.138C96.6862 106.82 97.137 107.74 97.2209 108.725V108.725Z"
            fill="#A0616A"
          />
          <path
            d="M53.1161 144.991C53.1161 144.991 54.1498 146.856 57.6808 143.976C60.5076 141.67 74.0508 131.66 76.9684 127.559C77.6347 127.817 78.3698 127.851 79.0579 127.655C79.7461 127.46 80.3483 127.047 80.7695 126.48L72.7754 119.641C70.3835 119.91 70.4348 121.479 70.6838 122.371L64.6537 125.04L49.082 134.762L52.3444 143.655L53.1161 144.991Z"
            fill="#01966B"
          />
          <path
            d="M38.0299 162.175L37.4476 164.884L36.9531 174.877L58.7219 176.045L58.0459 160.676L55.9359 157.289L38.0299 162.175Z"
            fill="#A0616A"
          />
          <path
            d="M47.2557 126.078C52.4516 126.078 56.6638 121.963 56.6638 116.886C56.6638 111.81 52.4516 107.695 47.2557 107.695C42.0598 107.695 37.8477 111.81 37.8477 116.886C37.8477 121.963 42.0598 126.078 47.2557 126.078Z"
            fill="#A0616A"
          />
          <path
            d="M30.5539 150.792L30.4961 143.152C30.4948 140.403 31.447 137.735 33.1974 135.582C34.9479 133.43 37.3933 131.921 40.1346 131.301L40.1701 131.293L42.0304 128.844L49.5886 129.582L50.9739 132.348L56.4507 135.534L58.5593 142.157C59.4739 142.752 60.2534 143.525 60.8493 144.427C61.4452 145.33 61.8449 146.342 62.0233 147.402C62.7393 151.869 59.5375 156.339 59.1567 156.851L58.1754 162.251L36.8286 165.93L36.6552 165.952L30.5539 150.792Z"
            fill="#01966B"
          />
          <path
            d="M58.7676 115.252C57.6702 115.931 56.5316 116.544 55.3581 117.088C54.1671 117.647 52.9213 118.087 51.6397 118.401C51.273 117.615 50.8243 116.868 50.3006 116.172C50.4354 116.997 50.4388 117.837 50.3109 118.663C48.1867 118.998 46.011 118.823 43.971 118.155L42.1993 115.252L42.0654 118.492C40.5976 118.754 39.1349 119 37.6671 119.257C37.5796 119.267 37.492 119.287 37.4096 119.302C36.498 115.287 37.7907 110.864 40.6646 108.188C42.0914 106.872 43.8713 105.98 45.797 105.615C47.7227 105.25 49.715 105.427 51.5418 106.125C51.7736 106.215 52.0105 106.316 52.2371 106.421C52.2371 106.422 52.2372 106.423 52.2375 106.423C52.2377 106.424 52.2381 106.425 52.2386 106.425C52.2391 106.426 52.2396 106.426 52.2403 106.426C52.2409 106.426 52.2416 106.426 52.2423 106.426C52.3762 106.492 52.4895 106.648 52.6388 106.633C59.0663 105.933 59.2723 110.804 58.7676 115.252Z"
            fill="#2F2E41"
          />
          <path
            d="M43.1172 260.896L47.825 264.435L64.0371 248.379L57.0889 243.156L43.1172 260.896Z"
            fill="#A0616A"
          />
          <path
            d="M43.7362 257.75L51.5198 262.91L49.0664 266.025L52.3817 276.118C52.5455 276.616 52.5419 277.153 52.3714 277.649C52.201 278.145 51.8726 278.575 51.4343 278.876C50.9959 279.177 50.4705 279.334 49.9348 279.322C49.3991 279.311 48.8811 279.133 48.4564 278.814L39.5967 272.153L40.4963 267.924L37.4733 270.557L34.1328 268.046L43.7362 257.75Z"
            fill="#2F2E41"
          />
          <path
            d="M27.8394 241.298L27.8392 241.238L30.0704 237.482L27.7921 232.592L27.7237 220.03L28.0931 218.144C28.5898 215.608 28.4453 212.991 27.672 210.522L27.6304 202.875C27.5503 200.96 30.5878 199.275 29.8795 197.678C23.1842 182.584 36.7164 171.069 36.8079 170.976L37.0844 170.695L38.5848 171.806L40.0129 171.807L59.2842 175.238L59.4234 175.491C60.1814 176.88 61.6442 181.668 61.731 183.834C62.6914 184.92 73.6318 197.288 74.6043 198.148C81.1763 203.957 84.939 213.572 86.5969 218.787C87.0087 220.075 87.0917 221.443 86.8387 222.77C86.5857 224.097 86.0044 225.344 85.1454 226.401L60.2658 253.024L53.5705 246.987L67.0326 223.021L66.0618 220.59C64.3201 220.276 62.7438 219.381 61.6019 218.058L58.8759 214.897C58.1211 214.024 57.6415 212.957 57.4951 211.823L55.842 211.629L49.9204 205.555L45.8121 241.902L44.6952 244.773L25.2443 267.113L17.0039 262.081L27.8394 241.298Z"
            fill="#2F2E41"
          />
          <path
            d="M24.8714 85.2261C24.4977 85.6857 24.2317 86.2198 24.0921 86.7907C23.9526 87.3616 23.9428 87.9555 24.0636 88.5304C24.1844 89.1054 24.4327 89.6475 24.7911 90.1186C25.1496 90.5897 25.6094 90.9782 26.1381 91.2569L19.9531 109.145L25.0035 128.102L33.1473 124.506L28.122 110.207L31.8817 89.3939C32.3021 88.495 32.3703 87.4768 32.0735 86.532C31.7767 85.5873 31.1355 84.7818 30.2714 84.268C29.4073 83.7543 28.3803 83.5681 27.3851 83.7447C26.3898 83.9212 25.4954 84.4484 24.8714 85.2261Z"
            fill="#A0616A"
          />
          <path
            d="M32.3257 143.721C32.3257 143.721 30.413 144.724 29.0827 140.422C28.0178 136.978 20.7702 118.28 20.6396 113.286C19.9379 113.144 19.3046 112.778 18.8395 112.245C18.3743 111.713 18.1037 111.044 18.0703 110.345L28.5965 108.922C30.4436 110.431 29.5179 111.715 28.8085 112.326L32.3936 118.258L39.8871 134.275L33.7215 143.019L32.3257 143.721Z"
            fill="#01966B"
          />
          <path
            d="M37.6641 119.259C38.745 116.074 40.6833 113.232 43.2757 111.031C45.868 108.831 49.0188 107.352 52.3976 106.75C52.411 106.746 52.4559 106.728 52.5143 106.702C52.5501 106.68 52.595 106.658 52.6399 106.636C52.5053 106.562 52.3751 106.496 52.2405 106.43C52.2399 106.43 52.2393 106.43 52.2388 106.43C52.2382 106.43 52.2377 106.429 52.2373 106.429C52.2369 106.429 52.2365 106.428 52.2363 106.427C52.2361 106.427 52.236 106.426 52.236 106.426C52.1193 106.321 52.0026 106.215 51.8815 106.115L51.5404 106.128C51.5404 106.128 39.5534 104.273 37.6641 119.259Z"
            fill="#FD6584"
          />
          <path
            d="M192.339 221.677C193.647 219.756 192.873 217.006 190.609 215.535C188.346 214.064 185.45 214.429 184.142 216.35C182.834 218.271 183.608 221.021 185.872 222.492C188.135 223.963 191.031 223.598 192.339 221.677Z"
            fill="#F2F2F2"
          />
          <path
            d="M165.784 149.974C167.092 148.053 166.318 145.303 164.055 143.832C161.791 142.361 158.896 142.726 157.588 144.647C156.279 146.568 157.054 149.318 159.317 150.789C161.581 152.26 164.476 151.895 165.784 149.974Z"
            fill="#F2F2F2"
          />
          <path
            d="M276.839 260.357C278.147 258.436 277.373 255.686 275.109 254.215C272.846 252.744 269.95 253.108 268.642 255.029C267.334 256.95 268.108 259.7 270.372 261.172C272.635 262.643 275.531 262.278 276.839 260.357Z"
            fill="#F2F2F2"
          />
          <path
            d="M52.3154 29.6851C53.6237 27.7641 52.8494 25.0141 50.5859 23.543C48.3225 22.0718 45.427 22.4365 44.1188 24.3576C42.8105 26.2786 43.5849 29.0286 45.8483 30.4997C48.1118 31.9709 51.0072 31.6062 52.3154 29.6851Z"
            fill="#F2F2F2"
          />
          <path
            d="M157.093 7.04448C158.401 5.12342 157.627 2.37349 155.363 0.902327C153.1 -0.568837 150.204 -0.204136 148.896 1.71692C147.588 3.63797 148.362 6.38793 150.626 7.85909C152.889 9.33026 155.785 8.96553 157.093 7.04448Z"
            fill="#F2F2F2"
          />
          <path
            d="M107.503 197.013C108.811 195.092 108.037 192.342 105.773 190.871C103.51 189.4 100.615 189.765 99.3063 191.686C97.998 193.607 98.7724 196.357 101.036 197.828C103.299 199.299 106.195 198.934 107.503 197.013Z"
            fill="#E6E6E6"
          />
          <path
            d="M323.638 296.998H9.64356C9.49137 296.998 9.34557 296.939 9.23811 296.833C9.13065 296.728 9.07031 296.585 9.07031 296.437C9.07031 296.288 9.13065 296.145 9.23811 296.04C9.34557 295.935 9.49137 295.875 9.64356 295.875H323.638C323.79 295.875 323.936 295.934 324.044 296.039C324.152 296.145 324.212 296.288 324.212 296.437C324.212 296.586 324.152 296.728 324.044 296.834C323.936 296.939 323.79 296.998 323.638 296.998Z"
            fill="#CCCCCC"
          />
          <path
            d="M68.0847 110.456C65.4435 110.449 62.8977 109.491 60.934 107.765C58.9703 106.039 57.7265 103.667 57.4404 101.102L51.3105 45.4832C51.0093 42.7217 51.8416 39.9563 53.625 37.7936C55.4083 35.6309 57.9969 34.2477 60.8229 33.9473L242.835 14.8013C245.662 14.5069 248.492 15.32 250.706 17.0622C252.92 18.8044 254.336 21.3333 254.644 24.0942L260.773 79.7127C261.075 82.4743 260.242 85.2398 258.459 87.4025C256.675 89.5653 254.087 90.9486 251.261 91.249L251.235 91.0145L251.261 91.249L69.2487 110.395C68.8621 110.436 68.4736 110.456 68.0847 110.456Z"
            fill="#E6E6E6"
          />
          <path
            d="M240.832 17.3828C240.49 17.3829 240.148 17.4009 239.808 17.4367L64.3587 35.8924C63.1319 36.0215 61.9432 36.3853 60.8603 36.9632C59.7775 37.5411 58.8217 38.3217 58.0476 39.2604C57.2736 40.1991 56.6963 41.2776 56.3489 42.4343C56.0015 43.591 55.8907 44.8032 56.0228 46.0017L59.1221 74.123C59.6231 78.6692 61.0358 83.0744 63.2794 87.0872C65.5231 91.1 68.5539 94.6418 72.1986 97.5103C75.8434 100.379 80.0308 102.518 84.5218 103.805C89.0127 105.093 93.7193 105.504 98.3727 105.014L221.743 92.0365C226.382 91.5485 230.878 90.1726 234.973 87.9873C239.068 85.802 242.682 82.8502 245.609 79.3002C248.537 75.7503 250.72 71.6719 252.033 67.2978C253.347 62.9237 253.766 58.3397 253.267 53.8073L250.156 25.5805C249.908 23.3322 248.819 21.2523 247.099 19.7396C245.378 18.2268 243.147 17.3876 240.832 17.3828V17.3828Z"
            fill="white"
          />
          <path
            d="M219.44 46.6499L121.379 56.9651C121.098 56.995 120.814 56.9705 120.544 56.8932C120.273 56.8159 120.021 56.6872 119.801 56.5145C119.581 56.3418 119.398 56.1284 119.263 55.8866C119.128 55.6449 119.043 55.3794 119.012 55.1054C118.982 54.8315 119.008 54.5544 119.087 54.29C119.167 54.0256 119.299 53.7792 119.476 53.5647C119.653 53.3502 119.871 53.172 120.119 53.0401C120.367 52.9083 120.639 52.8254 120.919 52.7963L218.98 42.4811C219.261 42.4512 219.544 42.4757 219.815 42.553C220.086 42.6303 220.338 42.759 220.558 42.9317C220.778 43.1044 220.96 43.3178 221.096 43.5596C221.231 43.8013 221.316 44.0668 221.346 44.3408C221.377 44.6147 221.351 44.8918 221.272 45.1562C221.192 45.4206 221.06 45.667 220.883 45.8815C220.706 46.096 220.487 46.2742 220.24 46.4061C219.992 46.5379 219.72 46.6208 219.44 46.6499Z"
            fill="#01966B"
          />
          <path
            d="M220.851 59.4465L122.79 69.7617C122.224 69.8212 121.657 69.6587 121.214 69.3099C120.771 68.9611 120.487 68.4545 120.426 67.9017C120.365 67.3489 120.532 66.7951 120.889 66.3621C121.246 65.9291 121.764 65.6524 122.33 65.5929L220.391 55.2777C220.957 55.2182 221.524 55.3807 221.967 55.7295C222.411 56.0783 222.694 56.5848 222.755 57.1377C222.816 57.6905 222.649 58.2443 222.292 58.6773C221.935 59.1103 221.417 59.387 220.851 59.4465Z"
            fill="#01966B"
          />
          <path
            d="M135.627 81.353L124.2 82.5551C123.634 82.6146 123.067 82.4521 122.624 82.1033C122.181 81.7545 121.897 81.248 121.837 80.6951C121.776 80.1423 121.942 79.5885 122.299 79.1555C122.656 78.7225 123.175 78.4458 123.74 78.3863L135.168 77.1842C135.448 77.1544 135.732 77.1788 136.003 77.2561C136.273 77.3334 136.526 77.4621 136.746 77.6349C136.965 77.8076 137.148 78.0209 137.284 78.2627C137.419 78.5045 137.504 78.7699 137.534 79.0439C137.564 79.3179 137.539 79.5949 137.459 79.8593C137.38 80.1237 137.248 80.3702 137.071 80.5846C136.894 80.7991 136.675 80.9773 136.427 81.1092C136.18 81.241 135.908 81.3239 135.627 81.353Z"
            fill="#01966B"
          />
          <path
            d="M64.2261 47.8287C66.3594 47.8287 68.0889 46.1392 68.0889 44.055C68.0889 41.9708 66.3594 40.2812 64.2261 40.2812C62.0927 40.2812 60.3633 41.9708 60.3633 44.055C60.3633 46.1392 62.0927 47.8287 64.2261 47.8287Z"
            fill="#3F3D56"
          />
          <path
            d="M237.089 28.9615C239.223 28.9615 240.952 27.272 240.952 25.1878C240.952 23.1036 239.223 21.4141 237.089 21.4141C234.956 21.4141 233.227 23.1036 233.227 25.1878C233.227 27.272 234.956 28.9615 237.089 28.9615Z"
            fill="#3F3D56"
          />
          <path
            d="M184.863 106.438C184.508 106.134 184.227 105.756 184.04 105.332C183.853 104.908 183.765 104.449 183.783 103.987C183.977 98.5061 183.053 93.0418 181.065 87.9128C180.896 87.4816 180.828 87.0192 180.865 86.5591C180.903 86.099 181.044 85.6527 181.28 85.2528C181.506 84.8685 181.816 84.5372 182.187 84.2822C182.558 84.0271 182.982 83.8545 183.428 83.7764C187.918 81.1295 192.521 80.3864 197.111 81.5682C197.852 81.4618 198.606 81.6166 199.24 82.0049C199.874 82.3931 200.346 82.9894 200.57 83.6867C202.429 89.5813 203.462 95.6944 203.643 101.86C203.661 102.599 203.404 103.32 202.921 103.89C202.438 104.46 201.761 104.84 201.014 104.961L187.43 107.154C186.974 107.228 186.508 107.203 186.064 107.079C185.62 106.955 185.209 106.736 184.863 106.438Z"
            fill="#01966B"
          />
          <path
            d="M186.071 90.4701L197.505 88.6247C197.599 88.6097 197.689 88.5768 197.77 88.5278C197.851 88.4788 197.921 88.4147 197.976 88.3391C198.032 88.2635 198.072 88.1779 198.093 88.0873C198.115 87.9967 198.118 87.9028 198.103 87.811C198.087 87.7192 198.053 87.6313 198.003 87.5523C197.953 87.4733 197.887 87.4048 197.809 87.3507C197.732 87.2965 197.644 87.2579 197.551 87.2369C197.459 87.216 197.362 87.2131 197.268 87.2285L185.835 89.0738C185.646 89.1048 185.477 89.2078 185.365 89.3603C185.254 89.5127 185.209 89.7021 185.24 89.887C185.271 90.0719 185.376 90.2371 185.532 90.3464C185.688 90.4557 185.882 90.5002 186.071 90.4701Z"
            fill="white"
          />
          <path
            d="M187.016 96.0479L198.449 94.2026C198.639 94.172 198.808 94.0691 198.92 93.9165C199.032 93.764 199.077 93.5743 199.046 93.3891C199.014 93.204 198.909 93.0386 198.753 92.9293C198.597 92.82 198.403 92.7758 198.213 92.8063L186.78 94.6517C186.686 94.6667 186.596 94.6996 186.515 94.7486C186.434 94.7976 186.363 94.8617 186.308 94.9373C186.252 95.0129 186.213 95.0985 186.191 95.1891C186.169 95.2797 186.166 95.3736 186.182 95.4654C186.197 95.5572 186.231 95.6452 186.281 95.7241C186.332 95.8031 186.398 95.8716 186.475 95.9258C186.552 95.9799 186.64 96.0185 186.733 96.0395C186.826 96.0604 186.922 96.0633 187.016 96.0479Z"
            fill="white"
          />
          <path
            d="M187.96 101.634L199.393 99.7885C199.582 99.7579 199.752 99.655 199.864 99.5025C199.975 99.3499 200.021 99.1602 199.989 98.975C199.958 98.7899 199.853 98.6245 199.697 98.5152C199.541 98.4059 199.346 98.3617 199.157 98.3923L187.723 100.238C187.63 100.253 187.54 100.286 187.459 100.335C187.378 100.384 187.308 100.448 187.253 100.524C187.197 100.599 187.158 100.685 187.136 100.775C187.115 100.866 187.111 100.959 187.127 101.051C187.142 101.143 187.176 101.231 187.226 101.309C187.277 101.388 187.342 101.457 187.42 101.511C187.497 101.565 187.584 101.604 187.677 101.625C187.77 101.646 187.866 101.649 187.96 101.634Z"
            fill="white"
          />
          <g clip-path="url(#clip1_1645_18957)">
            <path
              d="M89.7528 70.5667L86.0242 67.7999C85.796 67.6306 85.5382 67.5671 85.251 67.6097C84.9638 67.6522 84.7261 67.8003 84.5379 68.0539C84.3497 68.3075 84.2768 68.578 84.3194 68.8652C84.3619 69.1524 84.5083 69.379 84.7587 69.5452L89.4003 72.9894C89.6286 73.1588 89.8753 73.2239 90.1404 73.1847C90.4055 73.1454 90.6228 73.0116 90.7922 72.7833L97.5678 63.6523C97.7372 63.424 97.7951 63.1671 97.7416 62.8815C97.688 62.5959 97.5471 62.3685 97.3188 62.1991C97.0872 62.0076 96.8223 61.9339 96.5241 61.9781C96.2258 62.0223 95.9826 62.1712 95.7944 62.4248L89.7528 70.5667ZM93.2243 80.5171C91.3464 80.7952 89.5512 80.7167 87.8386 80.2817C86.1261 79.8467 84.585 79.121 83.2153 78.1046C81.8457 77.0883 80.7045 75.8236 79.7919 74.3106C78.8792 72.7977 78.2839 71.1022 78.0058 69.2243C77.731 67.3686 77.8111 65.5844 78.2461 63.8719C78.6812 62.1593 79.4068 60.6182 80.4232 59.2486C81.4395 57.8789 82.7034 56.7322 84.2147 55.8085C85.7261 54.8849 87.4207 54.284 89.2986 54.0059C91.1544 53.7311 92.9393 53.8167 94.6535 54.2628C96.3677 54.7089 97.9096 55.4401 99.2793 56.4564C100.649 57.4728 101.796 58.7366 102.719 60.248C103.643 61.7593 104.242 63.4429 104.517 65.2987C104.795 67.1765 104.711 68.9726 104.265 70.6867C103.819 72.4009 103.088 73.9428 102.071 75.3125C101.055 76.6822 99.7904 77.8233 98.2774 78.7359C96.7644 79.6486 95.08 80.2423 93.2243 80.5171ZM92.9298 78.5288C96.1333 78.0544 98.6525 76.5807 100.488 74.1077C102.323 71.6347 103.003 68.7965 102.529 65.5931C102.054 62.3896 100.581 59.8704 98.1076 58.0353C95.6346 56.2002 92.7964 55.5199 89.593 55.9942C86.3896 56.4686 83.8703 57.9423 82.0352 60.4153C80.2002 62.8883 79.5198 65.7265 79.9942 68.9299C80.4685 72.1334 81.9422 74.6526 84.4152 76.4877C86.8882 78.3228 89.7264 79.0031 92.9298 78.5288Z"
              fill="#01966B"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1645_18957">
            <rect width="353" height="297" fill="white" />
          </clipPath>
          <clipPath id="clip1_1645_18957">
            <rect
              width="32.1603"
              height="32.1603"
              fill="white"
              transform="translate(73 53.7109) rotate(-8.42298)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default CompanySucess;
