import React from "react";
import { IModalProps } from "types";
import { openNotification } from "utils/notifications";
import { useQueryClient } from "react-query";
import DeleteEntityModal from "components/entity/DeleteEntityModal";
import { QUERY_KEY_FOR_HOSPITALS } from "../../../hooks/hospital/useGetHospitals";
import { THospital } from "../../../types/hospital/hospital";
import { useDeleteHospital } from "../../../hooks/hospital/useDeleteHospital";

interface IProps extends IModalProps {
  hospital?: THospital;
}
export const DeleteHospital: React.FC<IProps> = ({
  open,
  handleClose,
  hospital,
}) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useDeleteHospital();
  const handleDelete = () => {
    if (!hospital) return;
    mutate(
      {
        id: hospital.id,
      },
      {
        onError: (err: any) => {
          openNotification({
            state: "error",
            title: "Error Occurred",
            duration: 2,
            description:
              err?.response.data.message ?? err?.response.data.error.message,
          });
        },
        onSuccess: (res: any) => {
          openNotification({
            state: "success",

            title: "Success",
            description: res.data.message,
            // duration: 0.4,
          });

          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_FOR_HOSPITALS],
            // exact: true,
          });

          handleClose();
        },
      }
    );
  };

  return (
    <DeleteEntityModal
      title="Delete Hospital"
      entity={{
        type: "hospital",
        name: hospital?.name ?? "",
      }}
      handleClose={handleClose}
      open={open}
      handleDelete={{ fn: handleDelete, isLoading: isLoading }}
    />
  );
};
