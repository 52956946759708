import { appRoutes } from "config/router/paths";
import { TEmployeeFilterPropsWithPaginationAndSearch } from "../hooks/useFetchEmployees";

export const constructSingleEmployeeRouteWithQueryParams = (
  id: number,
  params: Partial<
    Pick<TEmployeeFilterPropsWithPaginationAndSearch, "status" | "pagination">
  > = {}
) => {
  return `${appRoutes.singleEmployee(id).path}?status=${params.status?.join(",")}&offset=${params.pagination?.offset}`;
};
