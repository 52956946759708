import { WorkflowsTable } from "./WorkflowsTable";
import { DataContainerLoader } from "components/loaders/DataContainerLoader";
import { useFetchAllWorkflows } from "../hooks/useFetchAllWorkflows";
import { usePagination } from "hooks/usePagination";
import { errorFormatter } from "utils/errorHelpers";
import { ErrorWrapper } from "components/errorHandlers/ErrorWrapper";
import ErrorBoundary from "components/errorHandlers/ErrorBoundary";

export const WorkflowsViewContainer = () => {
  const { pagination, onChange } = usePagination();
  const {
    data,
    isError,
    error,
    isFetching: isLoading,
    isSuccess,
  } = useFetchAllWorkflows({
    pagination,
  });

  return (
    <>
      <div className="mt-5 flex flex-col gap-4">
        <div className="content overflow-y-hidden relative">
          <ErrorBoundary>
            <DataContainerLoader isLoading={isLoading}>
              <ErrorWrapper
                isError={isError}
                message={errorFormatter(error).message}
              >
                {isSuccess && (
                  <WorkflowsTable
                    data={data?.data}
                    loading={isLoading}
                    pagination={{ ...pagination, total: data?.total }}
                    onChange={onChange}
                  />
                )}
              </ErrorWrapper>
            </DataContainerLoader>
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
};
