import React from "react";
import { WalletTransactionsTable } from "./WalletTransactionsTable";

const WalletTransactionContainer:React.FC = () => {
  
  return (
    <div>
      <WalletTransactionsTable />
    </div>
  );
};

export default WalletTransactionContainer;
