import React from "react";
import { ModuleCards } from "./ModuleCards";
import { Form, Skeleton } from "antd";
import { TSubscriptionPriceType } from "features/billing/types/priceType";
import { TBillingCycle } from "features/billing/types/billingCycle";
import { generateRandomBgColorClassNameForSubscriptionModule } from "features/billing/utils";
import {
  ECreateCompanySubscriptionOps,
  useCreateCompanySubscriptionStateAndDispatch,
} from "features/billing/stateManagers";
import { TSubscription } from "features/billing/types/subscription";
import { SUBSCRIPTION_ICON_MAPPING } from "../../billing/cards/ModulesCard";
import { useGetCompanyActiveSubscription } from "features/billing/hooks/company/useGetCompanyActiveSubscription";
import { EmptyDataWrapper } from "components/data/EmptyDataWrapper";
import { IModuleCardProps } from "./ModuleCard";
import { ActiveModuleSubscription } from "features/billing/types/company/active-company-subscription/module";
import { useGetSubscriptionModules } from "features/billing/hooks/module/useGetSubscriptionModules";

const ModuleContainer: React.FC<{
  currency?: TSubscriptionPriceType;
  billingCycle?: TBillingCycle;
  isLoading?: boolean;
  subscriptions?: TSubscription[];
}> = ({ currency = "NGN", billingCycle = "yearly", isLoading }) => {
  const { data: sub, isLoading: isLoadingSub } =
    useGetCompanyActiveSubscription();
  const { data, isLoading: isLoadingModules } = useGetSubscriptionModules();
  const activeSubscriptionModules =
    sub?.type === "plan" ? sub.plan.modules : sub?.modules;
  const moduleDataUnParsed:
    | (IModuleCardProps &
        Pick<
          ActiveModuleSubscription["modules"][number],
          "iconUrl" | "name" | "label"
        >)[]
    | undefined = data?.data?.map((m) => ({
    ...m,
    isActive: activeSubscriptionModules?.some((a) => a.id === m.id),
    icon: null,
    title: {
      mainText: m.name,
      supportingText: m.description ?? "",
    },
    disabled: true,
    features: m?.features?.map((f) => f.name),
    // pricePerLicensedEmployee: { //Commented bcos BE does not provide data needed
    //   amount: 0,
    //   currency: PRICE_TYPE_CURRENCY[sub.currency],
    // },
    subscriptionId: m.id,
  }));

  const moduleData = moduleDataUnParsed?.map((module, index) => {
    const bgColor = generateRandomBgColorClassNameForSubscriptionModule(index);
    return {
      ...module,
      icon: (
        <div
          className={`h-6 w-6 rounded flex align-middle justify-center py-1  ${bgColor} `}
        >
          {module?.iconUrl ? (
            <img src={module?.iconUrl} alt={module.name} />
          ) : (
            SUBSCRIPTION_ICON_MAPPING?.[module.label]
          )}
        </div>
      ),
    };
  });
  const { dispatch } = useCreateCompanySubscriptionStateAndDispatch();
  return (
    <div className="flex flex-col gap-2 ">
      <p className="text-left pb-2 text-lg">
        {sub?.type === "plan"
          ? `Here are the modules purchased based on your plan.`
          : `Here are the modules purchase.`}
      </p>
      <Skeleton
        loading={isLoadingSub || isLoadingModules}
        active
        paragraph={{ rows: 40 }}
      >
        <EmptyDataWrapper isEmpty={moduleData?.length === 0}>
          <ModuleCards
            Form={Form}
            onChange={(val) =>
              dispatch({
                type: ECreateCompanySubscriptionOps.update,
                payload: { purchased: val.map((item) => +item) },
              })
            }
            data={moduleData}
          />
        </EmptyDataWrapper>
      </Skeleton>
    </div>
  );
};

export default ModuleContainer;
