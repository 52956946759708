import {
  BeatLoader,
  ScaleLoader,
  BarLoader,
  BounceLoader,
} from "react-spinners";

export const DataContainerLoader: React.FC<{
  type?: "BeatLoader" | "Scaleloader" | "BarLoader" | "BounceLoader";
  isLoading?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
}> = ({ type = "BeatLoader", isLoading = false, children, style }) => {
  return (
    <>
      {isLoading ? (
        <div className="h-44 flex justify-center items-center" style={style}>
          {type === "BeatLoader" && <BeatLoader color="var(--caramel)" />}
          {type === "Scaleloader" && <ScaleLoader color="var(--caramel)" />}
          {type === "BarLoader" && <BarLoader color="var(--caramel)" />}
          {type === "BounceLoader" && <BounceLoader color="var(--caramel)" />}
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
