import React, { useState, useEffect } from "react";

const LiveClock: React.FC<{ format?: string }> = ({
  format = "YYYY-MM-DD hh:mm:ss a",
}) => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000); // Update every second

    return () => {
      clearInterval(intervalId); // Cleanup when the component unmounts
    };
  }, []);

  const formatDate = (date: Date, format: string) => {
    const pad = (n: number) => n.toString().padStart(2, "0");

    const hours12 = date.getHours() % 12 || 12; // Convert to 12-hour format
    const amPm = date.getHours() >= 12 ? "pm" : "am";

    return format
      .replace("YYYY", date.getFullYear().toString())
      .replace("MM", pad(date.getMonth() + 1))
      .replace("DD", pad(date.getDate()))
      .replace("hh", pad(hours12))
      .replace("HH", pad(date.getHours())) // 24-hour format if needed
      .replace("mm", pad(date.getMinutes()))
      .replace("ss", pad(date.getSeconds()))
      .replace("a", amPm);
  };

  const formattedDateTime = formatDate(currentDateTime, format);

  return <>{formattedDateTime}</>;
};

export default LiveClock;
