import { useVersionChecker } from "hooks/ci-cd/useVersionChecker";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";

const NewAppVersionBanner = () => {
  const { isNewVersionAvailable } = useVersionChecker();
  const [isBannerVisible, setIsBannerVisible] = useState(isNewVersionAvailable);

  if (!isBannerVisible) return null;

  return (
    <div className="bg-caramel text-center p-4 text-white relative">
      Please reload to get the latest updates!
      <button
        onClick={() => window.location.reload()}
        className="ml-4 underline font-medium underline-offset-4"
      >
        Reload
      </button>
      <button
        onClick={() => setIsBannerVisible(false)}
        className="mx-4 underline underline-offset-4 absolute right-0 top-0.5"
      >
        <IoMdClose className="text-3xl" />
      </button>
    </div>
  );
};

export default NewAppVersionBanner;
