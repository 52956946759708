import dayjs from "dayjs";
import { useGetCompanyParamSetting } from "./useGetCompanyParamSetting";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);
export const useGetCompanyLocationSettings = () => {
  const { data, isLoading } = useGetCompanyParamSetting();
  const country = data?.value.locationSettings.country;
  const timezone = data?.value.locationSettings.timezone ?? "UTC";
  const dateAndTimeFormat = data?.value.dateAndTimeSettings ?? {
    dateFormat: "YYYY-MM-DD",
    timeFormat: "12-HR",
  };


  return {
    data: data?.value.locationSettings
      ? {
          country,
          timezone,
        }
      : null,
    isLoading,
    formatDateWithTimezone: (dateString: string) => {
      const date = new Date(dateString);
      return date.toLocaleString("en-US", {
        timeZone: timezone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
    formatTimeWithTimeZone: (value: string) => {
      const timeFormat =
        dateAndTimeFormat?.timeFormat === "24-HR" ? "HH:mm" : "h:mm a";
      return value ? dayjs(value).tz(timezone).format(timeFormat) : null;
    },
  };
};
