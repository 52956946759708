import { theme } from "antd";

export const useGetReusableStyles = () => {
  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 15,
    background: "var(--background)",
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  return { panelStyle };
};
