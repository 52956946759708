import { useParams } from "react-router-dom";
import { EmployeeProfileContainer } from "../components/MyProfile/EmployeeProfileContainer";
import {
  canUserAccessComponent,
  useGetUserPermissions,
} from "components/permission-restriction/PermissionRestrictor";
import { appRoutes } from "config/router/paths";
import { PageIntro } from "components/layout/PageIntro";
import { AppButtonList } from "components/button/AppButtonList";
import useBackNextOnSingleEmployeeProfile from "../hooks/single-employee-profile";

export const EmployeeProfile = () => {
  const params = useParams();
  const employeeId = params.id as string;
  const { userPermissions, isOwner } = useGetUserPermissions();
  const canEditProfile =
    isOwner ||
    canUserAccessComponent({
      userPermissions,
      requiredPermissions: ["manage-employees"],
    });
  const {
    canBack,
    canNext,
    currentEmployeeId,
    handleBack,
    handleNext,
    isLoading,
  } = useBackNextOnSingleEmployeeProfile(+employeeId);

  return (
    <>
      <div className="Container mt-3">
        <PageIntro
          title={"Employee Profile"}
          link={appRoutes.employeeSettings}
          comps={[
            <div className="flex gap-x-4">
              <AppButtonList
                data={[
                  {
                    label: "Back",
                    type: "button",
                    variant: "default",
                    handleClick: handleBack,
                    disabled: isLoading || !canBack,
                    isLoading,
                  },
                  {
                    label: "Next",
                    type: "button",
                    variant: "default",
                    handleClick: handleNext,
                    disabled: isLoading || !canNext,
                    isLoading,
                  },
                ]}
              />
            </div>,
          ]}
        />
        <EmployeeProfileContainer
          employeeId={currentEmployeeId}
          isOwner={canEditProfile} //this is made optional, so that users more than user (isOwner) is capable of editing employee info directly, and those with the stated permission above can do so, forgive the naming //TODO: the isOwner should be renamed appropriately
        />
      </div>
    </>
  );
};
