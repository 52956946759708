export const formatPhoneNumber = ({
  code,
  number,
}: {
  code?: string;
  number?: string;
}) => {
  if (code && number) {
    return `${code}-${number}`;
  }
  return "";
};
