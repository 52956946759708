import React from "react";

export interface IconProps
  extends React.ButtonHTMLAttributes<HTMLOrSVGElement> {}
const PersonWelcomingIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="494"
      height="350"
      viewBox="0 0 494 350"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M205.941 65.678C169.478 66.1102 139.499 48.3171 108.799 75.7489C85.1844 96.8582 80.8128 128.818 92.8224 156.203C121.628 221.852 225.5 244.332 291.528 221.025C367.424 194.157 422.36 115.046 377.756 58.153C363.822 40.3036 325.326 23.7975 302.669 24.333C261.953 25.2067 246.233 65.1895 205.941 65.678Z"
        className="fill-caramel"
      />
      <path
        opacity="0.9"
        d="M205.941 65.678C169.478 66.1102 139.499 48.3171 108.799 75.7489C85.1844 96.8582 80.8128 128.818 92.8224 156.203C121.628 221.852 225.5 244.332 291.528 221.025C367.424 194.157 422.36 115.046 377.756 58.153C363.822 40.3036 325.326 23.7975 302.669 24.333C261.953 25.2067 246.233 65.1895 205.941 65.678Z"
        fill="white"
      />
      <path
        d="M246.701 350C352.372 350 438.035 345.238 438.035 339.365C438.035 333.492 352.372 328.73 246.701 328.73C141.03 328.73 55.3672 333.492 55.3672 339.365C55.3672 345.238 141.03 350 246.701 350Z"
        fill="#FBFBFB"
      />
      <path
        d="M215.123 134.114L214.472 135.053C214.275 135.326 214.067 135.57 213.87 135.824C213.465 136.331 213.041 136.763 212.617 137.28C211.758 138.219 210.89 139.159 209.962 139.986C208.107 141.682 206.129 143.252 204.041 144.683C201.956 146.113 199.763 147.394 197.479 148.516C196.324 149.051 195.18 149.605 193.986 150.056L192.2 150.733L191.696 150.911C191.42 150.996 191.163 151.09 190.857 151.156C190.261 151.297 189.647 151.364 189.032 151.353C187.859 151.345 186.706 151.071 185.667 150.554C184.831 150.139 184.064 149.61 183.387 148.985C182.316 147.979 181.39 146.842 180.634 145.603C179.342 143.459 178.287 141.192 177.486 138.839C175.934 134.405 174.824 129.841 174.17 125.208L178.295 124.344C179.045 126.401 179.815 128.524 180.664 130.572C181.512 132.62 182.361 134.659 183.348 136.594C184.246 138.462 185.316 140.251 186.545 141.94C187.033 142.64 187.621 143.272 188.292 143.818C188.484 143.969 188.701 144.089 188.933 144.175C189.071 144.175 189.061 144.175 188.992 144.175H188.923L189.249 144.025L190.719 143.349C191.706 142.898 192.644 142.409 193.591 141.864C195.46 140.798 197.265 139.635 198.999 138.379C200.709 137.099 202.334 135.719 203.864 134.246C204.633 133.532 205.364 132.771 206.064 132C206.41 131.615 206.755 131.23 207.051 130.845L207.525 130.272L207.91 129.783L215.123 134.114Z"
        fill="#B97964"
      />
      <path
        d="M203.173 133.711L208.176 145.135L204.555 147.286L198.93 136.924L203.173 133.711Z"
        fill="#263238"
      />
      <path
        d="M217.335 124.091C209.174 123.987 200.885 133.823 200.678 134.068L207.132 148.516C207.132 148.516 221.49 137.421 222.21 133.081C222.96 128.562 222.506 124.156 217.335 124.091Z"
        className="fill-caramel"
      />
      <path
        opacity="0.3"
        d="M217.335 124.091C209.174 123.987 200.885 133.823 200.678 134.068L207.132 148.516C207.132 148.516 221.49 137.421 222.21 133.081C222.96 128.562 222.506 124.156 217.335 124.091Z"
        fill="black"
      />
      <path
        d="M177.627 122.852L171.983 118.672L170.838 126.901C170.838 126.901 176.325 128.949 179.246 127.174L179.167 125.671C179.137 125.123 178.985 124.588 178.719 124.102C178.454 123.616 178.081 123.19 177.627 122.852Z"
        fill="#B97964"
      />
      <path
        d="M164.462 118.258L164.818 124.505L170.867 126.901L172.012 118.671L164.462 118.258Z"
        fill="#B97964"
      />
      <path
        d="M288.148 122.184L296.872 120.108L290.773 114.18C290.773 114.18 285.119 116.378 286.5 121.075L288.148 122.184Z"
        fill="#B97964"
      />
      <path
        d="M301.204 118.633L300.484 112.113L290.724 114.18L296.823 120.108L301.204 118.633Z"
        fill="#B97964"
      />
      <path
        d="M259.7 331.128L251.579 331.871L244.799 313.627L252.921 312.875L259.7 331.128Z"
        fill="#B97964"
      />
      <path
        d="M346.638 292.771L340.658 298.295L325.283 286.872L331.273 281.348L346.638 292.771Z"
        fill="#B97964"
      />
      <path
        d="M338.931 297.223L343.53 289.454C343.607 289.32 343.733 289.218 343.884 289.166C344.034 289.115 344.2 289.117 344.349 289.172L351.336 291.746C351.508 291.818 351.663 291.923 351.791 292.055C351.918 292.187 352.014 292.344 352.074 292.514C352.134 292.684 352.155 292.864 352.136 293.043C352.118 293.221 352.06 293.394 351.967 293.55C350.319 296.237 349.382 297.449 347.359 300.868C346.115 302.972 343.688 307.792 341.971 310.695C340.254 313.598 337.145 312.132 337.589 310.779C339.563 304.701 339.227 301.31 338.695 298.623C338.597 298.144 338.681 297.648 338.931 297.223Z"
        fill="#263238"
      />
      <path
        d="M249.96 329.5H259.828C260.006 329.504 260.177 329.561 260.317 329.664C260.458 329.768 260.559 329.911 260.608 330.073L262.759 337.523C262.814 337.699 262.824 337.885 262.788 338.065C262.753 338.246 262.674 338.416 262.556 338.561C262.439 338.707 262.287 338.824 262.113 338.903C261.939 338.982 261.748 339.021 261.555 339.017C258.092 338.96 256.404 338.763 252.052 338.763C249.378 338.763 240.664 339.035 236.964 339.035C233.263 339.035 232.592 335.55 234.092 335.278C240.803 333.859 246.467 331.914 248.529 330.054C248.911 329.697 249.425 329.498 249.96 329.5Z"
        fill="#263238"
      />
      <path
        d="M244.808 313.627L248.292 323.04H256.699L252.93 312.875L244.808 313.627Z"
        fill="#A76755"
      />
      <path
        d="M331.284 281.348L325.294 286.881L333.218 292.762L339.208 287.238L331.284 281.348Z"
        fill="#A76755"
      />
      <path
        d="M209.39 126.602C209.39 126.602 206.034 129.035 228.642 171.038L260.872 161.127C256.096 148.811 253.471 141.116 251.004 113.562C246.223 114.066 241.487 114.898 236.833 116.052C231.856 117.244 226.968 118.748 222.198 120.552C216.041 123.07 209.39 126.602 209.39 126.602Z"
        className="fill-caramel"
      />
      <path
        opacity="0.3"
        d="M209.39 126.602C209.39 126.602 206.034 129.035 228.642 171.038L260.872 161.127C256.096 148.811 253.471 141.116 251.004 113.562C246.223 114.066 241.487 114.898 236.833 116.052C231.856 117.244 226.968 118.748 222.198 120.552C216.041 123.07 209.39 126.602 209.39 126.602Z"
        fill="black"
      />
      <path
        d="M230.017 100.805C230.61 105.577 232.475 114.07 236.787 116.052C236.787 116.052 237.034 120.993 228.291 123.68C218.669 126.639 222.153 120.552 222.153 120.552C226.998 117.734 225.597 114.07 223.504 110.876L230.017 100.805Z"
        fill="#B97964"
      />
      <path
        d="M213.842 124.326C216.22 123.152 219.249 121.724 222.151 120.568C226.926 118.731 231.833 117.221 236.835 116.05C239.647 115.317 242.489 114.772 244.887 114.387C245.114 115.026 244.433 116.679 242.765 118.962C237.486 123.133 226.226 128.554 217.927 128.356C214.296 127.069 213.388 125.707 213.842 124.326Z"
        fill="#B97964"
      />
      <path
        d="M242.757 118.961C239.172 123.535 234.888 127.573 230.047 130.939C225.924 130.461 221.862 129.596 217.919 128.355C226.178 128.553 237.438 123.132 242.757 118.961Z"
        fill="#263238"
      />
      <path
        d="M227.361 104.91L223.503 110.857C224.007 111.598 224.439 112.381 224.796 113.196C226.71 112.304 229.148 109.664 228.684 107.559C228.408 106.61 227.961 105.713 227.361 104.91Z"
        fill="#A76755"
      />
      <path
        d="M231.97 91.99C232.464 99.8532 232.858 103.169 229.227 107.66C223.77 114.405 213.517 113.297 210.556 105.706C207.902 98.895 207.537 87.1801 215.165 83.1592C216.841 82.2626 218.73 81.7883 220.652 81.7813C222.575 81.7744 224.467 82.2349 226.151 83.1194C227.834 84.0039 229.252 85.2829 230.27 86.8353C231.289 88.3877 231.874 90.162 231.97 91.99Z"
        fill="#B97964"
      />
      <path
        d="M248.285 77.8974C250.151 70.0859 245.012 62.3135 236.806 60.5374C228.601 58.7612 220.437 63.6538 218.571 71.4653C216.705 79.2769 221.845 87.0492 230.05 88.8254C238.255 90.6016 246.42 85.709 248.285 77.8974Z"
        fill="#263238"
      />
      <path
        d="M229.621 80.9817C232.76 82.9546 234.319 85.9796 233.105 87.7363C231.891 89.4931 228.358 89.324 225.21 87.3512C222.062 85.3783 220.513 82.3533 221.727 80.5965C222.941 78.8398 226.473 79.0089 229.621 80.9817Z"
        className="fill-caramel"
      />
      <path
        opacity="0.6"
        d="M229.621 80.9817C232.76 82.9546 234.319 85.9796 233.105 87.7363C231.891 89.4931 228.358 89.324 225.21 87.3512C222.062 85.3783 220.513 82.3533 221.727 80.5965C222.941 78.8398 226.473 79.0089 229.621 80.9817Z"
        fill="#FAFAFA"
      />
      <path
        d="M227.954 97.3555C228.773 95.5517 225.921 90.1969 226.533 88.1959C224.894 86.7397 208.286 91.8879 209.628 88.1301C210.911 84.5226 214.957 79.7408 224.194 81.7419C234.062 83.8838 234.92 92.01 233.815 94.6029C232.71 97.1957 231.141 99.892 231.141 99.892L227.954 97.3555Z"
        fill="#263238"
      />
      <path
        d="M234.143 98.8855C234.164 100.407 233.554 101.875 232.446 102.972C230.956 104.409 229.16 103.376 228.696 101.516C228.272 99.8437 228.548 97.0724 230.462 96.3396C230.885 96.1831 231.341 96.13 231.79 96.1853C232.239 96.2405 232.667 96.4022 233.033 96.6557C233.4 96.9091 233.693 97.2461 233.887 97.6357C234.081 98.0253 234.169 98.455 234.143 98.8855Z"
        fill="#B97964"
      />
      <path
        d="M228.644 171.035C228.644 171.035 213.703 240.404 222.032 263.279C230.696 287.075 244.798 320.294 244.798 320.294L257.972 319.58C257.972 319.58 247.117 277.606 244.443 258.648C240.781 232.512 256.462 162.523 256.462 162.523L228.644 171.035Z"
        className="fill-caramel"
      />
      <path
        opacity="0.6"
        d="M228.644 171.035C228.644 171.035 213.703 240.404 222.032 263.279C230.696 287.075 244.798 320.294 244.798 320.294L257.972 319.58C257.972 319.58 247.117 277.606 244.443 258.648C240.781 232.512 256.462 162.523 256.462 162.523L228.644 171.035Z"
        fill="#FAFAFA"
      />
      <path
        d="M259.008 319.882L243.762 320.718L241.314 315.007L258.13 313.344L259.008 319.882Z"
        className="fill-caramel"
      />
      <path
        opacity="0.2"
        d="M259.008 319.882L243.762 320.718L241.314 315.007L258.13 313.344L259.008 319.882Z"
        fill="black"
      />
      <path
        d="M244.444 176.371C242.155 184.432 244.069 196.513 248.392 204.987C249.448 198.411 250.573 192.041 251.638 186.339C249.418 182.252 246.971 178.626 244.444 176.371Z"
        className="fill-caramel"
      />
      <path
        opacity="0.4"
        d="M244.444 176.371C242.155 184.432 244.069 196.513 248.392 204.987C249.448 198.411 250.573 192.041 251.638 186.339C249.418 182.252 246.971 178.626 244.444 176.371Z"
        fill="#FAFAFA"
      />
      <path
        d="M237.151 168.415C237.151 168.415 259.335 218.892 274.029 246.605C285.87 269.02 329.813 292.478 329.813 292.478L337.708 284.512C337.708 284.512 304.867 256.751 296.676 241.551C270.466 192.897 275.854 171.647 260.894 161.125L237.151 168.415Z"
        className="fill-caramel"
      />
      <path
        opacity="0.6"
        d="M237.151 168.415C237.151 168.415 259.335 218.892 274.029 246.605C285.87 269.02 329.813 292.478 329.813 292.478L337.708 284.512C337.708 284.512 304.867 256.751 296.676 241.551C270.466 192.897 275.854 171.647 260.894 161.125L237.151 168.415Z"
        fill="#FAFAFA"
      />
      <path
        d="M339.177 283.375L328.322 294.357L321.454 290.919L333.957 278.781L339.177 283.375Z"
        className="fill-caramel"
      />
      <path
        opacity="0.2"
        d="M339.177 283.375L328.322 294.357L321.454 290.919L333.957 278.781L339.177 283.375Z"
        fill="black"
      />
      <path
        d="M260.746 159.179L263.124 161.481C263.311 161.659 263.124 161.96 262.749 162.073L228.95 172.407C228.654 172.501 228.368 172.407 228.289 172.266L227.076 169.617C226.987 169.429 227.184 169.194 227.5 169.09L260.134 159.067C260.237 159.033 260.348 159.026 260.455 159.046C260.563 159.066 260.663 159.112 260.746 159.179Z"
        fill="#263238"
      />
      <path
        d="M257.56 164L258.428 163.737C258.606 163.68 258.704 163.549 258.665 163.455L257.046 160.007C257.046 159.913 256.81 159.876 256.642 159.923L255.764 160.195C255.596 160.195 255.487 160.374 255.537 160.477L257.155 163.915C257.185 164.019 257.362 164.056 257.56 164Z"
        className="fill-caramel"
      />
      <path
        opacity="0.6"
        d="M257.56 164L258.428 163.737C258.606 163.68 258.704 163.549 258.665 163.455L257.046 160.007C257.046 159.913 256.81 159.876 256.642 159.923L255.764 160.195C255.596 160.195 255.487 160.374 255.537 160.477L257.155 163.915C257.185 164.019 257.362 164.056 257.56 164Z"
        fill="#FAFAFA"
      />
      <path
        d="M231.508 172.006L232.386 171.733C232.554 171.677 232.662 171.555 232.613 171.451L230.995 168.013C230.945 167.91 230.768 167.872 230.59 167.929L229.712 168.192C229.544 168.248 229.436 168.37 229.485 168.473L231.103 171.912C231.153 172.015 231.33 172.053 231.508 172.006Z"
        className="fill-caramel"
      />
      <path
        opacity="0.6"
        d="M231.508 172.006L232.386 171.733C232.554 171.677 232.662 171.555 232.613 171.451L230.995 168.013C230.945 167.91 230.768 167.872 230.59 167.929L229.712 168.192C229.544 168.248 229.436 168.37 229.485 168.473L231.103 171.912C231.153 172.015 231.33 172.053 231.508 172.006Z"
        fill="#FAFAFA"
      />
      <path
        d="M244.521 168.002L245.39 167.729C245.567 167.729 245.676 167.551 245.627 167.448L244.008 164.009C243.959 163.906 243.771 163.868 243.604 163.925L242.725 164.197C242.558 164.244 242.449 164.376 242.498 164.47L244.117 167.917C244.165 167.971 244.231 168.008 244.304 168.024C244.377 168.039 244.453 168.031 244.521 168.002Z"
        className="fill-caramel"
      />
      <path
        opacity="0.6"
        d="M244.521 168.002L245.39 167.729C245.567 167.729 245.676 167.551 245.627 167.448L244.008 164.009C243.959 163.906 243.771 163.868 243.604 163.925L242.725 164.197C242.558 164.244 242.449 164.376 242.498 164.47L244.117 167.917C244.165 167.971 244.231 168.008 244.304 168.024C244.377 168.039 244.453 168.031 244.521 168.002Z"
        fill="#FAFAFA"
      />
      <path
        d="M219.812 95.6156C219.891 96.245 219.605 96.8087 219.171 96.8557C218.737 96.9026 218.312 96.4329 218.233 95.7941C218.154 95.1553 218.441 94.601 218.875 94.554C219.309 94.507 219.733 94.9768 219.812 95.6156Z"
        fill="#263238"
      />
      <path
        d="M212.263 96.4921C212.352 97.1309 212.056 97.6852 211.621 97.7322C211.187 97.7791 210.763 97.3094 210.684 96.68C210.605 96.0506 210.891 95.4869 211.325 95.4305C211.76 95.3742 212.164 95.8251 212.263 96.4921Z"
        fill="#263238"
      />
      <path
        d="M211.523 95.449L209.865 95.1953C209.865 95.1953 210.842 96.2945 211.523 95.449Z"
        fill="#263238"
      />
      <path
        d="M214.404 97.3555C213.991 98.8729 213.364 100.33 212.539 101.686C212.91 101.875 213.32 101.986 213.74 102.012C214.161 102.038 214.582 101.978 214.976 101.837L214.404 97.3555Z"
        fill="#A24E3F"
      />
      <path
        d="M217.621 104.063C217.574 104.063 217.527 104.048 217.491 104.018C217.455 103.989 217.431 103.948 217.424 103.903C217.42 103.853 217.437 103.803 217.469 103.763C217.502 103.723 217.549 103.696 217.601 103.687C218.433 103.608 219.233 103.344 219.938 102.917C220.642 102.489 221.231 101.91 221.657 101.226C221.668 101.203 221.683 101.182 221.703 101.165C221.722 101.147 221.745 101.134 221.769 101.125C221.794 101.116 221.821 101.112 221.847 101.113C221.874 101.115 221.9 101.121 221.924 101.132C221.947 101.143 221.968 101.158 221.985 101.177C222.002 101.196 222.015 101.217 222.024 101.241C222.032 101.265 222.035 101.29 222.033 101.314C222.031 101.339 222.024 101.363 222.012 101.386C221.564 102.132 220.934 102.765 220.175 103.231C219.416 103.697 218.55 103.982 217.651 104.063H217.621Z"
        fill="#263238"
      />
      <path
        d="M220.751 93.3626C220.656 93.3615 220.565 93.3282 220.494 93.2686C220.129 92.9473 219.689 92.7139 219.21 92.5881C218.731 92.4622 218.227 92.4475 217.741 92.5453C217.69 92.5595 217.637 92.564 217.585 92.5584C217.533 92.5528 217.482 92.5373 217.436 92.5128C217.39 92.4883 217.35 92.4553 217.317 92.4157C217.285 92.3761 217.261 92.3307 217.247 92.2822C217.233 92.2347 217.228 92.1848 217.234 92.1356C217.24 92.0864 217.256 92.0388 217.282 91.9958C217.308 91.9527 217.343 91.9151 217.384 91.8852C217.426 91.8553 217.473 91.8338 217.524 91.8219C218.136 91.6939 218.771 91.7064 219.376 91.8584C219.982 92.0103 220.541 92.2973 221.007 92.6956C221.045 92.7305 221.074 92.7722 221.094 92.8182C221.115 92.8641 221.125 92.9135 221.125 92.9633C221.125 93.0131 221.115 93.0625 221.094 93.1084C221.074 93.1544 221.045 93.1961 221.007 93.2311C220.942 93.3037 220.85 93.3506 220.751 93.3626Z"
        fill="#263238"
      />
      <path
        d="M208.364 93.5778C208.306 93.591 208.245 93.591 208.187 93.5778C208.096 93.53 208.027 93.4506 207.996 93.3562C207.965 93.2617 207.973 93.1594 208.019 93.0705C208.289 92.5333 208.689 92.0649 209.187 91.704C209.685 91.343 210.265 91.0998 210.881 90.9944C210.984 90.9838 211.088 91.0123 211.169 91.0739C211.25 91.1354 211.303 91.225 211.315 91.3232C211.322 91.3726 211.318 91.4228 211.305 91.4709C211.291 91.519 211.267 91.564 211.235 91.6034C211.203 91.6427 211.163 91.6756 211.118 91.7001C211.072 91.7245 211.022 91.7401 210.97 91.7459C210.483 91.8323 210.024 92.0283 209.633 92.3175C209.241 92.6068 208.928 92.9809 208.72 93.4087C208.681 93.4643 208.628 93.5092 208.566 93.5389C208.504 93.5686 208.434 93.582 208.364 93.5778Z"
        fill="#263238"
      />
      <path
        d="M257.559 120.898L257.934 121.349L258.397 121.885C258.713 122.242 259.039 122.608 259.384 122.965C260.065 123.689 260.776 124.403 261.535 125.079C263.023 126.468 264.595 127.773 266.243 128.987C267.916 130.194 269.651 131.319 271.443 132.36C272.341 132.876 273.259 133.365 274.186 133.844L275.598 134.53L276.278 134.859C276.446 134.934 276.614 135.009 276.772 135.065C277.276 135.265 277.815 135.37 278.361 135.375C278.862 135.348 279.344 135.178 279.742 134.887C281.065 133.843 282.109 132.517 282.791 131.016C283.707 129.207 284.444 127.32 284.992 125.38C285.602 123.401 286.106 121.394 286.502 119.367L290.716 119.621C290.847 124.246 290.153 128.858 288.663 133.262C288.241 134.462 287.695 135.62 287.035 136.719C286.302 137.971 285.355 139.098 284.232 140.054C282.896 141.184 281.262 141.947 279.505 142.261C277.798 142.545 276.044 142.429 274.394 141.923C274.009 141.82 273.654 141.688 273.288 141.557L272.43 141.219L270.752 140.552C269.637 140.073 268.542 139.612 267.466 139.03C265.307 137.945 263.227 136.724 261.239 135.375C259.249 134.009 257.354 132.522 255.565 130.922C254.677 130.114 253.819 129.26 252.98 128.376C252.565 127.926 252.151 127.437 251.756 127.005L251.154 126.263C250.947 126 250.789 125.793 250.513 125.417L257.559 120.898Z"
        fill="#B97964"
      />
      <path
        d="M264.554 125.849L258.376 135.723L255.367 133.017L261.07 123.125L264.554 125.849Z"
        fill="#263238"
      />
      <path
        d="M251.044 113.559C254.597 113.502 263.222 123.198 263.222 123.198L255.722 135.626C255.722 135.626 246.169 127.613 246.179 123.226C246.189 118.651 246.998 113.672 251.044 113.559Z"
        className="fill-caramel"
      />
      <path
        opacity="0.3"
        d="M251.044 113.559C254.597 113.502 263.222 123.198 263.222 123.198L255.722 135.626C255.722 135.626 246.169 127.613 246.179 123.226C246.189 118.651 246.998 113.672 251.044 113.559Z"
        fill="black"
      />
      <path
        d="M337.314 301.439C337.04 301.429 336.776 301.334 336.564 301.167C336.465 301.104 336.386 301.017 336.336 300.914C336.285 300.811 336.265 300.697 336.278 300.585C336.367 299.57 339.041 298.236 339.337 298.086C339.369 298.065 339.407 298.055 339.446 298.055C339.484 298.055 339.522 298.065 339.554 298.086C339.584 298.109 339.606 298.14 339.618 298.175C339.63 298.21 339.632 298.247 339.623 298.283C339.317 299.298 338.636 301.045 337.65 301.355C337.544 301.402 337.43 301.43 337.314 301.439ZM339.1 298.621C338.054 299.185 336.722 300.068 336.673 300.594C336.665 300.645 336.674 300.697 336.699 300.742C336.724 300.788 336.763 300.825 336.811 300.848C336.903 300.933 337.018 300.993 337.143 301.021C337.268 301.049 337.399 301.044 337.521 301.007C338.064 300.857 338.636 299.974 339.1 298.64V298.621Z"
        className="fill-caramel"
      />
      <path
        d="M336.947 298.981C336.513 299.016 336.08 298.914 335.713 298.69C335.595 298.592 335.514 298.459 335.486 298.312C335.457 298.166 335.482 298.014 335.555 297.882C335.609 297.746 335.694 297.623 335.805 297.524C335.917 297.424 336.051 297.351 336.197 297.309C337.342 296.943 339.434 298.042 339.522 298.089C339.555 298.105 339.582 298.13 339.601 298.159C339.62 298.189 339.63 298.223 339.631 298.258C339.63 298.29 339.62 298.322 339.603 298.349C339.585 298.377 339.561 298.401 339.532 298.418C338.726 298.789 337.842 298.982 336.947 298.981ZM336.74 297.61C336.599 297.608 336.459 297.627 336.325 297.666C336.234 297.693 336.151 297.739 336.081 297.801C336.011 297.863 335.956 297.939 335.921 298.023C335.822 298.248 335.921 298.342 335.921 298.408C336.365 298.765 338.003 298.615 338.94 298.248C338.257 297.903 337.509 297.686 336.74 297.61Z"
        className="fill-caramel"
      />
      <path
        d="M245.579 331.061C244.888 331.116 244.197 330.961 243.605 330.619C243.45 330.511 243.324 330.369 243.236 330.206C243.149 330.043 243.103 329.863 243.102 329.68C243.09 329.58 243.107 329.48 243.15 329.389C243.194 329.298 243.262 329.22 243.348 329.163C244.246 328.58 247.621 330.046 248.283 330.337C248.32 330.355 248.351 330.383 248.371 330.419C248.39 330.454 248.397 330.495 248.391 330.534C248.388 330.573 248.371 330.609 248.345 330.638C248.318 330.666 248.282 330.686 248.243 330.694C247.37 330.901 246.477 331.024 245.579 331.061ZM244 329.435C243.866 329.424 243.733 329.45 243.615 329.51C243.585 329.53 243.562 329.557 243.548 329.589C243.534 329.62 243.53 329.655 243.536 329.689C243.539 329.812 243.572 329.933 243.632 330.042C243.692 330.151 243.777 330.245 243.881 330.318C244.661 330.882 246.516 330.666 247.602 330.478C246.463 329.96 245.249 329.608 244 329.435Z"
        className="fill-caramel"
      />
      <path
        d="M248.244 330.734H248.165C247.05 330.302 244.8 328.583 244.928 327.69C244.928 327.493 245.086 327.249 245.599 327.192C247.573 326.948 248.402 330.377 248.432 330.518C248.44 330.551 248.439 330.586 248.428 330.619C248.418 330.652 248.399 330.682 248.372 330.706C248.334 330.727 248.289 330.737 248.244 330.734ZM245.777 327.549H245.649C245.441 327.549 245.323 327.634 245.313 327.728C245.244 328.235 246.793 329.607 247.938 330.189C247.691 329.381 246.961 327.549 245.777 327.549Z"
        className="fill-caramel"
      />
      <path
        d="M219.072 94.5662L217.414 94.3125C217.414 94.3125 218.391 95.4117 219.072 94.5662Z"
        fill="#263238"
      />
      <path
        d="M157.337 28.918C155.591 31.0035 145.969 33.4555 138.321 32.7791C135.687 49.2382 144.894 68.1868 144.894 68.1868C144.894 68.1868 164.877 65.9227 165.37 58.0878C165.864 50.2528 157.337 28.918 157.337 28.918Z"
        className="fill-caramel"
      />
      <path
        opacity="0.4"
        d="M157.337 28.918C155.591 31.0035 145.969 33.4555 138.321 32.7791C135.687 49.2382 144.894 68.1868 144.894 68.1868C144.894 68.1868 164.877 65.9227 165.37 58.0878C165.864 50.2528 157.337 28.918 157.337 28.918Z"
        fill="#FAFAFA"
      />
      <path
        d="M308.607 16.3945C310.393 18.7901 319.462 21.1669 326.478 19.9268C330.238 39.411 323.271 62.5589 323.271 62.5589C323.271 62.5589 304.65 60.9806 303.535 51.6707C302.42 42.3608 308.607 16.3945 308.607 16.3945Z"
        className="fill-caramel"
      />
      <path
        opacity="0.4"
        d="M308.607 16.3945C310.393 18.7901 319.462 21.1669 326.478 19.9268C330.238 39.411 323.271 62.5589 323.271 62.5589C323.271 62.5589 304.65 60.9806 303.535 51.6707C302.42 42.3608 308.607 16.3945 308.607 16.3945Z"
        fill="#FAFAFA"
      />
      <path
        d="M177.626 185.138C177.484 185.158 177.339 185.151 177.2 185.117C177.061 185.083 176.93 185.023 176.815 184.941C176.701 184.859 176.604 184.756 176.531 184.638C176.459 184.52 176.411 184.39 176.392 184.255L152.955 25.9863C152.936 25.8465 152.946 25.7045 152.986 25.5686C153.025 25.4328 153.093 25.3059 153.185 25.1955C153.277 25.0851 153.392 24.9934 153.522 24.9259C153.652 24.8584 153.795 24.8165 153.942 24.8026C154.085 24.784 154.23 24.7924 154.369 24.8272C154.508 24.8621 154.638 24.9227 154.753 25.0055C154.867 25.0884 154.964 25.192 155.036 25.3103C155.109 25.4286 155.156 25.5593 155.175 25.695L178.612 183.954C178.632 184.094 178.621 184.236 178.582 184.372C178.542 184.508 178.474 184.634 178.382 184.745C178.29 184.855 178.176 184.947 178.046 185.014C177.916 185.082 177.773 185.124 177.626 185.138Z"
        className="fill-caramel"
      />
      <path
        opacity="0.2"
        d="M177.626 185.138C177.484 185.158 177.339 185.151 177.2 185.117C177.061 185.083 176.93 185.023 176.815 184.941C176.701 184.859 176.604 184.756 176.531 184.638C176.459 184.52 176.411 184.39 176.392 184.255L152.955 25.9863C152.936 25.8465 152.946 25.7045 152.986 25.5686C153.025 25.4328 153.093 25.3059 153.185 25.1955C153.277 25.0851 153.392 24.9934 153.522 24.9259C153.652 24.8584 153.795 24.8165 153.942 24.8026C154.085 24.784 154.23 24.7924 154.369 24.8272C154.508 24.8621 154.638 24.9227 154.753 25.0055C154.867 25.0884 154.964 25.192 155.036 25.3103C155.109 25.4286 155.156 25.5593 155.175 25.695L178.612 183.954C178.632 184.094 178.621 184.236 178.582 184.372C178.542 184.508 178.474 184.634 178.382 184.745C178.29 184.855 178.176 184.947 178.046 185.014C177.916 185.082 177.773 185.124 177.626 185.138Z"
        fill="black"
      />
      <path
        d="M289.302 173.376C289.449 173.394 289.598 173.384 289.741 173.346C289.884 173.309 290.017 173.244 290.133 173.156C290.249 173.069 290.345 172.96 290.416 172.836C290.487 172.712 290.531 172.576 290.546 172.436L314.032 14.177C314.053 14.0413 314.046 13.9029 314.01 13.7699C313.975 13.6369 313.912 13.5118 313.826 13.4019C313.74 13.2919 313.632 13.1992 313.508 13.1291C313.384 13.059 313.247 13.0128 313.104 12.9933C312.958 12.975 312.808 12.9849 312.666 13.0226C312.523 13.0602 312.39 13.1248 312.274 13.2124C312.158 13.3 312.062 13.4089 311.991 13.5327C311.92 13.6564 311.876 13.7925 311.861 13.9327L288.375 172.192C288.335 172.466 288.41 172.743 288.584 172.965C288.757 173.186 289.016 173.334 289.302 173.376Z"
        className="fill-caramel"
      />
      <path
        opacity="0.2"
        d="M289.302 173.376C289.449 173.394 289.598 173.384 289.741 173.346C289.884 173.309 290.017 173.244 290.133 173.156C290.249 173.069 290.345 172.96 290.416 172.836C290.487 172.712 290.531 172.576 290.546 172.436L314.032 14.177C314.053 14.0413 314.046 13.9029 314.01 13.7699C313.975 13.6369 313.912 13.5118 313.826 13.4019C313.74 13.2919 313.632 13.1992 313.508 13.1291C313.384 13.059 313.247 13.0128 313.104 12.9933C312.958 12.975 312.808 12.9849 312.666 13.0226C312.523 13.0602 312.39 13.1248 312.274 13.2124C312.158 13.3 312.062 13.4089 311.991 13.5327C311.92 13.6564 311.876 13.7925 311.861 13.9327L288.375 172.192C288.335 172.466 288.41 172.743 288.584 172.965C288.757 173.186 289.016 173.334 289.302 173.376Z"
        fill="black"
      />
      <path
        d="M152.019 29.33L156.459 60.4068C156.487 60.6032 156.558 60.7917 156.669 60.9593C156.78 61.1269 156.928 61.2696 157.102 61.3776C157.277 61.4856 157.474 61.5564 157.679 61.585C157.885 61.6137 158.095 61.5995 158.295 61.5436C168.222 58.838 223.425 45.3475 308.222 53.9341C308.582 53.9736 308.945 53.8824 309.237 53.6786C309.53 53.4749 309.731 53.1736 309.801 52.8349C311.399 44.9624 314.153 22.5378 314.853 16.7978C314.895 16.4359 314.786 16.0728 314.55 15.7865C314.314 15.5002 313.969 15.3135 313.59 15.2665C302.202 13.8949 224.471 5.69352 153.015 27.8269C152.692 27.9288 152.416 28.1343 152.234 28.4085C152.053 28.6826 151.976 29.0083 152.019 29.33Z"
        className="fill-caramel"
      />
      <path
        opacity="0.6"
        d="M152.019 29.33L156.459 60.4068C156.487 60.6032 156.558 60.7917 156.669 60.9593C156.78 61.1269 156.928 61.2696 157.102 61.3776C157.277 61.4856 157.474 61.5564 157.679 61.585C157.885 61.6137 158.095 61.5995 158.295 61.5436C168.222 58.838 223.425 45.3475 308.222 53.9341C308.582 53.9736 308.945 53.8824 309.237 53.6786C309.53 53.4749 309.731 53.1736 309.801 52.8349C311.399 44.9624 314.153 22.5378 314.853 16.7978C314.895 16.4359 314.786 16.0728 314.55 15.7865C314.314 15.5002 313.969 15.3135 313.59 15.2665C302.202 13.8949 224.471 5.69352 153.015 27.8269C152.692 27.9288 152.416 28.1343 152.234 28.4085C152.053 28.6826 151.976 29.0083 152.019 29.33Z"
        fill="#FAFAFA"
      />
      <path
        d="M166.257 34.7591L170.934 33.8197L174.24 42.0023L175.079 33.0212L179.747 32.0817L183.842 40.1046L183.901 31.2832L188.559 30.3438L187.947 46.2204L183.122 47.1598L178.493 38.1224L177.507 48.2308L172.681 49.1702L166.257 34.7591Z"
        fill="#FAFAFA"
      />
      <path
        d="M190.851 30.1758L204.163 28.2969L204.598 31.5661L196.269 32.7311L196.595 35.1736L204.321 34.0933L204.736 37.2216L197.009 38.302L197.364 41.3364L205.94 40.1433L206.404 43.6192L192.845 45.4981L190.851 30.1758Z"
        fill="#FAFAFA"
      />
      <path
        d="M207.545 27.9308L212.538 27.4141L213.604 39.035L221.39 38.2365L221.745 41.9943L208.917 43.3095L207.545 27.9308Z"
        fill="#FAFAFA"
      />
      <path
        d="M233.933 34.8176L238.384 35.757C238.2 36.8423 237.779 37.879 237.15 38.8008C236.573 39.6308 235.786 40.3092 234.861 40.7737C233.753 41.2821 232.547 41.5698 231.318 41.6192C229.851 41.7681 228.367 41.6176 226.966 41.1776C225.753 40.6785 224.723 39.8481 224.006 38.7915C223.061 37.4095 222.551 35.7993 222.536 34.1506C222.277 31.9876 222.93 29.8152 224.351 28.1099C225.113 27.3403 226.03 26.7247 227.047 26.3002C228.065 25.8757 229.161 25.6509 230.272 25.6392C231.947 25.4308 233.648 25.7265 235.137 26.4847C236.445 27.3125 237.43 28.5275 237.94 29.9419L233.568 31.1819C233.448 30.8202 233.264 30.4804 233.025 30.1767C232.723 29.8355 232.336 29.5709 231.9 29.4064C231.191 29.1306 230.401 29.108 229.676 29.3427C228.951 29.5775 228.339 30.0539 227.953 30.684C227.474 31.6494 227.293 32.7245 227.43 33.7842C227.516 35.494 227.845 36.6495 228.417 37.2508C228.715 37.5504 229.083 37.7789 229.491 37.9177C229.899 38.0565 230.336 38.1015 230.766 38.0493C231.17 38.0446 231.569 37.9584 231.937 37.7965C232.304 37.6346 232.631 37.4006 232.897 37.1098C233.443 36.4427 233.8 35.6535 233.933 34.8176Z"
        fill="#FAFAFA"
      />
      <path
        d="M240.209 33.0204C240.132 31.9398 240.281 30.8553 240.647 29.8297C241.012 28.8041 241.588 27.8578 242.341 27.0455C243.148 26.2894 244.106 25.6952 245.159 25.2982C246.211 24.9012 247.337 24.7095 248.469 24.7344C249.593 24.6279 250.727 24.7395 251.804 25.0624C252.881 25.3854 253.879 25.913 254.735 26.6133C255.504 27.374 256.102 28.2766 256.49 29.2654C256.879 30.2542 257.051 31.3083 256.995 32.3627C257.081 33.9057 256.776 35.4456 256.107 36.8533C255.503 38.0257 254.553 39.0051 253.373 39.6716C252.001 40.4011 250.455 40.7828 248.883 40.7802C247.331 40.884 245.774 40.6462 244.334 40.085C243.095 39.5428 242.062 38.6506 241.374 37.5297C240.565 36.1544 240.162 34.5961 240.209 33.0204ZM245.212 32.8888C245.125 34.0787 245.474 35.2606 246.199 36.2333C246.507 36.5647 246.893 36.8225 247.325 36.9857C247.757 37.1489 248.223 37.213 248.686 37.1727C249.159 37.1829 249.629 37.0909 250.06 36.9038C250.49 36.7168 250.871 36.4393 251.173 36.0923C251.861 34.9999 252.156 33.7232 252.012 32.4567C252.096 31.3225 251.745 30.1976 251.025 29.2908C250.709 28.9606 250.318 28.704 249.882 28.5411C249.446 28.3782 248.976 28.3132 248.508 28.3513C248.045 28.35 247.586 28.4481 247.168 28.6383C246.749 28.8286 246.381 29.1062 246.091 29.4505C245.396 30.4649 245.087 31.6746 245.212 32.8794V32.8888Z"
        fill="#FAFAFA"
      />
      <path
        d="M260.035 24.6992H266.617L268.887 34.1594L271.679 24.765L278.261 24.8213L277.817 40.3222H273.712L274.048 28.4946L270.554 40.294H266.844L264.031 28.4382L263.696 40.2658H259.591L260.035 24.6992Z"
        fill="#FAFAFA"
      />
      <path
        d="M281.874 24.9727L295.265 25.5457L295.058 28.8526L286.67 28.4956L286.502 31.0039L294.278 31.3327L294.071 34.4423L286.295 34.1135L286.098 37.1761L294.722 37.533L294.485 41.0466L280.848 40.4641L281.874 24.9727Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};

export default PersonWelcomingIcon;
