import axios from "axios";
import { MICROSERVICE_ENDPOINTS } from "config/enviroment";
import { useQuery } from "react-query";
import { ICurrentCompany } from "types";
import { useApiAuth } from "hooks/useApiAuth";
import { TConferenceRoomBookingPolicy } from "../../types";

export const QUERY_KEY_FOR_CONFERENCE_ROOM_BOOKING_POLICY =
  "conference-room-booking-policy";
const getData = async (
  auth: ICurrentCompany
): Promise<TConferenceRoomBookingPolicy> => {
  const url = `${MICROSERVICE_ENDPOINTS.UTILITY}/self-service/conference-room/policy`;

  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${auth.token}`,
      "x-company-id": auth.companyId,
    },
  };

  const res = await axios.get(url, config);
  const item: TConferenceRoomBookingPolicy = res.data.data;

  const data: TConferenceRoomBookingPolicy = item;

  return data;
};

export const useGetConferenceRoomBookingPolicy = () => {
  const { token, companyId } = useApiAuth();

  const queryData = useQuery(
    [QUERY_KEY_FOR_CONFERENCE_ROOM_BOOKING_POLICY],
    () => getData({ companyId, token }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
    }
  );

  return queryData;
};
