import { TSingleEmployeePayroll } from "../types";

export const displaySubTotalForEmployeePayrollBreakdown = (
  componentType: "allowance" | "gross-pay" | "deduction",
  employeePayroll?: TSingleEmployeePayroll
): number => {
  if (!employeePayroll) return 0;
  switch (componentType) {
    case "allowance":
      return +employeePayroll.totalAllowances;
    case "deduction":
      return +employeePayroll.totalDeductions;
    case "gross-pay":
      return +employeePayroll.grossPay;

    default:
      return 0;
  }
};
