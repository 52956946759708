export const isAccordianPanelActive = (
  key: string,
  accordianKeys: string | string[]
): boolean => {
  if (typeof accordianKeys === "string") {
    return key === accordianKeys;
  }
  if (typeof accordianKeys === "object") {
    return accordianKeys.includes(key);
  }
  return false;
};
