import React from "react";
import { AppButton, IAppBtnProps } from "./AppButton";

interface IProps {
  data: (IAppBtnProps & { hidden?: boolean })[];
}

export const AppButtonList: React.FC<IProps> = ({ data }) => {
  return (
    <div className="flex gap-3">
      {data
        .filter((b) => b.hidden !== true)
        .map((item, i) => (
          <AppButton key={i} {...item} />
        ))}
    </div>
  );
};
