import axios from "axios";
import { useQuery } from "react-query";
import { MICROSERVICE_ENDPOINTS } from "config/enviroment";
import { useApiAuth } from "hooks/useApiAuth";
import { ICurrentCompany } from "types";
import { AnalyticsRecordProps } from "../types";

interface IGetDataProps {
  branchId?: number;
  departmentId?: number;
  date?: string;
}

export const QUERY_KEY_FOR_ANALYTICS_RECORDS = "analyticsRecords";

const getData = async (
  auth: ICurrentCompany,
  filter: IGetDataProps
): Promise<AnalyticsRecordProps> => {
  const { branchId, date, departmentId } = filter;

  const url = `${MICROSERVICE_ENDPOINTS.TIME_AND_ATTENDANCE}/dashboard/analytics`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${auth.token}`,
      "x-company-id": auth.companyId,
    },
    params: {
      branchId,
      departmentId,
      date,
    },
  };
  const res = await axios.get(url, config);

  const item: AnalyticsRecordProps = res.data.data;
  const data: AnalyticsRecordProps = {
    ...item,
  };

  return data;
};
export const useGetAnalyticsRecord = (filter?: IGetDataProps) => {
  const { companyId, token } = useApiAuth();
  const queryData = useQuery(
    [
      QUERY_KEY_FOR_ANALYTICS_RECORDS,
      filter?.branchId,
      filter?.departmentId,
      filter?.date,
    ],
    () => getData({ token, companyId }, { ...filter }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
    }
  );

  return queryData;
};
