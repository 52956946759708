import { useApiAuth } from "hooks/useApiAuth";
import { EmployeeProfileContainer } from "../components/MyProfile/EmployeeProfileContainer";
import {
  canUserAccessComponent,
  useGetUserPermissions,
} from "components/permission-restriction/PermissionRestrictor";
import { PageIntro } from "components/layout/PageIntro";
import { appRoutes } from "config/router/paths";

export const MyProfile = () => {
  const { currentUserEmployeeId } = useApiAuth();
  const { userPermissions, isOwner } = useGetUserPermissions();
  const canEditProfile =
    isOwner ||
    canUserAccessComponent({
      userPermissions,
      requiredPermissions: ["manage-employees"],
    });

  return (
    <>
      <div className="Container mt-3">
        <PageIntro
          title={"Employee Profile"}
          link={appRoutes.employeeSettings}
        />

        <EmployeeProfileContainer
          employeeId={currentUserEmployeeId}
          isOwner={canEditProfile}
        />
      </div>
    </>
  );
};
