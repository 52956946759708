import { Form, Modal, Select } from "antd";
import { AppButton } from "components/button/AppButton";
import { IModalProps } from "types";
import { generalValidationRules } from "utils/formHelpers/validation";
import { useEnrollEmployee } from "../hooks/useEnrollEmployee";
import { openNotification } from "utils/notifications";
import { useQueryClient } from "react-query";
import { GlobalContext, EGlobalOps } from "stateManagers/GlobalContextProvider";
import { useContext } from "react";
import { QUERY_KEY_FOR_ENROLLMENT_STATUS_CHECK } from "../hooks/useCheckEnrollmentStatus";

export const EnrollEmployee = ({ handleClose, open }: IModalProps) => {
  const { isLoading, mutate } = useEnrollEmployee();
  const [form] = Form.useForm();
  const globalCtx = useContext(GlobalContext);
  const { dispatch } = globalCtx;
  const queryClient = useQueryClient();

  const handleSubmit = (values: any) => {
    mutate(
      {
        ...values,
      },
      {
        onError: (err: any) => {
          openNotification({
            state: "error",
            title: "Error Occurred",
            description:
              err?.response.data.message ?? err?.response.data.error.message,
          });
        },
        onSuccess: (res: any) => {
          openNotification({
            state: "success",
            title: "Success",
            description: res.data.message,
          });

          form.resetFields();
          dispatch({ type: EGlobalOps.setShowInitialSetup, payload: true });
          queryClient.invalidateQueries([QUERY_KEY_FOR_ENROLLMENT_STATUS_CHECK]);
          handleClose();
        },
      }
    );
  };

  return (
    <Modal title="Enroll Employee" footer={null} open={open} onCancel={() => handleClose()}>
      <Form layout="vertical" onFinish={handleSubmit} requiredMark={false}>
        <Form.Item
          label="Select preference"
          name="employeeType"
          rules={generalValidationRules}
        >
          <Select
            allowClear
            placeholder="Select"
            options={[
              { label: "Enroll All", value: "all" },
              { label: "Licensed Users", value: "licensed" },
              { label: "Unlicensed Users", value: "unlicensed" },
            ]}
          />
        </Form.Item>

        <AppButton type="submit" label="Enroll" isLoading={isLoading} />
      </Form>
    </Modal>
  );
};
