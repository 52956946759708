import PageSubHeader from "components/layout/PageSubHeader";

import { RequisitionPolicyForm } from "../../requisitions/components/RequisitionSetting";

export const MonetaryRequestSetting = () => {
  return (
    <div className="flex flex-col gap-4">
      <PageSubHeader
        description={`You can now select the workflow approval for monetary requisition`}
      />
      <MonetaryRequestPolicy />
    </div>
  );
};

const MonetaryRequestPolicy = () => {
  return (
    <div className="mt-4 bg-card py-6 px-6 flex flex-col gap-4">
      <MonetaryRequestPolicyForm />
    </div>
  );
};

const MonetaryRequestPolicyForm = () => {
  return <RequisitionPolicyForm type="money" />;
};
