import { LOCAL_STORAGE_APP_VERSION } from "constants/localStorageKeys";
import { useState, useEffect } from "react";

const APP_VERSION_FILE_LOCATION = "/version.json"; //this is in the app public folder
export const useVersionChecker = (interval = 60000) => {
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);

  useEffect(() => {
    const initialVersion = localStorage.getItem(LOCAL_STORAGE_APP_VERSION);

    const checkForUpdate = async () => {
      try {
        const response = await fetch(APP_VERSION_FILE_LOCATION);
        const { version } = await response.json();
        if (initialVersion && initialVersion !== version) {
          setIsNewVersionAvailable(true);
        }
        localStorage.setItem(LOCAL_STORAGE_APP_VERSION, version);
      } catch (error) {
        console.error("Failed to check version:", error);
      }
    };

    checkForUpdate(); // Run on mount
    const intervalId = setInterval(checkForUpdate, interval);

    return () => clearInterval(intervalId);
  }, [interval]);

  return { isNewVersionAvailable };
};
