export interface IconProps
  extends React.ButtonHTMLAttributes<HTMLOrSVGElement> {}

const PersonTrashIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="430"
      height="320"
      viewBox="0 0 430 320"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M429.732 281.211H0.268311V281.426H429.732V281.211Z"
        fill="#EBEBEB"
      />
      <path
        d="M73.7753 289.391H45.3276V289.605H73.7753V289.391Z"
        fill="#EBEBEB"
      />
      <path
        d="M183.795 287.062H147.265V287.277H183.795V287.062Z"
        fill="#EBEBEB"
      />
      <path
        d="M97.0351 292.398H80.5522V292.613H97.0351V292.398Z"
        fill="#EBEBEB"
      />
      <path d="M386.7 295.93H373.618V296.144H386.7V295.93Z" fill="#EBEBEB" />
      <path
        d="M365.965 295.93H336.512V296.144H365.965V295.93Z"
        fill="#EBEBEB"
      />
      <path
        d="M285.286 289.742H264.818V289.957H285.286V289.742Z"
        fill="#EBEBEB"
      />
      <path
        d="M203.834 242.904H37.9924C36.6916 242.904 35.4441 242.388 34.5244 241.468C33.6046 240.548 33.0879 239.301 33.0879 238V4.86153C33.0992 3.56824 33.6209 2.33177 34.5395 1.42127C35.458 0.510772 36.699 -4.95824e-05 37.9924 3.60968e-09H203.834C205.135 3.60969e-09 206.382 0.51672 207.302 1.43649C208.222 2.35625 208.738 3.60372 208.738 4.90447V238C208.738 239.301 208.222 240.548 207.302 241.468C206.382 242.388 205.135 242.904 203.834 242.904ZM37.9924 0.171786C36.7493 0.174057 35.5577 0.668881 34.6787 1.54789C33.7997 2.42689 33.3049 3.61843 33.3026 4.86153V238C33.3049 239.243 33.7997 240.435 34.6787 241.314C35.5577 242.193 36.7493 242.688 37.9924 242.69H203.834C205.077 242.688 206.269 242.193 207.148 241.314C208.027 240.435 208.521 239.243 208.524 238V4.86153C208.521 3.61843 208.027 2.42689 207.148 1.54789C206.269 0.668881 205.077 0.174057 203.834 0.171786H37.9924Z"
        fill="#EBEBEB"
      />
      <path
        d="M389.628 242.904H223.769C222.469 242.902 221.223 242.385 220.304 241.466C219.385 240.546 218.867 239.3 218.865 238V4.86153C218.879 3.56894 219.401 2.33377 220.319 1.42375C221.237 0.513732 222.477 0.00218879 223.769 0H389.628C390.919 0.00447028 392.155 0.517288 393.07 1.4273C393.985 2.33732 394.504 3.5712 394.516 4.86153V238C394.516 239.298 394.001 240.543 393.085 241.462C392.169 242.381 390.926 242.9 389.628 242.904ZM223.769 0.171786C222.526 0.174057 221.335 0.668881 220.456 1.54789C219.577 2.42689 219.082 3.61843 219.08 4.86153V238C219.082 239.243 219.577 240.435 220.456 241.314C221.335 242.193 222.526 242.688 223.769 242.69H389.628C390.871 242.688 392.063 242.193 392.942 241.314C393.821 240.435 394.316 239.243 394.318 238V4.86153C394.316 3.61843 393.821 2.42689 392.942 1.54789C392.063 0.668881 390.871 0.174057 389.628 0.171786H223.769Z"
        fill="#EBEBEB"
      />
      <path
        d="M264.388 121.43L357.041 121.43V28.5282L264.388 28.5282V121.43Z"
        fill="#F0F0F0"
      />
      <path
        d="M264.388 28.5391H262.791V121.441H264.388V28.5391Z"
        fill="#E0E0E0"
      />
      <path
        d="M272.574 115.563L348.864 115.562V34.3939L272.574 34.3939V115.563Z"
        fill="#FAFAFA"
      />
      <path
        d="M302.611 97.9159H326.772C332.012 97.9159 335.516 93.6728 334.597 88.4678L329.899 61.5576C329.377 58.9538 327.989 56.6032 325.962 54.8878C323.935 53.1725 321.387 52.1932 318.733 52.1094H294.631C289.392 52.1094 285.887 56.3525 286.806 61.5576L291.505 88.4678C292.023 91.0625 293.401 93.4066 295.416 95.1211C297.432 96.8356 299.966 97.8203 302.611 97.9159Z"
        fill="#F0F0F0"
      />
      <path
        d="M90.4299 149.641H69.8157V276.805H90.4299V149.641Z"
        fill="#F0F0F0"
      />
      <path
        d="M180.883 276.812H71.9199V281.202H180.883V276.812Z"
        fill="#F0F0F0"
      />
      <path
        d="M90.4299 276.812H182.696L182.696 149.648H90.4299L90.4299 276.812Z"
        fill="#F5F5F5"
      />
      <path
        d="M94.1403 238.562H178.985V215.852H94.1403V238.562Z"
        fill="#F0F0F0"
      />
      <path
        d="M94.1403 268.289H178.985V245.579H94.1403V268.289Z"
        fill="#F0F0F0"
      />
      <path
        d="M168.945 214.461H104.182C103.622 214.461 103.168 214.915 103.168 215.474V216.565C103.168 217.125 103.622 217.579 104.182 217.579H168.945C169.504 217.579 169.958 217.125 169.958 216.565V215.474C169.958 214.915 169.504 214.461 168.945 214.461Z"
        fill="#F5F5F5"
      />
      <path
        d="M168.945 244.188H104.182C103.622 244.188 103.168 244.641 103.168 245.201V246.292C103.168 246.852 103.622 247.305 104.182 247.305H168.945C169.504 247.305 169.958 246.852 169.958 246.292V245.201C169.958 244.641 169.504 244.188 168.945 244.188Z"
        fill="#F5F5F5"
      />
      <path
        d="M94.1403 179.359H178.985V156.649H94.1403V179.359Z"
        fill="#F0F0F0"
      />
      <path
        d="M94.1403 208.852H178.985V186.142H94.1403V208.852Z"
        fill="#F0F0F0"
      />
      <path
        d="M168.945 155.266H104.182C103.622 155.266 103.168 155.719 103.168 156.279V157.37C103.168 157.93 103.622 158.384 104.182 158.384H168.945C169.504 158.384 169.958 157.93 169.958 157.37V156.279C169.958 155.719 169.504 155.266 168.945 155.266Z"
        fill="#F5F5F5"
      />
      <path
        d="M168.945 184.742H104.182C103.622 184.742 103.168 185.196 103.168 185.756V186.847C103.168 187.406 103.622 187.86 104.182 187.86H168.945C169.504 187.86 169.958 187.406 169.958 186.847V185.756C169.958 185.196 169.504 184.742 168.945 184.742Z"
        fill="#F5F5F5"
      />
      <path
        d="M179.355 21.5234H63.6487V123.1H179.355V21.5234Z"
        fill="#E6E6E6"
      />
      <path
        d="M191.757 17.5703H56.1585C56.0078 17.5703 55.8585 17.6001 55.7194 17.6581C55.5802 17.716 55.4539 17.8009 55.3477 17.9079C55.2415 18.0149 55.1575 18.1419 55.1006 18.2815C55.0437 18.4211 55.015 18.5705 55.0161 18.7213C55.0161 19.0243 55.1365 19.3148 55.3507 19.5291C55.565 19.7433 55.8555 19.8636 56.1585 19.8636H191.757C192.061 19.8637 192.353 19.7436 192.568 19.5296C192.784 19.3156 192.906 19.025 192.908 18.7213C192.908 18.416 192.787 18.1233 192.571 17.9074C192.355 17.6916 192.063 17.5703 191.757 17.5703Z"
        fill="#E6E6E6"
      />
      <path
        d="M184.268 21.5234H65.9419V123.1H184.268V21.5234Z"
        fill="#F0F0F0"
      />
      <path
        d="M179.355 123.109H63.6487V126.365H182.739L179.355 123.109Z"
        fill="#E6E6E6"
      />
      <path
        d="M192.444 123.109H74.1272L77.5028 126.365H195.829L192.444 123.109Z"
        fill="#F0F0F0"
      />
      <path
        d="M177.396 116.224V28.3906L72.8219 28.3906V116.224H177.396Z"
        fill="#FAFAFA"
      />
      <path
        d="M96.5627 116.224L136.898 28.3906H155.949L115.605 116.224H96.5627Z"
        fill="white"
      />
      <path
        d="M119.693 116.224L160.037 28.3906H167.458L127.123 116.224H119.693Z"
        fill="white"
      />
      <path
        d="M177.396 116.224V28.3906H176.52V116.224H177.396Z"
        fill="#E6E6E6"
      />
      <g opacity="0.4">
        <path
          d="M72.899 15.5156H61.0715V99.3383H72.899V15.5156Z"
          fill="#F0F0F0"
        />
        <path
          d="M77.5115 15.5156H68.2952V99.3383H77.5115V15.5156Z"
          fill="#E6E6E6"
        />
        <path
          d="M84.7264 15.5156H72.8989V99.3383H84.7264V15.5156Z"
          fill="#F0F0F0"
        />
        <path
          d="M89.3386 15.5156H80.1223V99.3383H89.3386V15.5156Z"
          fill="#E6E6E6"
        />
        <path
          d="M96.554 15.5156H84.7266V99.3383H96.554V15.5156Z"
          fill="#F0F0F0"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M172.999 15.5156H166.179V99.3383H172.999V15.5156Z"
          fill="#F0F0F0"
        />
        <path
          d="M175.644 15.5156H170.336V99.3383H175.644V15.5156Z"
          fill="#E6E6E6"
        />
        <path
          d="M179.81 15.5156H172.99V99.3383H179.81V15.5156Z"
          fill="#F0F0F0"
        />
        <path
          d="M182.464 15.5156H177.156V99.3383H182.464V15.5156Z"
          fill="#E6E6E6"
        />
        <path
          d="M186.63 15.5156H179.81V99.3383H186.63V15.5156Z"
          fill="#F0F0F0"
        />
      </g>
      <path
        d="M306.776 183.988C306.675 185.231 306.945 186.475 307.551 187.564C308.157 188.654 309.072 189.539 310.181 190.108C311.291 190.677 312.543 190.905 313.782 190.762C315.02 190.62 316.188 190.113 317.139 189.306C318.09 188.5 318.78 187.43 319.122 186.231C319.465 185.032 319.444 183.759 319.063 182.572C318.682 181.385 317.958 180.338 316.982 179.563C316.005 178.787 314.821 178.319 313.579 178.216C311.912 178.081 310.259 178.612 308.984 179.695C307.708 180.777 306.914 182.321 306.776 183.988Z"
        fill="#E0E0E0"
      />
      <path
        d="M324.306 194.026C327.755 193.518 330.14 190.311 329.633 186.861C329.125 183.412 325.917 181.027 322.468 181.534C319.018 182.042 316.633 185.25 317.141 188.699C317.648 192.148 320.856 194.533 324.306 194.026Z"
        fill="#E0E0E0"
      />
      <path
        d="M328.138 188.575C328.037 189.818 328.306 191.062 328.912 192.152C329.518 193.241 330.434 194.126 331.543 194.695C332.652 195.265 333.905 195.492 335.143 195.35C336.382 195.207 337.55 194.7 338.5 193.894C339.451 193.087 340.141 192.017 340.483 190.818C340.826 189.62 340.805 188.347 340.424 187.16C340.043 185.973 339.319 184.925 338.343 184.15C337.367 183.375 336.183 182.906 334.94 182.803C334.114 182.734 333.283 182.828 332.493 183.081C331.704 183.334 330.972 183.74 330.34 184.276C329.708 184.813 329.188 185.469 328.81 186.206C328.432 186.944 328.204 187.749 328.138 188.575Z"
        fill="#E0E0E0"
      />
      <path
        d="M295.052 187.301C294.951 188.543 295.22 189.788 295.827 190.877C296.433 191.966 297.348 192.851 298.457 193.421C299.566 193.99 300.819 194.217 302.057 194.075C303.296 193.932 304.464 193.425 305.415 192.619C306.365 191.812 307.055 190.742 307.398 189.544C307.74 188.345 307.72 187.072 307.339 185.885C306.958 184.698 306.233 183.651 305.257 182.875C304.281 182.1 303.097 181.631 301.855 181.529C300.187 181.393 298.535 181.925 297.259 183.007C295.984 184.089 295.19 185.634 295.052 187.301Z"
        fill="#E0E0E0"
      />
      <path
        d="M344.456 194.026C347.906 193.518 350.291 190.311 349.783 186.861C349.276 183.412 346.068 181.027 342.618 181.534C339.169 182.042 336.784 185.25 337.292 188.699C337.799 192.148 341.007 194.533 344.456 194.026Z"
        fill="#E0E0E0"
      />
      <path
        d="M296.937 187.274C299.587 185.007 299.898 181.022 297.631 178.372C295.365 175.722 291.38 175.411 288.73 177.678C286.08 179.944 285.769 183.929 288.035 186.579C290.302 189.229 294.287 189.54 296.937 187.274Z"
        fill="#E0E0E0"
      />
      <path
        d="M277.633 185.863C277.532 187.106 277.802 188.35 278.408 189.439C279.014 190.529 279.929 191.414 281.038 191.983C282.147 192.552 283.4 192.78 284.639 192.637C285.877 192.495 287.045 191.988 287.996 191.181C288.946 190.375 289.636 189.305 289.979 188.106C290.321 186.907 290.301 185.634 289.92 184.447C289.539 183.26 288.814 182.213 287.838 181.438C286.862 180.662 285.678 180.194 284.436 180.091C282.769 179.956 281.116 180.487 279.84 181.57C278.565 182.652 277.771 184.196 277.633 185.863Z"
        fill="#E0E0E0"
      />
      <path
        d="M270.427 187.562C270.427 187.562 271.509 213.39 313.012 213.39C354.515 213.39 355.598 187.562 355.598 187.562H270.427Z"
        fill="#F0F0F0"
      />
      <path
        d="M379.278 213.391H371.385V281.203H379.278V213.391Z"
        fill="#E6E6E6"
      />
      <path
        d="M381.383 213.391H376.736V281.203H381.383V213.391Z"
        fill="#F0F0F0"
      />
      <path
        d="M345.694 213.391H337.801V281.203H345.694V213.391Z"
        fill="#E6E6E6"
      />
      <path
        d="M347.172 213.391H342.525V281.203H347.172V213.391Z"
        fill="#F0F0F0"
      />
      <path
        d="M270.77 221.625H382.826V213.405H270.77V221.625Z"
        fill="#F0F0F0"
      />
      <path
        d="M236.525 213.391H228.631V281.203H236.525V213.391Z"
        fill="#E6E6E6"
      />
      <path
        d="M238.002 213.391H233.355V281.203H238.002V213.391Z"
        fill="#F0F0F0"
      />
      <path
        d="M227.223 221.625H267.395V213.405H227.223V221.625Z"
        fill="#E6E6E6"
      />
      <path d="M270.1 213.391H262.207V281.203H270.1V213.391Z" fill="#E6E6E6" />
      <path
        d="M272.213 213.391H267.566V281.203H272.213V213.391Z"
        fill="#F0F0F0"
      />
      <path
        d="M215 320.009C306.976 320.009 381.537 315.655 381.537 310.286C381.537 304.916 306.976 300.562 215 300.562C123.024 300.562 48.4626 304.916 48.4626 310.286C48.4626 315.655 123.024 320.009 215 320.009Z"
        fill="#F5F5F5"
      />
      <path
        d="M305.599 301.745C305.6 303.461 304.992 305.123 303.883 306.433C302.775 307.743 301.237 308.617 299.544 308.9C278.705 312.416 257.424 312.416 236.585 308.9C234.899 308.61 233.37 307.732 232.269 306.422C231.169 305.113 230.568 303.456 230.572 301.745V232.172H305.634L305.599 301.745Z"
        className="fill-caramel"
      />
      <path
        opacity="0.3"
        d="M230.529 233.43V243.196C235.047 244.871 249.881 246.099 268.064 246.099C286.248 246.099 301.107 244.871 305.6 243.196V233.43H230.529Z"
        fill="black"
      />
      <path
        d="M307.008 240.87C307.008 243.078 289.967 244.864 268.064 244.864C246.162 244.864 229.121 243.078 229.121 240.87L228.408 232.195H307.764L307.008 240.87Z"
        className="fill-caramel"
      />
      <path
        d="M268.064 236.182C289.966 236.182 307.721 234.397 307.721 232.196C307.721 229.995 289.966 228.211 268.064 228.211C246.163 228.211 228.408 229.995 228.408 232.196C228.408 234.397 246.163 236.182 268.064 236.182Z"
        className="fill-caramel"
      />
      <path
        opacity="0.3"
        d="M268.064 236.182C289.966 236.182 307.721 234.397 307.721 232.196C307.721 229.995 289.966 228.211 268.064 228.211C246.163 228.211 228.408 229.995 228.408 232.196C228.408 234.397 246.163 236.182 268.064 236.182Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M240.596 296.845C239.824 296.843 239.085 296.535 238.539 295.99C237.994 295.444 237.686 294.705 237.684 293.933V254.045C237.686 253.273 237.994 252.534 238.539 251.988C239.085 251.443 239.824 251.135 240.596 251.133C241.367 251.137 242.105 251.446 242.65 251.991C243.195 252.536 243.503 253.274 243.508 254.045V293.933C243.503 294.704 243.195 295.442 242.65 295.987C242.105 296.532 241.367 296.84 240.596 296.845Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M295.542 296.845C294.77 296.843 294.031 296.535 293.485 295.99C292.94 295.444 292.632 294.705 292.63 293.933V254.045C292.632 253.273 292.94 252.534 293.485 251.988C294.031 251.443 294.77 251.135 295.542 251.133C296.312 251.137 297.05 251.446 297.596 251.991C298.141 252.536 298.449 253.274 298.453 254.045V293.933C298.449 294.704 298.141 295.442 297.596 295.987C297.05 296.532 296.312 296.84 295.542 296.845Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M277.22 296.845C276.45 296.84 275.712 296.532 275.166 295.987C274.621 295.442 274.313 294.704 274.309 293.933V254.045C274.313 253.274 274.621 252.536 275.166 251.991C275.712 251.446 276.45 251.137 277.22 251.133C277.992 251.135 278.731 251.443 279.277 251.988C279.822 252.534 280.13 253.273 280.132 254.045V293.933C280.13 294.705 279.822 295.444 279.277 295.99C278.731 296.535 277.992 296.843 277.22 296.845Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M258.908 296.845C258.137 296.84 257.399 296.532 256.854 295.987C256.309 295.442 256.001 294.704 255.997 293.933V254.045C256.001 253.274 256.309 252.536 256.854 251.991C257.399 251.446 258.137 251.137 258.908 251.133C259.68 251.135 260.419 251.443 260.965 251.988C261.51 252.534 261.818 253.273 261.82 254.045V293.933C261.818 294.705 261.51 295.444 260.965 295.99C260.419 296.535 259.68 296.843 258.908 296.845Z"
        fill="black"
      />
      <path
        d="M165.938 72.2823C165.938 73.0705 165.704 73.8411 165.266 74.4965C164.828 75.1519 164.206 75.6627 163.478 75.9643C162.749 76.266 161.948 76.3449 161.175 76.1911C160.402 76.0374 159.692 75.6578 159.134 75.1004C158.577 74.543 158.197 73.8329 158.044 73.0598C157.89 72.2867 157.969 71.4854 158.27 70.7571C158.572 70.0289 159.083 69.4065 159.738 68.9685C160.394 68.5306 161.164 68.2969 161.952 68.2969C163.009 68.2969 164.023 68.7168 164.771 69.4642C165.518 70.2116 165.938 71.2253 165.938 72.2823Z"
        fill="#263238"
      />
      <path
        d="M158.749 72.8594C158.71 72.8605 158.672 72.8505 158.638 72.8308C158.605 72.811 158.578 72.7822 158.56 72.7477C158.297 72.2638 158.131 71.7328 158.073 71.1849C158.015 70.6371 158.065 70.0831 158.221 69.5547C158.377 69.0263 158.636 68.5338 158.982 68.1053C159.328 67.6768 159.755 67.3207 160.239 67.0573C160.723 66.7939 161.254 66.6285 161.802 66.5703C162.35 66.5122 162.904 66.5625 163.432 66.7184C163.961 66.8743 164.453 67.1328 164.882 67.4791C165.31 67.8254 165.666 68.2526 165.93 68.7365C165.955 68.787 165.96 68.8453 165.944 68.8995C165.928 68.9536 165.892 68.9998 165.844 69.0286C165.819 69.0422 165.792 69.0507 165.764 69.0538C165.735 69.0568 165.707 69.0543 165.68 69.0463C165.653 69.0383 165.627 69.0251 165.605 69.0073C165.583 68.9895 165.565 68.9675 165.552 68.9427C165.074 68.0656 164.268 67.4141 163.311 67.1314C162.353 66.8487 161.322 66.958 160.445 67.4353C159.568 67.9125 158.917 68.7186 158.634 69.6762C158.351 70.6338 158.461 71.6645 158.938 72.5416C158.952 72.5664 158.96 72.5936 158.963 72.6217C158.966 72.6499 158.964 72.6783 158.956 72.7054C158.948 72.7326 158.934 72.7579 158.917 72.7799C158.899 72.8019 158.877 72.8201 158.852 72.8336C158.82 72.8501 158.785 72.8589 158.749 72.8594Z"
        fill="#263238"
      />
      <path
        d="M154.875 90.4995C154.099 90.4964 153.339 90.2781 152.679 89.8689C152.019 89.4597 151.485 88.8757 151.137 88.1816C150.79 87.4876 150.641 86.7106 150.708 85.9371C150.775 85.1636 151.055 84.4238 151.517 83.7999C151.846 83.3588 152.259 82.9869 152.732 82.7054C153.205 82.4239 153.729 82.2384 154.274 82.1593C154.302 82.1545 154.33 82.1554 154.358 82.1619C154.385 82.1685 154.411 82.1806 154.433 82.1975C154.456 82.2144 154.475 82.2357 154.489 82.2602C154.503 82.2847 154.511 82.3117 154.514 82.3397C154.522 82.3954 154.507 82.4518 154.473 82.4968C154.44 82.5418 154.39 82.5717 154.334 82.5802C153.347 82.7249 152.457 83.2559 151.861 84.0565C151.265 84.857 151.011 85.8616 151.156 86.8491C151.301 87.8366 151.832 88.7262 152.632 89.3222C153.433 89.9182 154.437 90.1718 155.425 90.0271C155.482 90.018 155.54 90.0319 155.587 90.0657C155.634 90.0995 155.665 90.1505 155.674 90.2075C155.683 90.2645 155.669 90.3227 155.635 90.3694C155.602 90.4161 155.551 90.4475 155.494 90.4566C155.289 90.4837 155.082 90.498 154.875 90.4995Z"
        fill="#263238"
      />
      <path
        d="M176.529 96.9972C175.723 96.996 174.935 96.7602 174.261 96.3186C174.213 96.287 174.179 96.2381 174.167 96.1821C174.154 96.1261 174.163 96.0673 174.192 96.018C174.225 95.9715 174.275 95.9394 174.331 95.9282C174.387 95.917 174.445 95.9276 174.493 95.9579C175.33 96.5012 176.349 96.6897 177.325 96.4819C178.301 96.2741 179.155 95.687 179.698 94.8499C180.241 94.0127 180.43 92.994 180.222 92.0178C180.014 91.0417 179.427 90.1881 178.59 89.6448C178.542 89.6131 178.508 89.5642 178.496 89.5082C178.483 89.4522 178.492 89.3935 178.521 89.3441C178.554 89.2976 178.604 89.2655 178.66 89.2543C178.716 89.2432 178.774 89.2538 178.822 89.284C179.753 89.8903 180.406 90.8408 180.638 91.9274C180.87 93.0141 180.661 94.1483 180.059 95.0818C179.761 95.5462 179.374 95.9465 178.92 96.2592C178.466 96.5719 177.953 96.7907 177.413 96.9027C177.122 96.9638 176.826 96.9955 176.529 96.9972Z"
        fill="#263238"
      />
      <path
        d="M159.72 84.7144C162.831 84.7144 165.354 82.1918 165.354 79.0799C165.354 75.968 162.831 73.4453 159.72 73.4453C156.608 73.4453 154.085 75.968 154.085 79.0799C154.085 82.1918 156.608 84.7144 159.72 84.7144Z"
        fill="#263238"
      />
      <path
        d="M165.008 91.948C167.926 89.0298 167.926 84.2983 165.008 81.3801C162.09 78.4618 157.358 78.4618 154.44 81.3801C151.522 84.2984 151.522 89.0298 154.44 91.948C157.358 94.8663 162.09 94.8663 165.008 91.948Z"
        fill="#263238"
      />
      <path
        d="M160.51 104.432C165.073 104.432 168.773 100.733 168.773 96.1691C168.773 91.6057 165.073 87.9062 160.51 87.9062C155.946 87.9062 152.247 91.6057 152.247 96.1691C152.247 100.733 155.946 104.432 160.51 104.432Z"
        fill="#263238"
      />
      <path
        d="M176.818 98.6214C177.866 94.18 175.116 89.7297 170.674 88.6814C166.233 87.6332 161.783 90.3838 160.734 94.8253C159.686 99.2667 162.437 103.717 166.878 104.765C171.32 105.814 175.77 103.063 176.818 98.6214Z"
        fill="#263238"
      />
      <path
        d="M196.181 113.776C196.181 114.446 196.181 115.297 196.241 116.07C196.301 116.843 196.301 117.667 196.353 118.466C196.464 120.064 196.576 121.679 196.748 123.285C197.057 126.509 197.489 129.718 198.045 132.913C198.191 133.704 198.32 134.511 198.483 135.301L198.861 137.251C198.955 137.56 198.861 137.5 198.741 137.491C198.62 137.483 198.74 137.646 199.247 137.646C200.544 137.516 201.807 137.152 202.975 136.572C203.662 136.263 204.366 135.928 205.071 135.567C205.775 135.207 206.488 134.794 207.132 134.434L209.64 137.328C209.042 138.014 208.405 138.665 207.733 139.278C207.072 139.908 206.372 140.496 205.638 141.039C204.868 141.618 204.055 142.138 203.207 142.593C202.241 143.12 201.206 143.508 200.132 143.744C199.469 143.889 198.791 143.947 198.113 143.916C197.287 143.868 196.477 143.664 195.726 143.315C194.927 142.94 194.216 142.402 193.638 141.735C193.142 141.155 192.737 140.503 192.436 139.802C192.203 139.266 192.013 138.711 191.869 138.144L191.534 136.89C191.294 136.031 191.096 135.215 190.89 134.374C190.086 130.997 189.467 127.579 189.035 124.135C188.82 122.417 188.648 120.699 188.545 118.939C188.493 118.08 188.451 117.221 188.425 116.31C188.399 115.4 188.425 114.592 188.425 113.57L196.181 113.776Z"
        fill="#FFB573"
      />
      <path
        d="M192.015 104.515C199.342 110.123 199.05 120.697 199.05 120.697L185.942 123.832C185.942 123.832 181.923 113.198 183.555 109.307C185.255 105.253 187.892 101.354 192.015 104.515Z"
        className="fill-caramel"
      />
      <path
        opacity="0.7"
        d="M192.015 104.515C199.342 110.123 199.05 120.697 199.05 120.697L185.942 123.832C185.942 123.832 181.923 113.198 183.555 109.307C185.255 105.253 187.892 101.354 192.015 104.515Z"
        fill="white"
      />
      <path
        d="M206.643 135.415L208.807 131.773L214.236 137.58C214.236 137.58 210.345 140.071 206.308 138.293L206.643 135.415Z"
        fill="#FFB573"
      />
      <path
        d="M211.478 130.023L218.444 134.129L214.236 137.573L208.807 131.767L211.478 130.023Z"
        fill="#FFB573"
      />
      <path
        d="M151.001 303.938L158.491 304.66L163.146 287.112L155.648 286.391L151.001 303.938Z"
        fill="#FFB573"
      />
      <path
        d="M112.607 289.184L119.333 292.706L127.329 276.85L120.604 273.328L112.607 289.184Z"
        fill="#FFB573"
      />
      <path
        d="M121.128 289.631L115.115 283.524C115.015 283.417 114.879 283.351 114.733 283.338C114.588 283.325 114.442 283.367 114.325 283.455L108.802 287.518C108.668 287.626 108.559 287.761 108.48 287.914C108.401 288.066 108.356 288.234 108.346 288.406C108.336 288.577 108.363 288.749 108.424 288.91C108.484 289.071 108.578 289.217 108.699 289.339C110.829 291.435 111.963 292.337 114.609 295.025C116.241 296.674 119.59 300.565 121.841 302.85C124.091 305.135 126.462 303.03 125.732 301.879C122.451 296.726 121.901 293.488 121.695 290.868C121.657 290.402 121.457 289.964 121.128 289.631Z"
        fill="#263238"
      />
      <path
        d="M159.659 302.391H150.581C150.418 302.393 150.261 302.448 150.132 302.547C150.003 302.646 149.911 302.784 149.868 302.941L147.926 310.079C147.878 310.248 147.87 310.427 147.903 310.6C147.936 310.774 148.01 310.937 148.118 311.076C148.226 311.216 148.365 311.328 148.524 311.404C148.683 311.48 148.858 311.517 149.034 311.513C152.221 311.513 153.733 311.513 157.71 311.513H169.881C173.188 311.513 173.883 308.172 172.509 307.863C166.376 306.506 162.837 304.633 160.948 302.847C160.59 302.54 160.13 302.378 159.659 302.391Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M163.138 287.12L160.742 296.164H153.054L155.64 286.398L163.138 287.12Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M120.595 273.328L127.329 276.858L123.206 285.027L116.472 281.497L120.595 273.328Z"
        fill="black"
      />
      <path
        d="M189.799 103.425C189.799 103.425 193.115 104.877 182 148.854L151.13 146.182C152.736 133.668 153.415 125.92 149.644 99.6113C154.045 99.096 158.477 98.8979 162.906 99.0187C167.601 99.0897 172.286 99.4945 176.924 100.23C181.267 101.077 185.564 102.143 189.799 103.425Z"
        className="fill-caramel"
      />
      <path
        opacity="0.7"
        d="M189.799 103.425C189.799 103.425 193.115 104.877 182 148.854L151.13 146.182C152.736 133.668 153.415 125.92 149.644 99.6113C154.045 99.096 158.477 98.8979 162.906 99.0187C167.601 99.0897 172.286 99.4945 176.924 100.23C181.267 101.077 185.564 102.143 189.799 103.425Z"
        fill="white"
      />
      <path
        d="M162.906 98.7734C162.906 98.7734 161.188 98.7734 159.084 98.7734C157.839 100.689 163.095 106.28 169.778 111.778C171.392 106.77 173.935 106.787 173.935 106.787L162.906 98.7734Z"
        fill="white"
      />
      <path
        d="M176.915 100.125C176.915 100.125 177.697 100.237 179.569 100.666C180.806 102.582 181.502 107.374 177.851 111.549C176.417 106.395 173.943 106.859 173.943 106.859L176.915 100.125Z"
        fill="white"
      />
      <path
        d="M165.655 83.3594C166.153 87.946 166.316 96.2433 162.906 99.0262C162.906 99.0262 166.548 102.754 173.935 106.86C179.295 103.149 176.924 100.237 176.924 100.237C171.994 98.6053 172.449 94.8862 173.626 91.4505L165.655 83.3594Z"
        fill="#FFB573"
      />
      <path
        opacity="0.2"
        d="M168.91 86.6641L173.626 91.4483C173.336 92.2434 173.118 93.0626 172.973 93.8962C171.083 93.4582 168.335 91.4826 168.3 89.4556C168.333 88.4959 168.54 87.5501 168.91 86.6641Z"
        fill="black"
      />
      <path
        d="M183.366 68.3984C186.526 71.1985 187.454 78.0184 184.448 82.648C183.545 77.9549 183.182 73.174 183.366 68.3984Z"
        fill="#263238"
      />
      <path
        d="M164.693 73.7278C164.143 81.2778 163.756 84.4644 167.02 88.8191C171.933 95.3728 181.33 94.385 184.122 87.1528C186.638 80.6336 187.111 69.3816 180.179 65.4134C178.656 64.5243 176.93 64.0409 175.167 64.0095C173.403 63.978 171.661 64.3996 170.108 65.2338C168.554 66.0681 167.24 67.2871 166.292 68.7743C165.344 70.2614 164.793 71.967 164.693 73.7278Z"
        fill="#FFB573"
      />
      <path
        d="M163.688 79.8102C169.271 79.5954 172.363 72.2946 170.963 69.211C177.293 72.5093 186.879 75.1033 185.814 70.8602C184.379 65.1311 177.448 60.931 168.919 63.9887C163.98 66.1361 159.754 70.8602 163.688 79.8102Z"
        fill="#263238"
      />
      <path
        d="M161.987 79.8023C162.226 81.2459 163.022 82.5383 164.203 83.4012C165.784 84.5264 167.227 83.2723 167.338 81.4428C167.441 79.7851 166.746 77.2083 164.89 76.8132C164.484 76.7325 164.064 76.7548 163.669 76.8779C163.273 77.0011 162.915 77.2213 162.626 77.5184C162.338 77.8156 162.128 78.1802 162.016 78.5791C161.905 78.978 161.895 79.3985 161.987 79.8023Z"
        fill="#FFB573"
      />
      <path
        d="M182 148.861C182 148.861 188.983 194.428 185.092 223.7C182.146 245.817 163.138 293.539 163.138 293.539L150.795 293.204C150.795 293.204 163.542 246.951 166.179 228.785C170.688 197.674 159.393 146.938 159.393 146.938L182 148.861Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M169.958 158.203L163.37 168.588C165.354 180.9 166.613 193.319 167.141 205.779C176.4 189.202 172.054 166.973 169.958 158.203Z"
        fill="black"
      />
      <path
        d="M165.062 294.224L149.55 293.803L150.495 288.812L166.522 288.907L165.062 294.224Z"
        className="fill-caramel"
      />
      <path
        d="M175.249 148.374C175.249 148.374 170.095 198.827 158.319 226.279C148.794 248.439 126.084 283.887 126.084 283.887L117.013 278.579C117.013 278.579 131.615 239.162 138.822 224.389C156.404 188.168 140.05 159.11 151.13 146.227L175.249 148.374Z"
        fill="#263238"
      />
      <path
        d="M127.072 284.654L115.502 278.307L117.383 273.359L129.709 280.317L127.072 284.654Z"
        className="fill-caramel"
      />
      <path
        d="M150.821 144.344L149.189 146.99C149.069 147.187 149.301 147.436 149.661 147.462L182.034 150.262C182.309 150.262 182.558 150.168 182.584 149.996L183.108 147.256C183.108 147.067 182.91 146.886 182.61 146.861L151.345 144.155C151.249 144.14 151.15 144.15 151.059 144.183C150.967 144.216 150.886 144.271 150.821 144.344Z"
        className="fill-caramel"
      />
      <path
        d="M154.721 148.201L153.862 148.132C153.698 148.132 153.578 148.02 153.595 147.917L154.3 144.361C154.3 144.249 154.48 144.181 154.643 144.198L155.502 144.267C155.674 144.267 155.794 144.378 155.768 144.481L155.073 148.037C155.038 148.14 154.875 148.218 154.721 148.201Z"
        fill="#263238"
      />
      <path
        d="M179.655 150.357L178.796 150.288C178.625 150.288 178.504 150.176 178.53 150.073L179.234 146.517C179.234 146.414 179.406 146.337 179.569 146.354L180.428 146.423C180.592 146.423 180.712 146.534 180.695 146.637L179.99 150.193C179.973 150.314 179.784 150.374 179.655 150.357Z"
        fill="#263238"
      />
      <path
        d="M167.183 149.279L166.325 149.202C166.161 149.202 166.041 149.09 166.058 148.987L166.763 145.431C166.763 145.328 166.934 145.251 167.106 145.268L167.965 145.345C168.128 145.345 168.249 145.457 168.231 145.56L167.527 149.116C167.51 149.219 167.355 149.288 167.183 149.279Z"
        fill="#263238"
      />
      <path
        d="M176.52 77.4856C176.434 78.0954 176.692 78.6365 177.087 78.6881C177.482 78.7396 177.877 78.293 177.946 77.6831C178.015 77.0733 177.766 76.5321 177.371 76.4806C176.975 76.4291 176.597 76.8757 176.52 77.4856Z"
        fill="#263238"
      />
      <path
        d="M183.409 78.4074C183.331 79.0173 183.589 79.5584 183.984 79.6099C184.379 79.6615 184.774 79.2148 184.843 78.605C184.912 77.9952 184.671 77.454 184.276 77.4025C183.881 77.3509 183.495 77.7976 183.409 78.4074Z"
        fill="#263238"
      />
      <path
        d="M184.079 77.4108L185.608 77.1875C185.608 77.1875 184.714 78.2268 184.079 77.4108Z"
        fill="#263238"
      />
      <path
        d="M180.703 79.0859C181.061 80.5484 181.616 81.9556 182.352 83.2689C182.012 83.4495 181.637 83.5539 181.253 83.5746C180.869 83.5954 180.485 83.5321 180.127 83.3892L180.703 79.0859Z"
        fill="#ED893E"
      />
      <path
        d="M176.554 85.1989C175.346 84.8052 174.333 83.967 173.72 82.8541C173.704 82.8185 173.703 82.7785 173.716 82.7419C173.728 82.7053 173.754 82.6749 173.788 82.6565C173.825 82.6386 173.867 82.6357 173.906 82.6486C173.944 82.6614 173.976 82.689 173.995 82.7252C174.376 83.3944 174.91 83.9641 175.554 84.388C176.197 84.8118 176.931 85.0779 177.697 85.1646C177.735 85.1646 177.772 85.18 177.8 85.2074C177.827 85.2347 177.843 85.2719 177.843 85.3106C177.843 85.3493 177.827 85.3865 177.8 85.4139C177.772 85.4412 177.735 85.4566 177.697 85.4566C177.307 85.4137 176.924 85.3273 176.554 85.1989Z"
        fill="#263238"
      />
      <path
        d="M173.385 73.95C173.335 73.9294 173.291 73.897 173.256 73.8555C173.228 73.8283 173.205 73.7956 173.19 73.7594C173.175 73.7233 173.167 73.6844 173.167 73.6451C173.167 73.6058 173.175 73.5669 173.19 73.5307C173.205 73.4946 173.228 73.4619 173.256 73.4347C173.677 73.0583 174.183 72.7897 174.731 72.6521C175.278 72.5144 175.851 72.5118 176.4 72.6444C176.437 72.6552 176.472 72.6734 176.503 72.698C176.533 72.7225 176.558 72.7529 176.577 72.7874C176.595 72.8218 176.607 72.8596 176.61 72.8985C176.614 72.9374 176.609 72.9766 176.598 73.0138C176.576 73.0899 176.524 73.1542 176.455 73.1928C176.386 73.2314 176.304 73.2411 176.228 73.2199C175.776 73.1194 175.307 73.1277 174.859 73.2442C174.411 73.3607 173.997 73.582 173.651 73.8899C173.614 73.9185 173.571 73.9387 173.525 73.9491C173.479 73.9595 173.431 73.9598 173.385 73.95Z"
        fill="#263238"
      />
      <path
        d="M186.303 75.7774C186.258 75.7599 186.217 75.7336 186.183 75.7001C185.882 75.3467 185.502 75.0692 185.074 74.8902C184.645 74.7111 184.181 74.6354 183.718 74.6694C183.679 74.6781 183.638 74.6784 183.599 74.6702C183.56 74.6621 183.522 74.6457 183.49 74.6221C183.458 74.5986 183.43 74.5684 183.41 74.5336C183.391 74.4988 183.378 74.4602 183.374 74.4203C183.362 74.3417 183.381 74.2614 183.428 74.197C183.475 74.1326 183.545 74.0894 183.623 74.0767C184.184 74.0263 184.749 74.1106 185.27 74.3228C185.792 74.535 186.255 74.8688 186.621 75.2964C186.663 75.346 186.687 75.4078 186.691 75.4725C186.695 75.5373 186.677 75.6015 186.642 75.6556C186.606 75.7097 186.554 75.7507 186.493 75.7727C186.432 75.7947 186.365 75.7963 186.303 75.7774Z"
        fill="#263238"
      />
      <path
        d="M193.097 140.59C193.653 138.858 194.545 137.252 195.722 135.864C196.899 134.476 198.338 133.333 199.956 132.502C201.574 131.67 203.34 131.165 205.154 131.015C206.967 130.866 208.792 131.076 210.525 131.632C212.258 132.188 213.864 133.08 215.251 134.256C216.639 135.433 217.782 136.872 218.614 138.49C219.446 140.108 219.951 141.875 220.1 143.688C220.249 145.502 220.04 147.327 219.484 149.059L214.141 165.774L187.755 157.296L193.097 140.59Z"
        className="fill-caramel"
      />
      <path
        opacity="0.3"
        d="M193.097 140.59C193.653 138.858 194.545 137.252 195.722 135.864C196.899 134.476 198.338 133.333 199.956 132.502C201.574 131.67 203.34 131.165 205.154 131.015C206.967 130.866 208.792 131.076 210.525 131.632C212.258 132.188 213.864 133.08 215.251 134.256C216.639 135.433 217.782 136.872 218.614 138.49C219.446 140.108 219.951 141.875 220.1 143.688C220.249 145.502 220.04 147.327 219.484 149.059L214.141 165.774L187.755 157.296L193.097 140.59Z"
        fill="black"
      />
      <path
        d="M167.063 132.227L162.236 147.258C162.165 147.474 162.138 147.702 162.156 147.928C162.174 148.155 162.237 148.376 162.341 148.578C162.445 148.78 162.588 148.959 162.761 149.106C162.935 149.252 163.136 149.363 163.353 149.431L187.746 157.265L193.106 140.584C194.231 137.089 196.697 134.182 199.963 132.504C203.229 130.826 207.028 130.513 210.525 131.634L184.491 123.268C180.992 122.15 177.192 122.464 173.925 124.144C170.658 125.823 168.19 128.73 167.063 132.227Z"
        className="fill-caramel"
      />
      <path
        d="M180.153 143.619L186.879 122.687C186.902 122.611 186.908 122.532 186.898 122.454C186.888 122.376 186.861 122.301 186.82 122.235C186.778 122.168 186.723 122.111 186.658 122.067C186.592 122.024 186.518 121.995 186.441 121.982L179.569 120.969L178.71 123.683L183.718 125.289L178.049 142.932L180.153 143.619Z"
        fill="#263238"
      />
      <path
        d="M176.58 142.471C176.425 142.956 176.417 143.476 176.557 143.966C176.697 144.456 176.979 144.893 177.368 145.222C177.756 145.551 178.234 145.758 178.74 145.816C179.246 145.875 179.758 145.782 180.211 145.549C180.664 145.316 181.038 144.955 181.286 144.51C181.533 144.065 181.644 143.556 181.603 143.049C181.562 142.541 181.371 142.057 181.055 141.657C180.739 141.258 180.312 140.961 179.827 140.805C179.504 140.7 179.164 140.661 178.826 140.688C178.489 140.715 178.159 140.809 177.858 140.964C177.556 141.119 177.288 141.332 177.069 141.59C176.85 141.849 176.684 142.148 176.58 142.471Z"
        fill="#263238"
      />
      <path
        d="M189.017 159.763L222.859 170.628C222.982 170.668 223.112 170.684 223.241 170.673C223.369 170.663 223.495 170.627 223.61 170.568C223.725 170.509 223.827 170.428 223.911 170.329C223.994 170.23 224.057 170.116 224.096 169.993C224.137 169.87 224.153 169.74 224.144 169.611C224.134 169.481 224.098 169.355 224.039 169.24C223.98 169.125 223.898 169.023 223.799 168.939C223.699 168.856 223.584 168.794 223.46 168.756L187.746 157.289C187.587 157.786 187.631 158.325 187.87 158.789C188.108 159.253 188.521 159.603 189.017 159.763Z"
        className="fill-caramel"
      />
      <path
        opacity="0.2"
        d="M189.017 159.763L202.846 164.203C202.97 164.244 203.1 164.259 203.229 164.249C203.359 164.239 203.485 164.203 203.601 164.144C203.716 164.085 203.819 164.004 203.903 163.905C203.988 163.806 204.052 163.691 204.092 163.568C204.17 163.319 204.147 163.05 204.028 162.819C203.909 162.587 203.704 162.412 203.456 162.331L187.746 157.289C187.587 157.786 187.631 158.325 187.87 158.789C188.108 159.253 188.521 159.603 189.017 159.763Z"
        fill="black"
      />
      <path
        d="M153.277 111.747L152.805 112.271L152.272 112.881C151.912 113.293 151.551 113.74 151.207 114.161C150.503 115.02 149.833 115.939 149.206 116.866C146.611 120.54 144.568 124.574 143.142 128.84C142.798 129.905 142.481 130.97 142.232 132.061C142.146 132.379 142.137 132.507 142.094 132.653C142.088 132.779 142.088 132.905 142.094 133.031C142.08 133.166 142.08 133.301 142.094 133.435C142.094 133.59 142.386 133.95 143.09 134.294C143.937 134.675 144.829 134.946 145.745 135.101C147.954 135.453 150.191 135.599 152.427 135.539C154.763 135.539 157.134 135.333 159.505 135.084C161.875 134.835 164.28 134.526 166.608 134.174L167.544 137.893C162.75 139.453 157.817 140.549 152.814 141.165C150.179 141.508 147.515 141.566 144.868 141.337C143.355 141.195 141.869 140.842 140.454 140.289C139.604 139.949 138.807 139.492 138.083 138.932C137.231 138.264 136.528 137.424 136.021 136.467C135.149 134.672 134.916 132.632 135.36 130.686C135.566 129.372 135.867 128.11 136.219 126.787C136.893 124.248 137.79 121.773 138.899 119.392C140.011 117.005 141.306 114.707 142.773 112.52C143.511 111.429 144.302 110.373 145.126 109.334C145.547 108.818 145.985 108.311 146.415 107.805L147.11 107.057C147.359 106.791 147.557 106.576 147.9 106.25L153.277 111.747Z"
        fill="#FFB573"
      />
      <path
        d="M164.736 134.96L170.748 131.773L171.014 138.233C171.014 138.233 166.72 140.011 164.263 138.052L164.736 134.96Z"
        fill="#FFB573"
      />
      <path
        d="M175.704 131.281L174.802 137.234L171.023 138.23L170.757 131.771L175.704 131.281Z"
        fill="#FFB573"
      />
      <path
        d="M149.644 99.6278C145.856 100.298 140.557 111.155 140.557 111.155L150.099 119.744C150.099 119.744 157.684 111.464 157.28 106.534C156.936 102.368 154.463 98.7689 149.644 99.6278Z"
        className="fill-caramel"
      />
      <path
        opacity="0.7"
        d="M149.644 99.6278C145.856 100.298 140.557 111.155 140.557 111.155L150.099 119.744C150.099 119.744 157.684 111.464 157.28 106.534C156.936 102.368 154.463 98.7689 149.644 99.6278Z"
        fill="white"
      />
      <path
        d="M124.083 292.943C123.983 292.956 123.882 292.956 123.782 292.943C122.829 292.891 121.815 291.431 121.291 290.572C121.275 290.548 121.266 290.52 121.266 290.491C121.266 290.462 121.275 290.433 121.291 290.409C121.307 290.384 121.331 290.364 121.358 290.353C121.386 290.342 121.417 290.341 121.446 290.349C121.755 290.409 124.426 291.01 124.761 291.912C124.796 292.01 124.805 292.115 124.787 292.218C124.769 292.32 124.724 292.416 124.658 292.496C124.521 292.704 124.319 292.861 124.083 292.943ZM121.738 290.761C122.502 291.938 123.258 292.642 123.799 292.677C123.923 292.679 124.044 292.649 124.153 292.591C124.261 292.532 124.352 292.446 124.418 292.342C124.455 292.304 124.48 292.256 124.491 292.205C124.502 292.153 124.497 292.099 124.478 292.05C124.272 291.5 122.666 290.985 121.738 290.761Z"
        className="fill-caramel"
      />
      <path
        d="M123.808 290.575C123.016 290.793 122.184 290.828 121.377 290.678C121.351 290.67 121.329 290.656 121.311 290.636C121.293 290.617 121.28 290.593 121.274 290.567C121.262 290.544 121.255 290.519 121.255 290.494C121.255 290.468 121.262 290.443 121.274 290.421C121.342 290.361 122.906 288.832 123.996 288.909C124.131 288.911 124.264 288.945 124.383 289.007C124.502 289.07 124.605 289.16 124.684 289.27C124.777 289.37 124.835 289.499 124.848 289.636C124.86 289.773 124.827 289.91 124.752 290.026C124.506 290.307 124.174 290.5 123.808 290.575ZM121.738 290.438C122.596 290.558 124.203 290.343 124.538 289.854C124.589 289.777 124.632 289.656 124.477 289.459C124.426 289.383 124.356 289.321 124.276 289.278C124.195 289.235 124.105 289.211 124.014 289.21C123.266 289.158 122.184 290.034 121.738 290.438Z"
        className="fill-caramel"
      />
      <path
        d="M162.863 303.538C162.033 303.518 161.207 303.411 160.398 303.22C160.37 303.21 160.345 303.191 160.328 303.167C160.311 303.142 160.302 303.113 160.302 303.083C160.302 303.052 160.311 303.023 160.328 302.999C160.345 302.974 160.37 302.955 160.398 302.945C160.768 302.765 164.057 301.227 164.882 301.811C164.956 301.862 165.015 301.932 165.053 302.014C165.091 302.095 165.106 302.186 165.097 302.275C165.093 302.443 165.05 302.608 164.973 302.756C164.895 302.905 164.785 303.035 164.65 303.134C164.111 303.452 163.486 303.593 162.863 303.538ZM160.914 303.022C161.884 303.203 163.688 303.443 164.435 302.885C164.531 302.81 164.61 302.715 164.666 302.607C164.722 302.499 164.755 302.38 164.762 302.258C164.769 302.22 164.765 302.181 164.75 302.146C164.734 302.11 164.708 302.081 164.676 302.06C164.212 301.743 162.271 302.421 160.888 303.022H160.914Z"
        className="fill-caramel"
      />
      <path
        d="M160.424 303.201C160.393 303.209 160.36 303.209 160.329 303.201C160.309 303.181 160.293 303.157 160.284 303.13C160.275 303.103 160.273 303.074 160.278 303.046C160.278 302.909 161.06 299.67 162.855 299.877C163.301 299.937 163.422 300.151 163.447 300.332C163.559 301.191 161.489 302.814 160.501 303.226L160.424 303.201ZM162.674 300.134C161.541 300.134 160.879 302.024 160.656 302.78C161.73 302.213 163.233 300.864 163.138 300.34C163.138 300.298 163.138 300.177 162.803 300.143L162.674 300.134Z"
        className="fill-caramel"
      />
      <path
        d="M177.207 76.489L178.728 76.2656C178.728 76.2656 177.826 77.3049 177.207 76.489Z"
        fill="#263238"
      />
      <path
        d="M230.057 148.345L196.799 155.217C196.231 155.324 195.643 155.204 195.161 154.883C194.68 154.562 194.343 154.065 194.223 153.499L192.668 146.284L192.11 143.621L193.089 140.581C193.225 140.144 193.389 139.717 193.578 139.301C193.6 139.235 193.629 139.171 193.664 139.112C194.213 137.861 194.952 136.702 195.855 135.676L197.572 135.315L226.089 129.397C226.658 129.291 227.246 129.411 227.727 129.732C228.209 130.053 228.545 130.549 228.666 131.115L231.698 145.717C231.822 146.283 231.72 146.874 231.413 147.366C231.106 147.857 230.62 148.208 230.057 148.345Z"
        className="fill-caramel"
      />
      <path
        opacity="0.3"
        d="M228.408 130.342L212.363 146.661L208.927 145.27L195.562 139.85L193.681 139.086C194.23 137.834 194.969 136.675 195.872 135.65L197.59 135.289L226.106 129.371C226.543 129.284 226.996 129.33 227.406 129.503C227.816 129.676 228.165 129.968 228.408 130.342Z"
        fill="black"
      />
      <path
        d="M228.408 130.336L208.927 145.29L207.656 146.269L195.416 140.197L193.595 139.338C193.617 139.272 193.646 139.208 193.681 139.149C194.23 137.898 194.969 136.739 195.872 135.713L197.589 135.352L226.106 129.434C226.536 129.34 226.984 129.376 227.394 129.536C227.803 129.697 228.156 129.975 228.408 130.336Z"
        className="fill-caramel"
      />
      <path
        opacity="0.2"
        d="M228.408 130.336L208.927 145.29L207.656 146.269L195.416 140.197L193.595 139.338C193.617 139.272 193.646 139.208 193.681 139.149C194.23 137.898 194.969 136.739 195.872 135.713L197.589 135.352L226.106 129.434C226.536 129.34 226.984 129.376 227.394 129.536C227.803 129.697 228.156 129.975 228.408 130.336Z"
        fill="white"
      />
      <path
        d="M270.658 178.474L260.523 189.4C260.123 189.825 259.571 190.076 258.987 190.097C258.403 190.118 257.835 189.907 257.405 189.511L232.548 166.363C232.12 165.964 231.868 165.412 231.847 164.827C231.826 164.243 232.038 163.674 232.436 163.245L242.606 152.32C242.803 152.106 243.04 151.933 243.305 151.811C243.569 151.689 243.855 151.621 244.146 151.611C244.437 151.6 244.727 151.648 244.999 151.75C245.271 151.853 245.521 152.008 245.732 152.208L270.581 175.356C270.903 175.656 271.129 176.045 271.228 176.473C271.327 176.902 271.297 177.35 271.139 177.761C271.032 178.03 270.868 178.274 270.658 178.474Z"
        className="fill-caramel"
      />
      <path
        opacity="0.3"
        d="M271.105 177.8L248.498 174.115L243.25 151.783C243.651 151.598 244.097 151.537 244.532 151.605C244.968 151.674 245.374 151.87 245.698 152.169L270.547 175.317C270.884 175.623 271.119 176.026 271.219 176.47C271.319 176.915 271.279 177.379 271.105 177.8Z"
        fill="black"
      />
      <path
        d="M271.105 177.801L249.091 171.238L243.25 151.861C243.651 151.677 244.097 151.615 244.532 151.684C244.968 151.752 245.374 151.949 245.698 152.248L270.547 175.396C270.869 175.695 271.094 176.084 271.194 176.513C271.293 176.941 271.262 177.39 271.105 177.801Z"
        className="fill-caramel"
      />
      <path
        opacity="0.2"
        d="M271.105 177.801L249.091 171.238L243.25 151.861C243.651 151.677 244.097 151.615 244.532 151.684C244.968 151.752 245.374 151.949 245.698 152.248L270.547 175.396C270.869 175.695 271.094 176.084 271.194 176.513C271.293 176.941 271.262 177.39 271.105 177.801Z"
        fill="white"
      />
      <path
        d="M279.497 196.462L290.663 209.501C290.876 209.75 291.036 210.041 291.134 210.354C291.231 210.668 291.264 210.998 291.229 211.324C291.194 211.65 291.094 211.966 290.933 212.252C290.772 212.539 290.554 212.789 290.294 212.988L266.037 231.884C265.63 232.13 265.145 232.215 264.679 232.123C264.212 232.031 263.796 231.768 263.512 231.386L254.777 221.423C254.613 221.231 254.477 221.017 254.373 220.787C254.211 220.471 254.143 220.115 254.178 219.762C254.213 219.408 254.35 219.072 254.571 218.794L276.044 196.368C276.379 196.005 276.822 195.759 277.308 195.666C277.793 195.573 278.295 195.639 278.741 195.852C279.029 196.006 279.286 196.213 279.497 196.462Z"
        className="fill-caramel"
      />
      <path
        opacity="0.3"
        d="M278.741 195.88L273.407 219.604L254.408 220.815C254.245 220.499 254.178 220.143 254.213 219.789C254.248 219.436 254.384 219.1 254.605 218.822L276.078 196.396C276.411 196.04 276.848 195.799 277.326 195.706C277.804 195.614 278.3 195.675 278.741 195.88Z"
        fill="black"
      />
      <path
        d="M278.741 195.878L269.799 221.053L254.39 220.813C254.228 220.497 254.16 220.141 254.196 219.787C254.231 219.434 254.367 219.098 254.588 218.82L276.061 196.393C276.395 196.034 276.835 195.79 277.317 195.698C277.799 195.605 278.297 195.668 278.741 195.878Z"
        className="fill-caramel"
      />
      <path
        opacity="0.2"
        d="M278.741 195.878L269.799 221.053L254.39 220.813C254.228 220.497 254.16 220.141 254.196 219.787C254.231 219.434 254.367 219.098 254.588 218.82L276.061 196.393C276.395 196.034 276.835 195.79 277.317 195.698C277.799 195.605 278.297 195.668 278.741 195.878Z"
        fill="white"
      />
    </svg>
  );
};
export default PersonTrashIcon;
