export const TIME_ZONES = [
  { label: "Pacific Time Zone", value: "America/Los_Angeles" },
  { label: "Mountain Time Zone", value: "America/Denver" },
  { label: "Central Time Zone", value: "America/Chicago" },
  { label: "Eastern Time Zone", value: "America/New_York" },
  { label: "Alaska Time Zone", value: "America/Anchorage" },
  { label: "Hawaii-Aleutian Time Zone", value: "Pacific/Honolulu" },
  { label: "Atlantic Time Zone", value: "America/Halifax" },
  { label: "Greenwich Mean Time", value: "Etc/GMT" },
  { label: "Coordinated Universal Time", value: "UTC" },
  { label: "British Summer Time", value: "Europe/London" },
  { label: "Central European Time", value: "Europe/Berlin" },
  { label: "Eastern European Time", value: "Europe/Bucharest" },
  { label: "Australian Eastern Standard Time", value: "Australia/Sydney" },
  { label: "Australian Central Standard Time", value: "Australia/Adelaide" },
  { label: "Australian Western Standard Time", value: "Australia/Perth" },
  { label: "West Africa Time (Nigeria)", value: "Africa/Lagos" },
  { label: "West Africa Time (Ghana)", value: "Africa/Accra" },
  { label: "West Africa Time (Ivory Coast)", value: "Africa/Abidjan" },
  { label: "West Africa Time (Senegal)", value: "Africa/Dakar" },
  { label: "West Africa Time (Gambia)", value: "Africa/Banjul" },
  { label: "West Africa Time (Liberia)", value: "Africa/Monrovia" },
  { label: "West Africa Time (Sierra Leone)", value: "Africa/Freetown" },
  { label: "West Africa Time (Mali)", value: "Africa/Bamako" },
  { label: "West Africa Time (Burkina Faso)", value: "Africa/Ouagadougou" },
  { label: "West Africa Time (Guinea)", value: "Africa/Conakry" },
];
