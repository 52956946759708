import { useState } from "react";
import { useFetchSingleEmployee } from "../../hooks/useFetchSingleEmployee";
import { EditMyProfile } from "./EditMyProfile";
import { Resignation } from "./Resignation";
import { EmployeeOverviewCard } from "./EmployeeOverviewCard";
import { getEmployeeFullName } from "../../utils/getEmployeeFullName";
import { EmployeeTabs } from "./EmployeeTabs";
import { ErrorWrapper } from "components/errorHandlers/ErrorWrapper";
import ErrorBoundary from "components/errorHandlers/ErrorBoundary";
import { errorFormatter } from "utils/errorHelpers";
import { DataContainerLoader } from "components/loaders/DataContainerLoader";

export const EmployeeProfileContainer = ({
  employeeId,
  isOwner = false,
}: {
  employeeId?: number;
  isOwner?: boolean;
}) => {
  const {
    data: employee,
    isLoading,
    isError,
    error,
  } = useFetchSingleEmployee({
    employeeId: employeeId ? +employeeId : undefined,
  });
  const [action, setAction] = useState<
    "edit-user" | "resign-user" | "suspend-user" | "activate-user"
  >();
  const clearAction = () => {
    setAction(undefined);
  };

  return (
    <>
      <ErrorBoundary>
        <DataContainerLoader
          isLoading={isLoading}
          type="Scaleloader"
          style={{ height: "400px" }}
        >
          <ErrorWrapper
            isError={isError}
            message={errorFormatter(error).message}
          >
            <div>
              <EditMyProfile
                open={action === "edit-user"}
                employee={employee}
                handleClose={clearAction}
              />
              <Resignation
                open={action === "resign-user"}
                handleClose={clearAction}
              />
              <div className="bg-card p-1 md:p-5 mt-5  flex flex-col gap-5">
                <EmployeeOverviewCard
                  {...{
                    showMoreActions: false,
                    data: {
                      fullName: getEmployeeFullName(employee),
                      designation: employee?.designation?.name,
                      department: employee?.designation?.department.name,
                      empuid: employee?.empUid,
                      email: employee?.email,
                      phone: employee?.personalInformation?.phoneNumber,
                      address:
                        employee?.personalInformation?.address?.streetAddress,
                      avatarUrl: employee?.avatarUrl,
                      role: employee?.role?.name,
                    },
                    isLoading,
                    handleEdit: () => {
                      setAction("edit-user");
                    },
                    handleResignation: () => {
                      setAction("resign-user");
                    },
                    handleSuspension: () => {
                      setAction("suspend-user");
                    },
                    handleActivate: () => {
                      setAction("activate-user");
                    },
                  }}
                />
                <EmployeeTabs
                  isLoading={isLoading}
                  employee={employee}
                  isOwner={isOwner}
                />
              </div>
            </div>
          </ErrorWrapper>
        </DataContainerLoader>
      </ErrorBoundary>
    </>
  );
};
