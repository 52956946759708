import React from "react";
import { IconProps } from "types/html";

const MoonIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9996 25.3338C11.9996 26.4538 12.1729 27.5471 12.4929 28.5605C7.37294 26.7871 3.50628 22.0805 3.10628 16.5738C2.70628 10.7205 6.07961 5.25381 11.5329 2.96048C12.9463 2.37381 13.6663 2.80048 13.9729 3.10715C14.2663 3.40048 14.6796 4.10715 14.0929 5.45381C13.4929 6.84048 13.1996 8.30715 13.1996 9.82715C13.2129 12.5471 14.2796 15.0671 16.0129 17.0005C13.5729 18.9471 11.9996 21.9605 11.9996 25.3338Z"
        className="fill-accent"
      />
      <path
        d="M28.28 23.6267C25.64 27.2133 21.4533 29.32 16.9867 29.32C16.7733 29.32 16.56 29.3067 16.3467 29.2933C15.0133 29.24 13.72 28.9867 12.4933 28.56C12.1733 27.5467 12 26.4533 12 25.3333C12 21.96 13.5733 18.9467 16.0133 17C17.9733 19.2 20.7867 20.6267 23.8933 20.76C24.7333 20.8 25.5733 20.7333 26.4 20.5867C27.8933 20.32 28.4933 20.88 28.7067 21.24C28.9333 21.6 29.1733 22.3867 28.28 23.6267Z"
        className="fill-accent"
      />
    </svg>
  );
};

export default MoonIcon;
