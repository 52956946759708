import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { ApiResponse, ICurrentCompany } from "types";
import { TBulkImportEmployeeProp } from "../types/bulk-import";
import { useApiAuth } from "hooks/useApiAuth";
import { errorFormatter } from "utils/errorHelpers";
import { openNotification, TNotificationState } from "utils/notifications";
import { QUERY_KEY_FOR_LIST_OF_EMPLOYEES } from "./useFetchEmployees";
import { MICROSERVICE_ENDPOINTS } from "config/enviroment";

type TData = { data: TBulkImportEmployeeProp[] };
type TEmployeeBulkUploadResponseData = {
  issues: Issues;
};

interface Issues {
  bank: string[];
}
export const employeeBulkUpload = async (vals: {
  auth: ICurrentCompany;
  props: TData;
}): Promise<ApiResponse<TEmployeeBulkUploadResponseData>> => {
  const { auth, props } = vals;
  const url = `${MICROSERVICE_ENDPOINTS.UTILITY}/employee/bulk`;
  const config = {
    headers: {
      // Accept: "application/json",
      Authorization: `Bearer ${auth.token}`,
      "x-company-id": auth.companyId,
    },
  };

  try {
    const data: (Omit<TBulkImportEmployeeProp, "bankDetails"> & {
      bank: TBulkImportEmployeeProp["bankDetails"];
    })[] = props.data.map((b) => {
      const ans = { ...b, bank: b.bankDetails };
      delete ans.bankDetails;
      return ans;
    });

    const response = await axios.post(url, data, config);
    return response.data as ApiResponse<TEmployeeBulkUploadResponseData>;
  } catch (error) {
    throw error;
  }
};

export const useEmployeeBulkUpload = (handleClose: () => void) => {
  const { token, companyId } = useApiAuth();
  const queryClient = useQueryClient();

  return useMutation(
    (props: TData) => employeeBulkUpload({ props, auth: { token, companyId } }),
    {
      onError: (_err) => {
        const formattedErr = errorFormatter(_err);
        openNotification({
          state: "error",
          title: formattedErr.message,
          description: formattedErr.errors
            ?.map((err) => `${err.path}: ${err.message}`)
            .join(","),
        });
      },
      onSuccess: (res) => {
        let message = res.message;
        let state: TNotificationState = "success";
        let duration = 1;
        if (res.data?.issues.bank) {
          duration = 10;
          state = "info";
          message = `The following employees have issues with their bank details ${res.data.issues.bank}`;
        }
        openNotification({
          state,

          title: "Success",
          description: message,
          duration,
        });

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_FOR_LIST_OF_EMPLOYEES],
          // exact: true,
        });
        handleClose();
      },
    }
  );
};
