import { Collapse, Form, InputNumber, Select, Skeleton, Switch } from "antd";
import { useGetReusableStyles } from "hooks/styles";
import { FormWorkflowInput } from "features/core/workflows/components/FormWorkflowInput";

import { VEHICLE_BOOKING_POLICY_FREQUENCY_TIME_PERIODS } from "../constants";
import { ACTIVE_EMPLOYEE_STATUSES } from "features/core/employees/constants";
import { FormDesignationInput } from "features/core/designations/components/FormDesignationInput";
import { FormRoleInput } from "features/core/roles-and-permissions/components/FormRoleInput";
import { useEffect, useState } from "react";
import { ActiveAccordianPanelIndicator } from "components/accordian/ActiveAccordianPanelIndicator";
import { isAccordianPanelActive } from "utils/accordianHelpers";
import { AppButton } from "components/button/AppButton";
import {
  generalValidationRulesOp,
  numberHasToBeGreaterThanZeroRule,
  numberInputValidationRulesOp,
  validateFormFieldsBeforeProceeding,
} from "utils/formHelpers/validation";

import { useQueryClient } from "react-query";
import { openNotification } from "utils/notifications";
import { errNotificationHelper } from "utils/errorHelpers/error-notification";
import {
  TSaveVehicleBookingPolicyInput,
  useSaveVehicleBookingPolicy,
} from "../hooks/policy/useSaveVehicleBookingPolicy";
import {
  QUERY_KEY_FOR_VEHICLE_BOOKING_POLICY,
  useGetVehicleBookingPolicy,
} from "../hooks/policy/useGetVehicleBookingPolicy";

const VBPolicyWorkflow: React.FC<{ Form: typeof Form }> = ({ Form }) => {
  return (
    <div className="grid grid-cols-2 gap-6">
      <FormWorkflowInput
        Form={Form}
        control={{ name: "workflowId", label: "Select Workflow Approval" }}
      />
    </div>
  );
};
const VBPolicyFrequency: React.FC<{ Form: typeof Form }> = ({ Form }) => {
  return (
    <div className="grid grid-cols-2 gap-6">
      <Form.Item<TSaveVehicleBookingPolicyInput>
        name={["frequency", "numOfTimes"]}
        label={`Number of Times`}
        rules={numberInputValidationRulesOp}
      >
        <InputNumber className="w-full" placeholder={`Number of Times`} />
      </Form.Item>
      <Form.Item<TSaveVehicleBookingPolicyInput>
        name={["frequency", "timePeriod"]}
        label={`Time Period`}
        rules={generalValidationRulesOp}
      >
        <Select
          placeholder={`Time Period`}
          className="capitalize"
          options={VEHICLE_BOOKING_POLICY_FREQUENCY_TIME_PERIODS.map((a) => ({
            label: <span className="capitalize">{a}</span>,
            value: a,
          }))}
        />
      </Form.Item>
    </div>
  );
};
const VBPolicyTimeRestriction: React.FC<{ Form: typeof Form }> = ({ Form }) => {
  return (
    <div className="grid grid-cols-2 gap-6">
      <Form.Item<TSaveVehicleBookingPolicyInput>
        name={["timeRestriction", "duration"]}
        label={`Max Duration(hrs)`}
        rules={[numberHasToBeGreaterThanZeroRule]}
      >
        <InputNumber className="w-full" placeholder={`Max Duration(hrs)`} />
      </Form.Item>
    </div>
  );
};
const VBPolicyEligibilityCriteria: React.FC<{ Form: typeof Form }> = ({
  Form,
}) => {
  return (
    <div className="grid grid-cols-2 gap-6">
      <Form.Item<TSaveVehicleBookingPolicyInput>
        name={["eligibilityCriteria", "employeeStatus"]}
        label={`Employee Status`}
        rules={generalValidationRulesOp}
      >
        <Select
          placeholder={`Employee Status`}
          className="capitalize"
          options={ACTIVE_EMPLOYEE_STATUSES.map((a) => ({
            label: <span className="capitalize">{a}</span>,

            value: a,
          }))}
          mode="multiple"
        />
      </Form.Item>
      <FormDesignationInput
        Form={Form}
        mode={`multiple`}
        control={{
          name: ["eligibilityCriteria", "designation"],
          label: "Designation(s)",
        }}
        optional
      />
      <FormRoleInput
        Form={Form}
        mode={`multiple`}
        control={{
          name: ["eligibilityCriteria", "role"],
          label: "Role(s)",
        }}
        optional
      />
    </div>
  );
};

const VehiclePolicyAccordian = () => {
  const { panelStyle } = useGetReusableStyles();
  const [activeKey, setActiveKey] = useState<string | string[]>("1");
  const [form] = Form.useForm<TSaveVehicleBookingPolicyInput>();
  const queryClient = useQueryClient();

  const { data, isLoading } = useGetVehicleBookingPolicy();
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        workflowId: data?.workflowId,
        eligibilityCriteria: {
          designation: data?.eligibilityCriteria?.designation,
          role: data?.eligibilityCriteria?.role,
          employeeStatus: data?.eligibilityCriteria?.employeeStatus,
        },
        frequency: {
          disabled: !!!data?.frequency?.disabled,
          numOfTimes: data?.frequency?.numOfTimes,
          timePeriod: data?.frequency?.timePeriod,
        },
        timeRestriction: {
          disabled: !!!data?.timeRestriction?.disabled,
          duration: data?.timeRestriction?.duration,
        },
      });
    }
  }, [data, form]);

  const { mutate, isLoading: isSaving } = useSaveVehicleBookingPolicy();

  const handleSubmit = async (data: TSaveVehicleBookingPolicyInput) => {
    const { errors, hasErrors } = await validateFormFieldsBeforeProceeding({
      onProceed: () =>
        mutate(
          {
            workflowId: data?.workflowId,
            eligibilityCriteria: {
              designation: data?.eligibilityCriteria?.designation,
              role: data?.eligibilityCriteria?.role,
              employeeStatus: data?.eligibilityCriteria?.employeeStatus,
            },
            frequency: {
              disabled: !!!data?.frequency?.disabled,
              numOfTimes: data?.frequency?.numOfTimes,
              timePeriod: data?.frequency?.timePeriod,
            },
            timeRestriction: {
              disabled: !!!data?.timeRestriction?.disabled,
              duration: data?.timeRestriction?.duration,
            },
          },
          {
            onError: (err: any) => {
              openNotification({
                state: "error",
                ...errNotificationHelper(err),
              });
            },
            onSuccess: (res: any) => {
              openNotification({
                state: "success",

                title: "Success",
                description: res.data.message,
                // duration: 0.4,
              });

              form.resetFields();

              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY_FOR_VEHICLE_BOOKING_POLICY],
                // exact: true,
              });
            },
          }
        ),
      form,
    });

    if (hasErrors) {
      openNotification({
        state: "error",
        title: "Validation Error",
        description: (
          <div className="space-y-4">
            {errors.map((e, i) => (
              <span key={i} className="capitalize">
                {e}
              </span>
            ))}
          </div>
        ),
      });
    }
  };
  return (
    <Skeleton active loading={isLoading} paragraph={{ rows: 18 }}>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        requiredMark={false}
        onFinish={handleSubmit}
      >
        <div className="flex justify-end mb-6 ">
          <AppButton type="submit" label="Save" isLoading={isSaving} />
        </div>
        <Collapse
          bordered={false}
          onChange={(val) => setActiveKey(val)}
          activeKey={activeKey}
          defaultActiveKey={["1", "2", "3", "4"]}
          expandIcon={() => undefined}
          // accordion
          className="bg-transparent"
          items={[
            {
              key: "1",
              label: (
                <div className="flex items-center justify-between gap-x-4">
                  <p className="text-lg">Approval Workflow</p>
                  <ActiveAccordianPanelIndicator
                    isActive={isAccordianPanelActive("1", activeKey)}
                  />
                </div>
              ),
              children: <VBPolicyWorkflow Form={Form} />,
              style: panelStyle,
            },
            {
              key: "2",
              label: (
                <div className="flex items-center justify-between gap-x-4">
                  <div>
                    <p className="text-lg">Booking Frequency</p>
                    <p className="text-sm text-black/70">
                      Set the limitations on how frequently an employee can book
                      a particular vehicle within a specified period to ensure
                      availability for others.
                    </p>
                  </div>
                  <Form.Item<TSaveVehicleBookingPolicyInput>
                    name={[`frequency`, "disabled"]}
                    valuePropName="checked"
                  >
                    <Switch size="small" />
                  </Form.Item>
                </div>
              ),
              children: <VBPolicyFrequency Form={Form} />,
              style: panelStyle,
            },
            {
              key: "3",
              label: (
                <div className="flex items-center justify-between gap-x-4">
                  <div>
                    <p className="text-lg">Time Restrictions</p>
                    <p className="text-sm text-black/70">
                      Set the maximum booking duration to prevent long-term
                      occupancy.
                    </p>
                  </div>
                  <Form.Item<TSaveVehicleBookingPolicyInput>
                    name={[`timeRestriction`, "disabled"]}
                    valuePropName="checked"
                  >
                    <Switch size="small" />
                  </Form.Item>
                </div>
              ),
              children: <VBPolicyTimeRestriction Form={Form} />,
              style: panelStyle,
            },
            {
              key: "4",
              label: (
                <div className="flex items-center justify-between gap-x-4">
                  <p className="text-lg">Eligibility Criteria</p>
                  <ActiveAccordianPanelIndicator
                    isActive={isAccordianPanelActive("4", activeKey)}
                  />
                </div>
              ),
              children: <VBPolicyEligibilityCriteria Form={Form} />,
              style: panelStyle,
            },
          ]}
        />
      </Form>
    </Skeleton>
  );
};

export default VehiclePolicyAccordian;
