import { z } from "zod";

export const employeeInformationValidationSchema = () => {
  return z.object({
    firstName: z.string().trim(),
    lastName: z.string().trim(),
    middleName: z.string().trim().optional(),
    email: z.string().email().trim(),

    empUid: z.string().min(4).trim(),
  });
};
