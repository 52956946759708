import { Form, Input, Modal, Skeleton } from "antd";
import { useEffect } from "react";
import { IModalProps } from "types";
import { useGetSingleLoan } from "../../hooks/requests/useGetSingleLoan";
import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT } from "constants/dateFormats";
import { TApprovalRequest } from "features/core/workflows/types/approval-requests";
import { getEmployeeFullName } from "features/core/employees/utils/getEmployeeFullName";
import { formatNumberWithCommas } from "utils/dataHelpers/formatNumberWithCommas";
import ApproveOrRejectButton from "features/core/workflows/components/approval-request/ApproveOrRejectButton";
import { useQueryClient } from "react-query";
import { QUERY_KEY_FOR_LOAN } from "../../hooks/useGetLoan";
import { QUERY_KEY_FOR_LOAN_REQUESTS } from "../../hooks/requests/useGetLoanRequests";
import { QUERY_KEY_FOR_ALL_LOAN_REQUESTS } from "../../hooks/requests/useGetAllLoans";
import { errorFormatter } from "utils/errorHelpers";
import { ErrorWrapper } from "components/errorHandlers/ErrorWrapper";

interface IProps extends IModalProps {
  id: number;
  approvalRequest?: TApprovalRequest;
  loanDetailSource:
    | "my-loan-request"
    | "all-loans-request"
    | "my-loan-payment"
    | "all-loans-payment";
}

export const LoanDetails = ({
  open,
  handleClose,
  id,
  approvalRequest,
  loanDetailSource,
}: IProps) => {
  const [form] = Form.useForm();
  const { data, isSuccess, isFetching, isError, error } = useGetSingleLoan({
    id: id ?? 0,
  });
  useEffect(() => {
    if (isSuccess && id) {
      form.setFieldsValue({
        loanId: data.id.toString().padStart(7, "0"),
        type: data.type.name,
        date: dayjs(data.date).format(DEFAULT_DATE_FORMAT),
        amount: formatNumberWithCommas(data.amount),
        paymentPlan: data.paymentPlan.name,
        description: data.description,
        employee: getEmployeeFullName(data.employee),
        status: data.status,
        employeeId: data.employee.empUid,
      });
    } else {
      form.resetFields();
    }
  }, [form, id, data, isSuccess]);
  const queryClient = useQueryClient();

  return (
    <Modal
      open={open}
      onCancel={() => handleClose()}
      footer={null}
      title={`${
        loanDetailSource === "all-loans-request" ? "Loan Request" : "Loan"
      } Details`}
      style={{ top: 20 }}
    >
      <Skeleton loading={isFetching} active paragraph={{ rows: 16 }}>
        <ErrorWrapper isError={isError} message={errorFormatter(error).message}>
          <>
            <ApproveOrRejectButton
              className="flex justify-end"
              request={approvalRequest}
              handleSuccess={() => {
                queryClient.invalidateQueries({
                  queryKey: [QUERY_KEY_FOR_LOAN],
                  // exact: true,
                });
                queryClient.invalidateQueries({
                  queryKey: [QUERY_KEY_FOR_ALL_LOAN_REQUESTS],
                  // exact: true,
                });
                queryClient.invalidateQueries({
                  queryKey: [QUERY_KEY_FOR_LOAN_REQUESTS],
                  // exact: true,
                });

                handleClose();
              }}
            />
            <Form layout="vertical" requiredMark={false} form={form} disabled>
              <Form.Item label="Loan ID" name="loanId">
                <Input placeholder="Loan ID" />
              </Form.Item>
              {loanDetailSource === "all-loans-request" && (
                <Form.Item label="Employee Name" name="employee">
                  <Input placeholder="Employee" />
                </Form.Item>
              )}
              {loanDetailSource === "all-loans-request" && (
                <Form.Item label="Employee ID" name="employeeId">
                  <Input placeholder="Employee" />
                </Form.Item>
              )}
              <Form.Item label="Loan Type" name="type">
                <Input placeholder="Type" />
              </Form.Item>

              <Form.Item label="Date" name="date">
                <Input placeholder="date" />
              </Form.Item>
              <Form.Item label="Amount" name="amount">
                <Input placeholder="amount" />
              </Form.Item>

          <Form.Item label="Loan status" name="status">
            <Input placeholder="Status" />
          </Form.Item>
          <h4>Document</h4>
          <div className="mb-5 mt-1 cursor-pointer group text-caramel border py-1 border-dotted w-full border-caramel rounded text-center">
           <a  href={data?.documentUrl}
              download="Loan_Document.pdf"
              rel="noopener noreferrer">
           <i className="ri-download-2-line text-xl block"></i>
            <span className="text-sm group-hover:text-accent">
              Download file
            </span>
           </a>
          </div>
          <Form.Item name="description">
            <Input.TextArea rows={4} placeholder="Description" />
          </Form.Item>
    
              <Form.Item label="Payment Plan" name="paymentPlan">
                <Input placeholder="paymentPlan" />
              </Form.Item>


              
              <Form.Item name="description">
                <Input.TextArea rows={4} placeholder="Description" />
              </Form.Item>
            </Form>
          </>
        </ErrorWrapper>
      </Skeleton>
    </Modal>
  );
};
