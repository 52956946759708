import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AuthProvider } from "react-auth-kit";
import { Suspense, useEffect } from "react";
import Router from "config/router";
import GlobalContextProvider from "stateManagers/GlobalContextProvider";
import ErrorBoundary from "components/errorHandlers/ErrorBoundary";
import { LOCAL_STORAGE_AUTH_KEY } from "constants/localStorageKeys";
import { useNetworkState } from "hooks/network/useNetworkState";
import { ErrorWrapper } from "components/errorHandlers/ErrorWrapper";
import PageNotFoundIcon from "assets/svg-components/PageNotFoundIcon/PageNotFoundIcon";
import { useInitializeGoogleAnalyticsTracking } from "hooks/analtyics";
import AntdConfigProvider from "components/providers/AntdConfigProvider";
import ThemeContextProvider from "components/providers/ThemeContextProvider";
import { APP_MAINTENANCE_SETTINGS } from "config/enviroment";
import NewAppVersionBanner from "components/ci-cd/NewAppVersionBanner";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false, //Prevent Multiple Requests from being made on faliure
    },
  },
});

function App() {
  // initialize react-ga to track page views on google analytics
  useInitializeGoogleAnalyticsTracking();
  // clear darkmode
  useEffect(() => {
    const dark = localStorage.getItem("dark");
    if (dark) {
      localStorage.removeItem("dark");
    }
    // localStorage.clear(); //to clear all changes tommorow
  }, []);

  // check online status
  const { isOnline } = useNetworkState();
  if (APP_MAINTENANCE_SETTINGS.MAINETANCE_MODE === "true") {
    return (
      <div className="flex items-center flex-col gap-6">
        <div className="py-12">{<PageNotFoundIcon />}</div>
        <h1 className="text-2xl text-black dark:text-white">
          {"Site under maintenance!"}
        </h1>
      </div>
    );
  }
  return (
    <ErrorBoundary
      message="Please contact administrator!"
      errImageOrIcon={<PageNotFoundIcon />}
    >
      <NewAppVersionBanner />
      <BrowserRouter>
        <AuthProvider
          authType={"localstorage"}
          authName={LOCAL_STORAGE_AUTH_KEY}
          // cookieDomain={window.location.hostname}
          // cookieSecure={window.location.protocol === "https:"}
          // refresh={refreshApi}
        >
          <ErrorWrapper
            isError={!isOnline}
            errImage={<PageNotFoundIcon />}
            message="Please check your internet connection!"
          >
            <QueryClientProvider client={queryClient}>
              <GlobalContextProvider>
                <ThemeContextProvider>
                  <AntdConfigProvider>
                    <Suspense fallback={<div>temporary Loading...</div>}>
                      <Router />
                    </Suspense>
                  </AntdConfigProvider>
                </ThemeContextProvider>
              </GlobalContextProvider>
              <ReactQueryDevtools
                initialIsOpen={false}
                position="bottom-right"
              />
            </QueryClientProvider>
          </ErrorWrapper>
        </AuthProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
