import { message, Modal, Typography, Upload, Form } from "antd";
import { RcFile } from "antd/es/upload";
import { AppButton } from "components/button/AppButton";
import { QUERY_KEY_FOR_EMPLOYEES_IN_PAYROLL } from "features/payroll/hooks/payroll/employee/useGetEmployeesInPayroll";
import { useAddOvertimeSheet } from "features/payroll/hooks/payroll/overtimeSheet/useAddOvertimeSheet";
import { useGetOvertimeSheetTemplate } from "features/payroll/hooks/payroll/overtimeSheet/useGetOvertimeSheetTemplate";
import { QUERY_KEY_FOR_SINGLE_PAYROLL } from "features/payroll/hooks/payroll/useGetSinglePayroll";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { IModalProps } from "types";
import { openNotification } from "utils/notifications";

interface ITimesheetProps extends IModalProps {
  payrollId: number;
}
export const UploadPayrollOvertimeTimesheet: React.FC<ITimesheetProps> = ({
  open,
  handleClose,
  payrollId,
}) => {
  // TODO : Refactor to use ImportEntityModal, in its own comp
  const queryClient = useQueryClient();

  const [form] = Form.useForm();
  const { mutate, isLoading } = useAddOvertimeSheet();
  const [fileList, setFilelist] = useState<any>([]);
  const handleUpload = (val: any) => {
    setFilelist(val.fileList);
  };
  const beforeUpload = (file: RcFile) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    const isSpreadSheetFile = file.type === "text/csv";
    if (!isSpreadSheetFile) {
      message.error("You can only upload CSV file!");
    }
    if (!isLt2M) {
      message.error("File must smaller than 2MB!");
    }
    return false; //this is done so that it prevents dafault value
  };
  const { mutate: mutateGetTemplate } = useGetOvertimeSheetTemplate();

  const handleGetTemplate = () => {
    mutateGetTemplate(
      {
        data: {
          payrollId,
        },
      },
      {
        onError: (err: any) => {
          openNotification({
            state: "error",
            title: "Error Occurred",
            description:
              err?.response.data.message ?? err?.response.data.error.message,
          });
        },
        onSuccess: (res: any) => {
          openNotification({
            state: "success",

            title: "Success",
            description: res.data.message,
            // duration: 0.4,
          });
          form.resetFields();

          handleClose();
        },
      }
    );
  };
  const handleSubmit = (data: any) => {
    mutate(
      {
        payrollId,
        data: {
          csvFile: fileList[0]?.originFileObj,
        },
      },
      {
        onError: (err: any) => {
          openNotification({
            state: "error",
            title: "Error Occurred",
            description:
              err?.response.data.message ?? err?.response.data.error.message,
          });
        },
        onSuccess: (res: any) => {
          openNotification({
            state: "success",

            title: "Success",
            description: res.data.message,
            // duration: 0.4,
          });
          form.resetFields();
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_FOR_SINGLE_PAYROLL],
            // exact: true,
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_FOR_EMPLOYEES_IN_PAYROLL],
            // exact: true,
          });

          handleClose();
        },
      }
    );
  };

  return (
    <Modal
      open={open}
      footer={null}
      title={"Upload Timesheet"}
      style={{ top: 20 }}
      onCancel={() => handleClose()}
    >
      <Form form={form} onFinish={handleSubmit} requiredMark={false}>
        <div className="border border-dotted border-slate-500 rounded flex flex-col items-center gap-2 py-3 px-2">
          <p>Select file to be Imported</p>
          <Typography.Text title="Please Download template and populate">
            <span
              className="text-sm pt-1 font-medium cursor-pointer hover:text-caramel underline"
              onClick={() => handleGetTemplate()}
            >
              Download template
            </span>
          </Typography.Text>

          <div className="flex justify-center w-3/5">
            <Upload
              fileList={fileList}
              onChange={handleUpload}
              onRemove={(file) => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFilelist(newFileList);
              }}
              beforeUpload={beforeUpload}
            >
              {fileList.length !== 1 && (
                <div className="w-full border border-dotted border-caramel px-2 py-1 rounded text-caramel text-sm flex flex-col gap-1 items-center justify-center">
                  <i className="ri-download-2-line text-2xl"></i>
                  <span className="text-xs font-medium">Upload File</span>
                </div>
              )}
            </Upload>
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <AppButton
            label="Upload"
            type="submit"
            isLoading={isLoading}
            disabled={fileList.length <= 0}
          />
        </div>
      </Form>
    </Modal>
  );
};
