import { PROFILE_EDIT_REQUEST_CATEGORIES } from "features/self-service/features/profile-edit/constants";
import { TNotification } from "../types";

// TODO: Update to account for all the possible content data, also update type content in TNotification e.g Task

export const generateNotificaionContent = (
  content?: TNotification["content"]
): TNotification["content"][number] | undefined => {
  if (
    "scheme" in (content?.[0] || {}) &&
    "disbursementDate" in (content?.[0] || {})
  ) {
    return [
      {
        ...(content?.[0] ?? {}),

        payroll: content?.[0]?.payroll ?? content?.[0],
      } as unknown as TNotification["content"][number],
    ]?.[0];
  }
  if (
    "time" in (content?.[0] || {}) &&
    "date" in (content?.[0] || {}) &&
    "policy" in (content?.[0] || {})
  ) {
    return [
      {
        ...(content?.[0] ?? {}),

        timeOff: content?.[0]?.timeOff ?? content?.[0],
      } as unknown as TNotification["content"][number],
    ]?.[0];
  }
  if (
    "shiftPartner" in (content?.[0] || {}) &&
    "employee" in (content?.[0] || {})
  ) {
    return [
      {
        ...(content?.[0] ?? {}),

        shiftSwap: content?.[0]?.shiftSwap ?? content?.[0],
      } as unknown as TNotification["content"][number],
    ]?.[0];
  }
  if (
    "reasonForLeaving" in (content?.[0] || {}) &&
    "assetChecklist" in (content?.[0] || {}) &&
    "separationDate" in (content?.[0] || {})
  ) {
    return [
      {
        ...(content?.[0] ?? {}),

        exitHandoverForm: content?.[0]?.exitHandoverForm ?? content?.[0],
      } as unknown as TNotification["content"][number],
    ]?.[0];
  }
  if (
    "paymentPlan" in (content?.[0] || {}) &&
    "balance" in (content?.[0] || {}) &&
    "employee" in (content?.[0] || {})
  ) {
    return [
      {
        ...(content?.[0] ?? {}),

        loan: content?.[0]?.loan ?? content?.[0],
      } as unknown as TNotification["content"][number],
    ]?.[0];
  }
  if (
    "employee" in (content?.[0] || {}) &&
    "title" in (content?.[0] || {}) &&
    "purpose" in (content?.[0] || {}) &&
    "amount" in (content?.[0] || {})
  ) {
    return [
      {
        ...(content?.[0] ?? {}),

        moneyRequisition: content?.[0]?.moneyRequisition ?? content?.[0],
      } as unknown as TNotification["content"][number],
    ]?.[0];
  }
  if (
    "proposedDesignation" in (content?.[0] || {}) &&
    "justification" in (content?.[0] || {}) &&
    "reason" in (content?.[0] || {})
  ) {
    return [
      {
        ...(content?.[0] ?? {}),

        positionChangeRequisition:
          content?.[0]?.positionChangeRequisition ?? content?.[0],
      } as unknown as TNotification["content"][number],
    ]?.[0];
  }
  if (
    "duration" in (content?.[0] || {}) &&
    "cost" in (content?.[0] || {}) &&
    "arrivalDate" in (content?.[0] || {}) &&
    "clientName" in (content?.[0] || {})
  ) {
    return [
      {
        ...(content?.[0] ?? {}),

        travelRequest: content?.[0]?.travelRequest ?? content?.[0],
      } as unknown as TNotification["content"][number],
    ]?.[0];
  }
  if (
    "employee" in (content?.[0] || {}) &&
    "title" in (content?.[0] || {}) &&
    "amount" in (content?.[0] || {})
  ) {
    return [
      {
        ...(content?.[0] ?? {}),

        reimbursementRequisition:
          content?.[0]?.reimbursementRequisition ?? content?.[0],
      } as unknown as TNotification["content"][number],
    ]?.[0];
  }
  if (
    "proposedDesignation" in (content?.[0] || {}) &&
    "preferredStartDate" in (content?.[0] || {}) &&
    "justification" in (content?.[0] || {})
  ) {
    return [
      {
        ...(content?.[0] ?? {}),

        promotionRequisition:
          content?.[0]?.promotionRequisition ?? content?.[0],
      } as unknown as TNotification["content"][number],
    ]?.[0];
  }
  if (
    "proposedDesignation" in (content?.[0] || {}) &&
    "proposedBranch" in (content?.[0] || {})
  ) {
    return [
      {
        ...(content?.[0] ?? {}),

        transferRequisition: content?.[0]?.transferRequisition ?? content?.[0],
      } as unknown as TNotification["content"][number],
    ]?.[0];
  }
  if ("designation" in (content?.[0] || {})) {
    return [
      {
        ...(content?.[0] ?? {}),

        jobRequisition: content?.[0]?.jobRequisition ?? content?.[0],
      } as unknown as TNotification["content"][number],
    ]?.[0];
  }
  if ("asset" in (content?.[0] || {})) {
    return [
      {
        ...(content?.[0] ?? {}),

        assetRequisition: content?.[0]?.assetRequisition ?? content?.[0],
      } as unknown as TNotification["content"][number],
    ]?.[0];
  }
  if ("conferenceRoom" in (content?.[0] || {})) {
    return [
      {
        ...(content?.[0] ?? {}),

        conferenceRoomBooking:
          content?.[0]?.conferenceRoomBooking ?? content?.[0],
      } as unknown as TNotification["content"][number],
    ]?.[0];
  }
  if ("vehicle" in (content?.[0] || {})) {
    return [
      {
        ...(content?.[0] ?? {}),

        vehicleBooking: content?.[0]?.vehicleBooking ?? content?.[0],
      } as unknown as TNotification["content"][number],
    ]?.[0];
  }
  if ("leaveType" in (content?.[0] || {})) {
    return [
      {
        ...(content?.[0] ?? {}),

        leave: content?.[0]?.leave ?? content?.[0],
      } as unknown as TNotification["content"][number],
    ]?.[0];
  }
  if (
    "category" in (content?.[0] || {}) &&
    PROFILE_EDIT_REQUEST_CATEGORIES?.includes((content?.[0] as any)?.category)
  ) {
    return [
      {
        ...(content?.[0] ?? {}),
        profileEditRequest: content?.[0]?.profileEditRequest ?? content?.[0],
      } as unknown as TNotification["content"][number],
    ]?.[0];
  }
  return content?.[0];
};
