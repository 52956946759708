import SubsciptionManagement from "features/billing/pages/SubsciptionManagement";
import { appRoutes } from "../paths";
import { TAppPageDataFnProps, TRouteData } from "../types";
import BillingHistory from "features/billing/pages/billing/BillingHistory";
import PurchaseEmployeeLicenses from "features/billing/pages/PurchaseEmployeeLicenses";
import SingleBillingHistory from "features/billing/pages/billing/SingleBillingHistory";
import StorageManagements from "features/billing/pages/StorageManagements";
import CompanyTrainingSessionManagement from "features/billing/pages/CompanyTrainingSessionManagement";
import InformEmployeeOfInActiveSubscription from "features/billing/pages/InformEmployeeOfInActiveSubscription";
import InformOwnerOfInActiveSubscription from "features/billing/pages/InformOwnerOfInActiveSubscription";
import PurchaseExtraLicense from "features/billing/pages/PurchaseExtraLicense";
import PurchaseModules from "features/billing/pages/PurchaseModules";
import UpgradePlanPage from "features/billing/pages/UpgradePlanPage";
import PurchaseSubscriptionByPlanPage from "features/billing/pages/PurchaseSubscriptionByPlanPage";
import { canUserAccessComponent } from "components/permission-restriction/PermissionRestrictor";

export const billingRoutes = (
  props: Omit<TAppPageDataFnProps, "isOwner" & { isOwner: boolean }>
): TRouteData[] => {
  const { userPermissions, activeSubscription } = props;
  return [
    {
      element: <div />,
      path: appRoutes.billingStatement,
      isSearchable: false,
    },
    {
      element: <SingleBillingHistory />,
      path: appRoutes.singleBillingSummary().format,
      isSearchable: false,
      hidden: !canUserAccessComponent({
        userPermissions,
        requiredPermissions: ["manage-subscription"],
        activeSubscription,

        requiredSubscriptionState: {
          label: "employee-management",
          resources: [],
        },
      }),
    },
    {
      element: <BillingHistory />,
      path: appRoutes.billingSummary,
      isSearchable: false,
      hidden: !canUserAccessComponent({
        userPermissions,
        requiredPermissions: ["manage-subscription"],
        activeSubscription,

        requiredSubscriptionState: {
          label: "employee-management",
          resources: [],
        },
      }),
    },
    {
      element: <PurchaseEmployeeLicenses />,
      path: appRoutes.purchaseUserLicense,
      isSearchable: false,
      hidden: !canUserAccessComponent({
        userPermissions,
        requiredPermissions: ["manage-subscription"],
        activeSubscription,

        requiredSubscriptionState: {
          label: "employee-management",
          resources: [],
        },
      }),
    },
    {
      element: <PurchaseExtraLicense />,
      path: appRoutes.purchaseExtraLiense,
      isSearchable: false,
      hidden: !canUserAccessComponent({
        userPermissions,
        requiredPermissions: ["manage-subscription"],
        activeSubscription,

        requiredSubscriptionState: {
          label: "employee-management",
          resources: [],
        },
      }),
    },
    {
      element: <PurchaseModules />,
      path: appRoutes.purchaseModules,
      isSearchable: false,
      hidden: !canUserAccessComponent({
        userPermissions,
        requiredPermissions: ["manage-subscription"],
        activeSubscription,

        requiredSubscriptionState: {
          label: "employee-management",
          resources: [],
        },
      }),
    },
    {
      element: <UpgradePlanPage />,
      path: appRoutes.upgradePlan,
      isSearchable: false,
      hidden: !canUserAccessComponent({
        userPermissions,
        requiredPermissions: ["manage-subscription"],
        activeSubscription,

        requiredSubscriptionState: {
          label: "employee-management",
          resources: [],
        },
      }),
    },
    {
      element: <PurchaseSubscriptionByPlanPage />,
      path: appRoutes.purchaseSubscriptionByPlan().format,
      isSearchable: false,
      hidden: !canUserAccessComponent({
        userPermissions,
        requiredPermissions: ["manage-subscription"],
        activeSubscription,

        requiredSubscriptionState: {
          label: "employee-management",
          resources: [],
        },
      }),
    },
    {
      element: <SubsciptionManagement />,
      path: appRoutes.billingSubscription,
      title: "Subscriptions",
      isSearchable: true,
      hidden: !canUserAccessComponent({
        userPermissions,
        requiredPermissions: ["manage-subscription"],
        activeSubscription,

        requiredSubscriptionState: {
          label: "employee-management",
          resources: [],
        },
      }),
      category: "doesnt-require-active-subscription",
    },
    {
      element: <InformEmployeeOfInActiveSubscription />,
      path: appRoutes.billingInactiveSubscriptionInformEmployee,
      isSearchable: false,
      category: "doesnt-require-active-subscription",
    },
    {
      element: <InformOwnerOfInActiveSubscription />,
      path: appRoutes.billingInactiveSubscriptionInformOwner,
      isSearchable: false,
      category: "doesnt-require-active-subscription",
    },
    {
      element: <StorageManagements />,
      path: appRoutes.billingStorageManagement,
      title: "Storage Management",
      isSearchable: true,
      hidden: !canUserAccessComponent({
        userPermissions,
        requiredPermissions: ["manage-company-storage"],
        activeSubscription,

        requiredSubscriptionState: {
          label: "employee-management",
          resources: [],
        },
      }),
    },
    {
      element: <CompanyTrainingSessionManagement />,
      path: appRoutes.billingTrainingSession,
      title: "Training Session",
      isSearchable: true,
      hidden: !canUserAccessComponent({
        userPermissions,
        requiredPermissions: ["manage-training-session"],
        activeSubscription,

        requiredSubscriptionState: {
          label: "employee-management",
          resources: [],
        },
      }),
    },
  ];
};
