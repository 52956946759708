import { TAdvancedWorkflowStage } from "../hooks/useCreateAdvancedWorkflow";
import { TBasicWorkflowStage } from "../hooks/useCreateBasicWorkflow";
import {
  TAdvancedWorkflowStageInput,
  TBasicWorkflowStageInput,
} from "../types";

export const defineAdvancedWorkflowStagesInput = (
  stages: TAdvancedWorkflowStageInput[]
) => {
  const workflowStages: TAdvancedWorkflowStage[] = stages
    .map(
      ({
        name,
        entityId,
        type,
        condition,
        count,
        enableTwoFactorAuth,
      }): TAdvancedWorkflowStage => {
        if (!!entityId && !!type && type !== "line-manager") {
          return {
            entityId: entityId,
            type,
            name,
            condition,
            count,
            enableTwoFactorAuth,
          };
        }

        if (type && ["line-manager", "hod"].includes(type)) {
          return {
            type,
            name,
          };
        }
        return {
          entityId: 0,
          type: "employee",
          name,
        };
      }
    )
    .filter((item) => item.entityId !== 0);
  return workflowStages;
};
export const defineBasicWorkflowStagesInput = (
  stages: TBasicWorkflowStageInput[]
) => {
  const workflowStages: TBasicWorkflowStage[] = stages
    .map(({ name, entityId, type }): TBasicWorkflowStage => {
      if (!!entityId && !!type && type !== "line-manager") {
        return {
          entityId: entityId,
          type,
          name,
        };
      }
      if (type && ["line-manager", "hod"].includes(type)) {
        return {
          type,
          name,
        };
      }
      return {
        //Done simply to return a default value, which is filtered out below
        entityId: 0,
        type: "employee",
        name,
      };
    })
    .filter((item) => item.entityId !== 0);

  return workflowStages;
};
