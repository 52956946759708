import { Form, Input, Modal } from "antd";
import { AppButton } from "components/button/AppButton";
import { generalValidationRules } from "utils/formHelpers/validation";
import { DoublePropTwoFA } from "../types";
import { useVerify2FA } from "../hooks/useVerify2Fa";
import { openNotification } from "utils/notifications";
import { useQueryClient } from "react-query";
import { QUERY_KEY_FOR_CHECK_OTP } from "../hooks/useGetTwoFA";

export const VerifyTwoFA = ({ open, handleClose, image }: DoublePropTwoFA) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useVerify2FA();

  const handleFormSubmit = (val: any) => {
    mutate(
      {
        ...val,
      },
      {
        onError: (err: any) => {
          openNotification({
            state: "error",
            title: "Error Occurred",
            description:
              err?.response.data.message ?? err?.response.data.error.message,
          });
        },
        onSuccess: (res) => {
          openNotification({
            state: "success",
            title: "Success",
            description: res.data.message,
          });

          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_FOR_CHECK_OTP],
          });
          form.resetFields();
          handleClose();
        },
      }
    );
  };
  return (
    <Modal
      open={open}
      footer={null}
      onCancel={() => handleClose()}
      title={`Enable Two-Factor Authentication`}
      style={{ top: 10 }}
    >
      <div className=" pt-5">
        <p>
          1. To enable 2FA, you need to install an authenticator app like Authy,
          or Google Authenticator.
        </p>
        <p className="pt-3">
          2. Scan the QR Code below with your authenticator app. If you can't
          scan this barcode, enter the text code (CBSRYISEBBJWW3R3) on the
          authenticator app instead.
        </p>

        <div>
          <img src={image} alt={"qrcode"} loading="lazy" />
        </div>

        <p> 3. Enter the OTP generated by the app and click enable.</p>

        <Form
          onFinish={handleFormSubmit}
          form={form}
          layout="vertical"
          className="mt-4"
          requiredMark={false}
        >
          <Form.Item name="token" rules={generalValidationRules}>
            <Input.OTP formatter={(str) => str.toUpperCase()} />
          </Form.Item>

          <div className="flex justify-between mt-3">
            <h3 className="text-lg cursor-pointer" onClick={() => handleClose()}>Cancel</h3>
            <AppButton label="Enable" type="submit" isLoading={isLoading} />
          </div>
        </Form>
      </div>
    </Modal>
  );
};
