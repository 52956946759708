import axios from "axios";
import { MICROSERVICE_ENDPOINTS } from "config/enviroment";
import { TEmployeeStatus } from "features/core/employees/types";
import { useApiAuth } from "hooks/useApiAuth";
import { useMutation } from "react-query";
import { ICurrentCompany } from "types";
import { CRB_POLICY_FREQUENCY_TIME_PERIODS } from "../../constants";
export type TSaveConferenceRoomBookingPolicyInput = {
  workflowId: number;
  frequency: {
    timePeriod: (typeof CRB_POLICY_FREQUENCY_TIME_PERIODS)[number];
    numOfTimes: number;
    disabled: boolean;
  };
  timeRestriction: {
    duration: number;
    disabled: boolean;
  };
  eligibilityCriteria: {
    employeeStatus: TEmployeeStatus[];
    designation: number[];
    role: number[];
  };
};

const savePolicy = async (
  props: TSaveConferenceRoomBookingPolicyInput,
  auth: ICurrentCompany
) => {
  const url = `${MICROSERVICE_ENDPOINTS.UTILITY}/self-service/conference-room/policy`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${auth.token}`,
      "x-company-id": auth.companyId,
    },
  };

  const data: TSaveConferenceRoomBookingPolicyInput = props;

  const response = await axios.post(url, data, config);
  return response;
};
export const useSaveConferenceRoomBookingPolicy = () => {
  const { token, companyId } = useApiAuth();
  return useMutation((props: TSaveConferenceRoomBookingPolicyInput) =>
    savePolicy(props, { token, companyId })
  );
};
