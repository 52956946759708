import { TPaystackBank } from "types/paystackBank";
import { textHasOnlyDigits } from "utils/formHelpers/validation";
import { z } from "zod";

type TValidateProps = { banks?: TPaystackBank[] };

export const bankDetailsValidationSchema = (props: TValidateProps) => {
  const { banks } = props;
  return z
    .object({
      bankName: z.string(),
      accountNumber: z.string().or(z.number()),
      bankCode: z.string().min(3).max(3).optional(),
      bvn: z.string().min(10).max(10).optional(), // optional
    })
    .transform(({ bankName, accountNumber, ...props }) => {
      return {
        bankCode: banks?.find(
          (b) => b.name.toLowerCase().trim() === bankName.toLowerCase().trim()
        )?.code,
        bankName,
        accountNumber: accountNumber?.toString(),
        ...props,
      };
    })
    .superRefine((data, ctx) => {
      const bankNames = banks?.map(({ name }) => name);
      const ACCOUNT_NUMBER_ALLOWED_LENGTH = 10;
      if (!textHasOnlyDigits(data.accountNumber)) {
        ctx.addIssue({
          code: "custom",
          message: `Account number must contain only digits`,
          path: ["accountNumber"],
        });
      }
      if (data.accountNumber.length !== ACCOUNT_NUMBER_ALLOWED_LENGTH) {
        ctx.addIssue({
          code: "custom",
          message: `Account number must be ${ACCOUNT_NUMBER_ALLOWED_LENGTH} digits long`,
          path: ["accountNumber"],
        });
      }

      if (!bankNames?.includes(data.bankName)) {
        ctx.addIssue({
          code: "custom",
          message: `Bank Name has to be one of the following ${bankNames}`,
          path: ["bankName"],
        });
      }
    });
};
