type TError = {
  message: string;
  errors?: { message: string; path: string }[];
};

type TErrorAccount = {
  trace: Trace;
};

interface Trace {
  _code: number;
}

export interface ApiErrorResponse {
  message: string;
  errors: ApiError[];
}

interface ApiError {
  message: string;
  path: string;
}

export const errorFormatter = (_error: unknown): TError => {
  const DEFAULT_ERR_MESSAGE = "Oops! Something went wrong!";

  // Check if the error is of type TErrorAccount
  if (isTErrorAccount(_error)) {
    return {
      message: `Error code: ${_error.trace._code}`,
    };
  }

  // Default handling for ApiErrorResponse
  const error = _error as Record<string, any>;
  const message =
    error?.response?.data?.message ??
    error?.response?.data?.error?.message ??
    DEFAULT_ERR_MESSAGE;

  const errors = (error?.response?.data?.error?.errors as ApiError[] | undefined)?.map(
    (err) => ({
      message: err.message,
      path: err.path.toString(),
    })
  );

  return { message, errors };
};

// Type guard to check if the error is of type TErrorAccount
function isTErrorAccount(error: unknown): error is TErrorAccount {
  return (
    typeof error === "object" &&
    error !== null &&
    "trace" in error &&
    typeof (error as TErrorAccount).trace._code === "number"
  );
}
