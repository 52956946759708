import { usePagination } from "hooks/usePagination";
import { useSearchParams } from "react-router-dom";
import {
  TEmployeeFilterPropsWithPaginationAndSearch,
  useFetchEmployees,
} from "../useFetchEmployees";
import { useEffect, useState } from "react";
import { DEFAULT_BACK_NEXT_SINGLE_EMPLOYEE_PROFILE_SIZE } from "../../constants";

const useBackNextOnSingleEmployeeProfile = (employeeId: number) => {
  // TODO: Refactor to properly account for the offset being considered at the end of a next that has a next page
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status") ?? "";
  const offset = searchParams.get("offset") ?? "";
  const { pagination, onChange } = usePagination();
  const [currentEmployeeId, setCurrentEmployeeId] =
    useState<number>(employeeId);
  const [canNext, setCanNext] = useState<boolean>(false);
  const [canBack, setCanBack] = useState<boolean>(false);

  const { data: employeeData, isFetching: isLoading } = useFetchEmployees({
    status: status.split(
      ","
    ) as TEmployeeFilterPropsWithPaginationAndSearch["status"],
    pagination: {
      offset: +offset || 0,
      limit: DEFAULT_BACK_NEXT_SINGLE_EMPLOYEE_PROFILE_SIZE,
    },
  });

  useEffect(() => {
    if (employeeData && employeeData.total) {
      const currentIndex = employeeData.data.findIndex(
        (emp) => emp.id === currentEmployeeId
      );
      setCanBack(currentIndex > 0 || pagination.offset > 0);
      setCanNext(
        currentIndex < employeeData.data.length - 1 ||
          pagination.offset + (pagination?.pageSize || 0) < employeeData.total
      );
    }
  }, [employeeData, currentEmployeeId, pagination.offset, pagination.pageSize]);

  const handleNext = () => {
    const currentIndex =
      employeeData?.data.findIndex((emp) => emp.id === currentEmployeeId) || 0;

    if (currentIndex < (employeeData?.data?.length || 0) - 1) {
      setCurrentEmployeeId(employeeData?.data?.[currentIndex + 1]?.id || 0);
    } else if (
      pagination.offset + (pagination?.pageSize || 0) <
      (employeeData?.total || 0)
    ) {
      onChange((pagination.current || 0) + 1);
    }
  };

  const handleBack = () => {
    const currentIndex =
      employeeData?.data?.findIndex((emp) => emp.id === currentEmployeeId) || 0;

    if (currentIndex > 0) {
      setCurrentEmployeeId(employeeData?.data?.[currentIndex - 1]?.id || 0);
    } else if (pagination.offset > 0) {
      onChange((pagination.current || 0) - 1);
    }
  };

  return {
    handleBack,
    handleNext,
    canNext,
    canBack,
    currentEmployeeId,
    isLoading,
  };
};

export default useBackNextOnSingleEmployeeProfile;
