export const ActiveAccordianPanelIndicator: React.FC<{
  isActive?: boolean;
}> = ({ isActive = false }) => {
  return (
    <>
      {isActive ? (
        <i className="ri-arrow-up-s-line text-lg" />
      ) : (
        <i className="ri-arrow-down-s-line text-lg" />
      )}
    </>
  );
};
